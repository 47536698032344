import React, { useState, useEffect, useRef } from "react";
import Router from "next/router";
import moment from "moment";
import { Tooltip } from "react-tooltip";
import { TableModel } from "../../_models/component.table.model";
import { TableWrapper, FilterSearch, FilterPlaceholder, Tr, THeadLabel, TJsx, SortWrapper, SortButton } from "../../styles/tables/table";
import Pagination from "./pagination";
import Toggle from "../forms/inputs/toggle";
import { StandardButtonAnchor, NewTabAnchor, TableButton, SummaryButtonStyled } from "../../styles/type/buttons";
import FilterSelectInput from "../forms/inputs/filterSelect";
import { ResetFiltersStyled, TotalSummaryStyled } from "../../styles/tables/pagination";
import TickIcon from "../icons/tick";
import theme from "../../styles/theme";
import CrossIcon from "../icons/cross";
import InputHelp from "../forms/inputs/help";
import { addToLocalStorage, getFromLocalStorage } from "../../state/localStorage";
import TableColumnIcon from "../icons/tableColumns";
import { IFilter } from "../../_models/data.filter.model";
import SummaryIcon from "../icons/summary";
import { RefreshIcon } from "../icons/refresh";
import TopScrollbar from "./TopScrollbar";
import FilterMultiSelect from "../forms/inputs/filterMultiSelect";
import { CenterIcon } from "../icons/center";

type Props = {
	resultItems?: any;
	resultItemsAll?: any;
	tableProperties?: any;
	pageNo?: number;
	pageSize?: number;
	showAll?: boolean;
	returnItem?: any;
	edit?: any;
	UpdatePage?: any;
	UpdateData?: any;
	paginationItems?: any;
	totalRecords?: number;
	scaleTable?: boolean;
	theme?: any;
	hidePagination?: boolean;
	sortBy?: any;
	sortDir?: any;
	defaultMessage?: string;
	external?: boolean;
	sub?: boolean;
	noPagination?: boolean;
	fixHeight?: boolean;
	defaultFilterValues?: any;
	fullPagination?: boolean;
	fullPaginationSlim?: boolean;
	showTotal?: boolean;
	slim?: boolean;
	rowTooltip?: string[];
	name?: string;
	defaultRequestFilter?: IFilter;
	defaultPageSize?: number;
	resetFilterValues?: IFilterItem[];
	hideReset?: boolean;
	stickyHeader?: boolean;
};

export interface IFilterItem {
	column: string;
	value: string | number | undefined;
}

export interface IOrderItem {
	[key: string]: number;
}

export interface IColumnConfig {
	column: string;
	hidden: boolean;
}

const Table: React.FC<Props> = ({
	pageNo,
	showAll,
	resultItems,
	paginationItems,
	pageSize,
	totalRecords,
	tableProperties,
	UpdatePage,
	returnItem,
	scaleTable,
	defaultMessage = null,
	external = false,
	sub = false,
	noPagination = false,
	fixHeight = false,
	defaultFilterValues,
	fullPagination,
	fullPaginationSlim,
	showTotal,
	slim,
	rowTooltip,
	name,
	defaultRequestFilter,
	defaultPageSize,
	resetFilterValues,
	hideReset = false,
	stickyHeader = false,
}) => {
	// let timeout: any;

	const timeout = useRef(null);

	const [state, setState] = useState(
		new TableModel({
			pageNo: defaultRequestFilter?.pageOptions?.pageNo ?? pageNo,
			showAll: showAll,
			records: resultItems,
			resultItemsFiltered: [],
			resultItemsFilteredPrePaginate: resultItems,
			loading: true,
			paginationItems: paginationItems,
			pageSize: defaultRequestFilter?.pageOptions?.pageSize ?? pageSize,
			totalRecords: totalRecords,
			filters: tableProperties.columns
				.filter((col: any) => col.Filter)
				.map((item) => ({
					column: item.FilterValue || item.Value,
					value: defaultRequestFilter?.filterOptions?.find((filterOption) => filterOption.column == item.Value)?.value ?? "",
					type: item.FilterType || null,
				})),
			order: defaultRequestFilter?.orderOptions ?? null,
		})
	);

	const [columnConfig, setColumnConfig] = useState<IColumnConfig[]>([]);

	const tableRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (defaultFilterValues) defaultFilter();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultFilterValues]);

	useEffect(() => {
		if (name) setColumnConfig(getFromLocalStorage(name) ?? []);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [name]);

	useEffect(() => {
		setState((prevState) => ({
			...prevState,
			records: resultItems,
			resultItemsFiltered: resultItems,
		}));
	}, [resultItems]);

	useEffect(() => {
		setState((prevState) => ({ ...prevState, pageNo }));
	}, [pageNo]);

	useEffect(() => {
		setState((prevState) => ({ ...prevState, pageSize }));
	}, [pageSize]);

	useEffect(() => {
		setState((prevState) => {
			const newFilters = [...prevState.filters];

			// Add new filters from TableProperties
			tableProperties.columns.forEach((col) => {
				if (col.Filter && !newFilters.some((f) => f.column === (col.FilterValue || col.Value))) {
					newFilters.push({
						column: col.FilterValue || col.Value,
						value: "",
						type: col.FilterType || null,
					});
				}
			});

			return {
				...prevState,
				filters: newFilters,
			};
		});
	}, [tableProperties]);

	useEffect(() => {
		setState((prevState) => ({
			...prevState,
			paginationItems,
			pageSize,
			showAll,
			totalRecords: totalRecords || state.records.length,
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationItems, pageSize, totalRecords, showAll]);

	const defaultFilter = () => {
		for (const [key, value] of Object.entries(defaultFilterValues)) {
			searchFilter({
				target: {
					name: key,
					value: value,
				},
			});

			if (value && document.getElementById(key)) (document.getElementById(key) as HTMLInputElement).value = value as string;
		}
		setState((prevState) => ({
			...prevState,
			filters: prevState.filters.map((item) => {
				if (defaultFilterValues[item.column]) {
					item.value = defaultFilterValues[item.column];
				}
				return item;
			}),
		}));
	};

	const searchTextFilter = (e) => {
		const target = e?.target;
		if (target?.value?.trim?.()?.length === 1) {
			return;
		}

		searchFilter(e);
	};

	const searchFilter = (e) => {
		const target = e.target;
		for (let i = 0, item; !!(item = state.filters[i++]); ) {
			if (item.column === target.name) item.value = target.value; // update filter value for table column
		}

		clearTimeout(timeout.current);
		timeout.current = setTimeout(() => {
			if (Array.isArray(target.value) ? target.value : target.value?.length > 1 || !target.value.length) {
				UpdatePage("reset", { showAll: state.showAll }, state.filters);
			}
		}, 500);
	};

	const orderBy = (Order, OrderType, Field) => {
		if (state?.order && state?.order[Field] === Order) {
			setState((prevState) => ({
				...prevState,
				order: null,
			}));
			UpdatePage("reset", { showAll: state.showAll }, null, {});
		} else {
			setState((prevState) => ({
				...prevState,
				order: {
					[Field]: Order,
				},
			}));

			UpdatePage("reset", { showAll: state.showAll }, null, { [Field]: Order === "Asc" ? 1 : -1 });
		}
	};

	const updateColumnConfig = (columnName) => {
		let found = false;

		const newColumnConfig = columnConfig.map((item) => {
			if (item.column === columnName) {
				found = true;
				return { ...item, hidden: !item.hidden };
			}
			return item;
		});

		// If columnName was not found, add it to the array
		if (!found) {
			newColumnConfig.push({ column: columnName, hidden: true });
		}

		setColumnConfig(newColumnConfig);
		addToLocalStorage(name, newColumnConfig);
	};

	const handleRowClick = (NAVIGATE, VALUE, RETURN) => {
		if (NAVIGATE) {
			if (RETURN) {
				returnItem(RETURN);
			} else {
				Router.push(`${NAVIGATE.As}`, `${NAVIGATE.Href}${VALUE ? `${VALUE}` : ""}${NAVIGATE.HrefAfter ? NAVIGATE.HrefAfter : ""}`);
			}
		}
	};

	const handleCellClick = (e, NAVIGATE, HREF?, NEW_TAB?) => {
		e.stopPropagation();
		NEW_TAB ? window.open(NAVIGATE, "_blank") : HREF ? Router.push(HREF, NAVIGATE) : Router.push(NAVIGATE);
	};

	const formatValue = (Value, SymbolType, Enum) => {
		return Value ? (SymbolType ? (SymbolType.position === "before" ? `${SymbolType.symbol}${Value}` : `${Value}${SymbolType.symbol}`) : Enum ? Enum[Value] : Value) : "";
	};

	const tableButton = (buttonText, buttonAction, value) => {
		return <TableButton onClick={(e) => buttonAction(e, value)}>{buttonText}</TableButton>;
	};

	const summaryButton = (buttonText, buttonAction, value) => {
		return (
			<SummaryButtonStyled data-tooltip-id="summary" data-tooltip-content={buttonText} onClick={(e) => buttonAction(e, value)}>
				<SummaryIcon />
			</SummaryButtonStyled>
		);
	};

	const getTotalSummary = () => {
		const startRecord = (pageNo - 1) * pageSize + 1;
		const endRecord = Math.min(startRecord + pageSize - 1, totalRecords);

		return (
			<span>
				Showing {startRecord}-{endRecord} of {totalRecords}
			</span>
		);
	};

	const resetFilters = () => {
		const elements = document.querySelectorAll(".searchFilters");

		elements.forEach((element: HTMLInputElement) => {
			element.value = "";
		});

		// Select all elements with the class 'test'
		const defaultFilterElements = document.querySelectorAll(".defaultRequestFilter");

		defaultFilterElements.forEach((element: HTMLInputElement) => {
			element.className = "";
		});

		setState((prevState) => ({
			...prevState,
			order: null,
			filters: prevState.filters.map((filter) => ({
				...filter,
				value: resetFilterValues?.find((rf) => rf.column === filter.column)?.value || "",
			})),
		}));

		UpdatePage(
			"reset",
			{ showAll: state.showAll, pageSize: defaultPageSize ?? 10, pageNo: 1 },
			state.filters.map((filter) => ({
				...filter,
				value: resetFilterValues?.find((rf) => rf.column === filter.column)?.value || "",
			})),
			{}
		);
	};

	const centerTable = () => {
		if (tableRef.current) {
			window.scrollTo({
				top: tableRef.current.offsetTop - 50, // Position table 50px from top
				behavior: "smooth",
			});
		}
	};

	const HasFilters = tableProperties.columns.filter((item) => item.Filter).length;

	return (
		<div>
			{showTotal ? <TotalSummaryStyled>{getTotalSummary()}</TotalSummaryStyled> : null}
			{!hideReset ? (
				<ResetFiltersStyled>
					{stickyHeader && (
						<a onClick={centerTable}>
							<CenterIcon />
							Center Table
						</a>
					)}
					<a onClick={resetFilters}>
						<RefreshIcon />
						Reset Filters
					</a>
				</ResetFiltersStyled>
			) : null}
			<TopScrollbar tableRef={tableRef} />
			<TableWrapper ref={tableRef} scaleTable={scaleTable} external={external} sub={sub} fixHeight={fixHeight} slim={slim} stickyHeader={stickyHeader}>
				<table>
					<thead>
						<tr>
							{tableProperties.columns
								.filter((item) => !item.Hide)
								.map((item, index) => (
									<th
										key={index}
										style={{
											...(item.Type === "Tooltip" || columnConfig?.find((col) => col.column === item.Collapse && col.hidden)
												? {
														minWidth: 0,
												  }
												: {}),
										}}
										className={defaultRequestFilter?.filterOptions?.find((filterOptions) => filterOptions?.column == item?.Value)?.value ? "defaultRequestFilter" : null}
									>
										<>
											{columnConfig?.find((col) => col.column === item.Collapse && col.hidden) ? (
												<>
													<THeadLabel
														external={external}
														sub={sub}
														vertical={item.Vertical ? true : false}
														help={item.Help}
														filter={item.Filter ? true : false}
														notVisible={true}
													>
														<TableColumnIcon type="maximise" onClick={() => updateColumnConfig(item.Collapse)} />
													</THeadLabel>
													<FilterPlaceholder />
												</>
											) : (
												<>
													<THeadLabel external={external} sub={sub} vertical={item.Vertical ? true : false} help={item.Help} filter={item.Filter ? true : false}>
														{name && item.Collapse ? <TableColumnIcon type="minimise" onClick={() => updateColumnConfig(item.Collapse)} /> : null}
														<span>{item.Name || ""}</span>
														{item.Order && (
															<SortWrapper active={state?.order && state?.order[item.OrderValue || item.Value] ? true : false}>
																<SortButton
																	data-tooltip-id="sort"
																	data-tooltip-content="Sort"
																	asc={
																		state.order
																			? state.order[item.OrderValue || item.Value]
																				? state.order[item.OrderValue || item.Value] === "Asc" || state.order[item.OrderValue || item.Value] === 1
																					? true
																					: false
																				: false
																			: false
																	}
																	desc={
																		state.order
																			? state.order[item.OrderValue || item.Value]
																				? state.order[item.OrderValue || item.Value] === "Desc" || state.order[item.OrderValue || item.Value] === -1
																					? true
																					: false
																				: false
																			: false
																	}
																	onClick={() => {
																		const currentOrder = state.order ? state.order[item.OrderValue || item.Value] : null;
																		let newOrder;
																		if (!currentOrder) {
																			newOrder = "Asc";
																		} else if (currentOrder === "Asc" || currentOrder === 1) {
																			newOrder = "Desc";
																		} else {
																			newOrder = "Desc";
																		}
																		orderBy(newOrder, item.Order, item.OrderValue || item.Value);
																	}}
																/>
															</SortWrapper>
														)}
														{item.Help ? <InputHelp help={item.Help} /> : null}
													</THeadLabel>
													{!!(HasFilters && item.Filter) ? (
														<>
															{item.Filter === "Search" ? (
																<FilterSearch>
																	<input
																		type="text"
																		className="searchFilters"
																		name={item.FilterValue || item.Value}
																		id={item.FilterValue || item.Value}
																		placeholder={`Filter by ${item.Name}`}
																		onChange={searchTextFilter}
																		defaultValue={state.filters.find((f) => f.column === item.Value)?.value ?? ""}
																	/>
																</FilterSearch>
															) : null}
															{item.Filter === "Options" || item.Filter === "MultiOptions" ? (
																<FilterSearch>
																	{item.Filter === "MultiOptions" ? (
																		<FilterMultiSelect
																			name={item.FilterValue || item.Value}
																			options={item.Options}
																			onChange={searchFilter}
																			value={state.filters.find((f) => f.column === item.Value)?.value ?? []}
																			placeholder="Filter"
																			includeSearch={true}
																			flush
																		/>
																	) : (
																		<FilterSelectInput
																			name={item.FilterValue || item.Value}
																			options={item.Options}
																			onChange={searchFilter}
																			defaultValue={state.filters.find((f) => f.column === item.Value)?.value ?? ""}
																			placeholder="Filter"
																			includeSearch={true}
																			flush
																		/>
																	)}
																</FilterSearch>
															) : null}
															{!item.Filter ? <FilterPlaceholder /> : null}
														</>
													) : HasFilters ? (
														<FilterPlaceholder />
													) : null}
												</>
											)}
										</>
									</th>
								))}
						</tr>
					</thead>
					<tbody>
						{state.resultItemsFiltered.map((item, index) => (
							<Tr
								key={index}
								clickable={tableProperties.navigate}
								onClick={() =>
									handleRowClick(
										tableProperties.navigate,
										tableProperties.navigate && tableProperties.navigate.Value ? item[tableProperties.navigate.Value] : null,
										tableProperties.navigate && tableProperties.navigate.ReturnType ? item : null
									)
								}
								highlightRow={item.highlightRow || false}
								tagHighlight={item.tagHighlight}
							>
								{tableProperties.columns
									.filter((item) => !item.Hide)
									.map((column, colIndex) => (
										<td
											key={index + colIndex}
											style={{
												textAlign: column.Slug ? "right" : "left",
												...(column.Type === "Tooltip" || columnConfig?.find((col) => col.column === column.Collapse && col.hidden)
													? {
															minWidth: 0,
													  }
													: {}),
											}}
											data-tooltip-id={rowTooltip?.length && column.Type !== "Jsx" && !column.Slug ? "compliance-tooltip" : undefined}
											data-tooltip-content={
												rowTooltip?.length && column.Type !== "Jsx" && !column.Slug ? rowTooltip.map((property: string) => item[property]).join(" ") : undefined
											}
										>
											<span
												style={
													column.Colour
														? {
																color: item[column.Colour],
														  }
														: null
												}
											>
												{columnConfig?.find((col) => col.column === column.Collapse && col.hidden) ? (
													<>&nbsp;</>
												) : (
													<>
														{column.Slug ? (
															column.NewTab ? (
																<NewTabAnchor
																	onClick={(e) => handleCellClick(e, `${column.Slug}${item[column.Value]}${column.After || ""}`, column.Href, column.NewTab)}
																>
																	{column.Text}
																</NewTabAnchor>
															) : (
																<StandardButtonAnchor
																	small
																	onClick={(e) => handleCellClick(e, `${column.Slug}${item[column.Value]}${column.After || ""}`, column.Href, column.NewTab)}
																>
																	{column.Text}
																</StandardButtonAnchor>
															)
														) : column.ReturnType ? (
															column.ReturnType === "summary" ? (
																column.ReturnTypeAction ? (
																	summaryButton(column.Text, column.ReturnTypeAction, item) // if action specified, render custom button
																) : (
																	<a onClick={(e) => returnItem(item)}>Select</a>
																)
															) : column.ReturnType === "item" ? (
																column.ReturnTypeAction ? (
																	tableButton(column.Text, column.ReturnTypeAction, column.ReturnType === "item" ? item : item[column.Value]) // if action specified, render custom button
																) : (
																	<a onClick={(e) => returnItem(item)}>Select</a>
																)
															) : (
																<a onClick={() => returnItem(item[column.Value])}>Select</a>
															)
														) : column.Type === "String" ? (
															formatValue(item[column.Value], column.Symbol, column.Enum)
														) : column.Type === "Image" ? (
															<img src={item[column.Value]} alt="" />
														) : column.Type === "Boolean" ? (
															item[column.Value] ? (
																"Yes"
															) : (
																"No"
															)
														) : column.Type === "BooleanIcon" ? (
															item[column.Value] ? (
																<TickIcon color={theme.green} large />
															) : (
																<CrossIcon color={theme.red} large />
															)
														) : column.Type === "Tooltip" ? (
															item[column.Value] ? (
																React.cloneElement(column.Content, {
																	value: item[column.Value],
																	item: item,
																})
															) : null
														) : column.Type === "Toggle" ? (
															<Toggle name={item._id} value={item[column.Value]} onChange={column.OnChange} />
														) : column.Type === "Select" ? (
															item[column.Value] ? (
																<TableButton remove onClick={(e) => returnItem(item)}>
																	Remove
																</TableButton>
															) : (
																<TableButton onClick={(e) => returnItem(item)}>Add</TableButton>
															)
														) : column.Type === "DateTime" ? (
															item[column.Value] ? (
																moment(item[column.Value], "YYYY-MM-DDTHH:mm:ssZ").format("DD/MM/YYYY HH:mm")
															) : (
																""
															)
														) : column.Type === "Jsx" ? (
															<TJsx>{item[column.Value]}</TJsx>
														) : item[column.Value] ? (
															moment(item[column.Value]).format("DD/MM/YYYY")
														) : (
															""
														)}
													</>
												)}
											</span>
										</td>
									))}
							</Tr>
						))}
						{!state.resultItemsFiltered.length && (
							<tr>
								<td colSpan={100}>
									<p>{defaultMessage || "There are no results."}</p>
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</TableWrapper>
			{rowTooltip?.length ? <Tooltip id="compliance-tooltip" style={{ backgroundColor: "#294338", color: "#fff", fontSize: "12px", padding: "5px" }} place="top" /> : null}
			<Tooltip id="summary" style={{ backgroundColor: theme.green, color: "#fff", fontSize: "12px", padding: "5px" }} place="top" />
			<Tooltip id="sort" style={{ backgroundColor: theme.green, color: "#fff", fontSize: "12px", padding: "5px" }} place="top" />
			<Tooltip id="expand" style={{ backgroundColor: theme.green, color: "#fff", fontSize: "12px", padding: "5px" }} place="top" />
			{!noPagination ? (
				<Pagination
					paginationItems={state.paginationItems}
					pageNo={state.pageNo}
					pageSize={state.pageSize}
					showAll={state.showAll}
					totalRecords={state.totalRecords}
					UpdatePage={UpdatePage}
					fullPagination={fullPagination}
					fullPaginationSlim={fullPaginationSlim}
				/>
			) : null}
		</div>
	);
};

export default Table;
