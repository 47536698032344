import { ContactRoles } from "../_enums/contactRoles.enum";
import { DocumentLevelTypes, RightToWork } from "../_enums/rightToWork.enum";
import { CandidateStatus } from "../_enums/candidateStatus.enum";
import { ReferenceTypes } from "../_enums/referenceTypes.enum";
import { ReferenceSubmissionTypes } from "../_enums/referenceSubmissionTypes.enum";
import { TimesheetStatus } from "../_enums/timesheetStatus.enum";
import { OccupationTypes } from "../_enums/occipationTypes.enum";
import { DBSTypes } from "../_enums/dbsTypes.enum";
import { TimesheetQueryReasonType } from "../_enums/timesheetQueryReasonType.enum";
import { BookingStatus } from "../_enums/bookingStatus.enum";
import { BookingSubType, BookingType } from "../_enums/bookingTypes.enum";
import { BookingDayStatus } from "../_enums/bookingDayStatus.enum";
import { CandidatePay, CandidatePaymentIntervals } from "../_enums/candidatePay.enum";
import { SalaryPay } from "../_enums/salaryPay.enum";
import { BookingPlaceholderCandidateStatus } from "../_enums/bookingPlaceholderCandidateStatus.enum";
import { GenderTypes } from "../_enums/gender.types.enum";
import { BookingDocTypes } from "../_enums/bookingDocTypes.enum";
import { AuditTypes, ChartTypes, GroupBy } from "../_enums/auditTypes.enum";
import { CandidateProcessingStatus } from "../_enums/candidateProcessingStatus.enum";
import { BooleanFilter } from "../_enums/booleanFilter.enum";
import { EmailPreferencesEnum } from "../_enums/emailPreference.enum";
import { AgreeDisagreeEnum } from "../_enums/agreeDisagree.enum";
import { TargetTypes } from "../_enums/targets.enum";
import { TransportTypes } from "../_enums/transportTypes.enum";
import { RemarksTags } from "../_enums/remarks.enum";
import { CandidateInterviewQuestonTypes, CandidateInterviewWorkTypes } from "../_models/data.candidateInterview.model";
import { EmailSmsPreferenceTypes } from "../_models/data.emailPreference";
import { AlternateCandidateSourceEnum, CandidateSourceEnum } from "../_enums/candidateSource.enum";
import { UserRole } from "../components/forms/user";
import { IndividualReportType } from "../_enums/reportType.enum";
import { NoticeTypes } from "../_enums/noticeTypes.enum";
import { EmploymentPreferenceTypes } from "../_enums/employmentPreferenceTypes.enum";
import { LocationTypes } from "../_enums/locationTypes.enum";
import { SubjectTypes } from "../_enums/subsidiarySubject.enum";
import { RatingTypes } from "../_enums/ofstedRating.enum";
import { InstitutionTypes } from "../_enums/governanceTypes.enum";
import { DataSyncProvidersEnum } from "../_enums/dataSyncProviders.enum";
import { BookingSortTypes } from "../_enums/bookingSortTypes.enum";

export const ContactRoleItems = [
	{ Name: "Headteacher", ID: ContactRoles.HeadTeacher },
	{ Name: "Assistant Headteacher", ID: ContactRoles.AssistantHeadteacher },
	{ Name: "Headteachers PA", ID: ContactRoles.HeadteachersPA },
	{ Name: "Cover Manager", ID: ContactRoles.CoverManager },
	{ Name: "HR Manager", ID: ContactRoles.HRManager },
	{ Name: "Finance Manager", ID: ContactRoles.FinanceManager },
	{ Name: "Invoice Contact", ID: ContactRoles.Invoice },
	{ Name: "Business Manager", ID: ContactRoles.BusinessManager },
	{ Name: "Business Administrator", ID: ContactRoles.BusinessAdministrator },
	{ Name: "Head of Maths", ID: ContactRoles.HeadMaths },
	{ Name: "Head of Science", ID: ContactRoles.HeadScience },
	{ Name: "Head of English", ID: ContactRoles.HeadEnglish },
	{ Name: "Deputy Headteacher", ID: ContactRoles.DeputyHead },
	{ Name: "SENCO", ID: ContactRoles.SENCO },
	{ Name: "Academy Recruitment Contact", ID: ContactRoles.AcademyRecruitmentContact },
	{ Name: "Nursery Manager", ID: ContactRoles.NurseryManager },
	{ Name: "CEO", ID: ContactRoles.CEO },
	{ Name: "COO", ID: ContactRoles.COO },
	{ Name: "Executive Headteacher", ID: ContactRoles.ExecutiveHeadteacher },
	{ Name: "Inclusion Manager", ID: ContactRoles.InclusionManager },
	{ Name: "EYFS Lead", ID: ContactRoles.EYFSLead },
	{ Name: "Admin Team", ID: ContactRoles.AdminTeam },
	{ Name: "Head of Humanities", ID: ContactRoles.HeadHumanities },
	{ Name: "Head of History", ID: ContactRoles.HeadHistory },
	{ Name: "Head of Geography", ID: ContactRoles.HeadGeography },
	{ Name: "Head of Business Studies", ID: ContactRoles.HeadBusinessStudies },
	{ Name: "Head of PE", ID: ContactRoles.HeadPE },
	{ Name: "Head of KS1", ID: ContactRoles.HeadKS1 },
	{ Name: "Head of KS2", ID: ContactRoles.HeadKS2 },
	{ Name: "Head of KS3", ID: ContactRoles.HeadKS3 },
	{ Name: "Head of KS4", ID: ContactRoles.HeadKS4 },
	{ Name: "Virtual School Headteacher", ID: ContactRoles.VirtualSchoolHeadteacher },
	{ Name: "Deputy Virtual School Headteacher", ID: ContactRoles.DeputyVirtualSchoolHeadteacher },
	{ Name: "Post-16 Team Leader", ID: ContactRoles.Post16TeamLeader },
	{ Name: "SEN Team", ID: ContactRoles.SENTeam },
	{ Name: "Inclusion Team", ID: ContactRoles.InclusionTeam },
	{ Name: "UASC Team", ID: ContactRoles.UASCTeam },
	{ Name: "Caseworker", ID: ContactRoles.Caseworker },
	{ Name: "Designated Teacher", ID: ContactRoles.DesignatedTeacher },
	{ Name: "Attendance Officer", ID: ContactRoles.AttendanceOfficer },
	{ Name: "Coordinator", ID: ContactRoles.Coordinator },
	{ Name: "Childrens Commissioner", ID: ContactRoles.ChildrensCommissioner },
	{ Name: "Other", ID: ContactRoles.Other },
];

export const RightToWorkItems = [
	{ Name: "UK Passport", ID: RightToWork.UKPassport },
	{ Name: "Birth Certificate", ID: RightToWork.BirthCertificate },
	{ Name: "European Passport", ID: RightToWork.EuropeanPassport },
	{ Name: "VISA", ID: RightToWork.Visa },
	{ Name: "Mandatory Training", ID: RightToWork.MandatoryTraining },
	{ Name: "Manual Handling Training", ID: RightToWork.ManualHandlingTraining },
	{ Name: "Crisis Training", ID: RightToWork.CrisisTraining },
	{ Name: "Nurse Training", ID: RightToWork.NurseTraining },
	{ Name: "Immunisation Report", ID: RightToWork.ImmunisationReport },
	{ Name: "GMC Certificate (Copy of original)", ID: RightToWork.GMCCertificate },
	{ Name: "NMC Certificate", ID: RightToWork.NMCCertificate },
	{ Name: "HCPC Certificate", ID: RightToWork.HCPCCertificate },
	{ Name: "Basic Life Support Certificate", ID: RightToWork.BasicLifeSupportCertificate },
	{ Name: "DBS", ID: RightToWork.DBS },
	{ Name: "Qualification", ID: RightToWork.Qualification },
	{ Name: "Proof of Address", ID: RightToWork.ProofOfAddress },
	{ Name: "Driving Licence", ID: RightToWork.DrivingLicense },
	{ Name: "Prohibition Check", ID: RightToWork.ProhibitionCheck },
	{ Name: "QTS Certificate", ID: RightToWork.QTSCertificate },
	{ Name: "Reference", ID: RightToWork.Reference },
	{ Name: "DBS Update Service", ID: RightToWork.DBSUpdateService },
	{ Name: "Home Office Share Code", ID: RightToWork.HomeOfficeShareCode },
	{ Name: "CV", ID: RightToWork.CV },
	{ Name: "National Insurance", ID: RightToWork.NationalInsurance },
	{ Name: "Safeguarding Certificate with Expiry", ID: RightToWork.SafeguardingCertificateWithExpiry },
	{ Name: "Safeguarding Certificate", ID: RightToWork.SafeguardingCertificate },
	{ Name: "RTW Verification", ID: RightToWork.RTWVerification },
	{ Name: "Proof of Name Change", ID: RightToWork.ProofOfNameChange },
	{ Name: "Overseas Criminal Record Check", ID: RightToWork.OverseasCriminalRecordCheck },
	{ Name: "Foreign Passport", ID: RightToWork.ForeignPassport },
	{ Name: "Interview Notes", ID: RightToWork.InterviewNotes },
	{ Name: "EWC Confirmation", ID: RightToWork.EWCConfirmation },
	{ Name: "EWC Confirmation (Internal Only)", ID: RightToWork.EWCConfirmationInternalOnly },
	{ Name: "DFE/TRA Check", ID: RightToWork.DfeCheck },
	{ Name: "Face to Face Notes", ID: RightToWork.FaceToFace },
	{ Name: "10 Year Work History", ID: RightToWork.TenYearWorkHistory },
	{ Name: "Imposter Check", ID: RightToWork.ImposterCheck },
	{ Name: "Biometric Residence Permit", ID: RightToWork.BiometricResidencePermit },
	{ Name: "RTW Photo Proof", ID: RightToWork.RTWPhoto },
	{ Name: "Declaration Pack", ID: RightToWork.DeclarationPack },
	{ Name: "Prevent Training", ID: RightToWork.PreventTraining },
	{ Name: "Cyber Security", ID: RightToWork.CyberSecurity },
	{ Name: "Disclaimer", ID: RightToWork.Disclaimer },
	{ Name: "Online Search", ID: RightToWork.OnlineSearch },
	{ Name: "Mandatory Training Certificate", ID: RightToWork.MandatoryTrainingCertificate },
	{ Name: "Proof of Bank Account", ID: RightToWork.ProofOfBankAccount },
	{ Name: "Application/Registration Form", ID: RightToWork.ApplicationRegistrationForm },
	{ Name: "Proof of NMC Registration", ID: RightToWork.ProofOfNMCRegistration },
	{ Name: "Staff Profile", ID: RightToWork.StaffProfile },
	{ Name: "Other", ID: RightToWork.Other },
];

export const BookingDocumentTypes = [
	{ Name: "Candidate attachment", ID: BookingDocTypes.candidate },
	{ Name: "Client attachment", ID: BookingDocTypes.client },
	{ Name: "Candidate & Client attachment", ID: BookingDocTypes.both },
];

export const DocumentLevelItems = [
	{ Name: "Candidate Document", ID: DocumentLevelTypes.CompanyCandidates },
	{ Name: "Client Document", ID: DocumentLevelTypes.CompanyClients },
	{ Name: "Booking Attachment Client - Automatically included", ID: DocumentLevelTypes.CompanyBookingsClient },
	{ Name: "Booking Attachment Candidate - Automatically included", ID: DocumentLevelTypes.CompanyBookingsCandidate },
	{ Name: "Booking Attachment Both - Automatically included", ID: DocumentLevelTypes.CompanyBookingsBoth },
];

export const CandidateStatusItems = [
	{ Name: "Approved", ID: CandidateStatus.Approved },
	{ Name: "Pending", ID: CandidateStatus.Pending },
	{ Name: "Archived", ID: CandidateStatus.Rejected },
];

export const CandidateStatusItemsNonApproved = [
	{ Name: "Pending", ID: CandidateStatus.Pending },
	{ Name: "Archived", ID: CandidateStatus.Rejected },
];

export const CandidateStatusItemsAll = [
	{ Name: "Pending", ID: CandidateStatus.Pending },
	{ Name: "Approved", ID: CandidateStatus.Approved },
	{ Name: "Archived", ID: CandidateStatus.Rejected },
	{ Name: "Applicant", ID: CandidateStatus.Enquired },
];

export const CandidateBookingPlaceholderStatusItems = [
	{ Name: "Pending", ID: BookingPlaceholderCandidateStatus.Pending },
	{ Name: "Interested", ID: BookingPlaceholderCandidateStatus.Interested },
	{ Name: "Not Interested", ID: BookingPlaceholderCandidateStatus.NotInterested },
];

export const CandidatePayItems = [
	{ Name: "Paye", ID: CandidatePay.Paye },
	{ Name: "Umbrella", ID: CandidatePay.Umbrella },
	{ Name: "Limited Company", ID: CandidatePay.LtdCompany },
];

export const CandidatePaymentIntervalItems = [
	{ Name: "Monthly", ID: CandidatePaymentIntervals.Monthly },
	{ Name: "Weekly", ID: CandidatePaymentIntervals.Weekly },
	{ Name: "Bi-Weekly", ID: CandidatePaymentIntervals.Biweekly },
];

export const CandidateSexItems = [
	{ Name: "Male", ID: GenderTypes.Male },
	{ Name: "Female", ID: GenderTypes.Female },
	{ Name: "Non-Binary/Other", ID: GenderTypes.Other },
	{ Name: "Prefer Not to Say", ID: GenderTypes.Unknown },
];

export const DBSTypeItems = [
	{ Name: "Basic", ID: DBSTypes.Basic },
	{ Name: "Standard", ID: DBSTypes.Standard },
	{ Name: "Enhanced", ID: DBSTypes.Enhanced },
	{ Name: "Enhanced with Barred List", ID: DBSTypes.EnhancedBarredList },
];

export const OccupationItems = [
	{ Name: "Teacher", ID: OccupationTypes.Teacher },
	{ Name: "Cover Supervisor", ID: OccupationTypes.CoverSupervisor },
	{ Name: "Teaching Assistant", ID: OccupationTypes.TeachingAssistant },
	{ Name: "School Support Staff", ID: OccupationTypes.SchoolSupportStaff },
	{ Name: "Primary Teacher", ID: OccupationTypes.PrimaryTeacher },
	{ Name: "Secondary Teacher", ID: OccupationTypes.SecondaryTeacher },
	{ Name: "Head Teacher", ID: OccupationTypes.HeadTeacher },
	{ Name: "Senior Leader", ID: OccupationTypes.SeniorLeader },
	{ Name: "Tutor", ID: OccupationTypes.Tutor },
	{ Name: "Mentor", ID: OccupationTypes.Mentor },
	{ Name: "Learning Support Assistant", ID: OccupationTypes.LearningSupportAssistant },
	{ Name: "Finance Officer", ID: OccupationTypes.FinanceOfficer },
	{ Name: "HR Administrator", ID: OccupationTypes.HRAdministrator },
	{ Name: "HR Manager", ID: OccupationTypes.HRManager },
	{ Name: "Office Manager", ID: OccupationTypes.OfficeManager },
	{ Name: "Administrator", ID: OccupationTypes.Administrator },
	{ Name: "Personal Assistant", ID: OccupationTypes.PersonalAssistant },
	{ Name: "Pastoral Manager", ID: OccupationTypes.PastoralManager },
	{ Name: "Receptionist", ID: OccupationTypes.Receptionist },
	{ Name: "Instructor", ID: OccupationTypes.Instructor },
	{ Name: "Caretaker", ID: OccupationTypes.Caretaker },
	{ Name: "Nursery Assistant", ID: OccupationTypes.NurseryAssistant },
	{ Name: "Nursery Nurse", ID: OccupationTypes.NurseryNurse },
	{ Name: "Deputy Nursery Manager", ID: OccupationTypes.DeputyNurseryManager },
	{ Name: "Lunchtime Supervisor", ID: OccupationTypes.LunchtimeSupervisor },
	{ Name: "Classroom Graduate", ID: OccupationTypes.ClassroomGraduate },
	{ Name: "Primary Cover Supervisor", ID: OccupationTypes.PrimaryCoverSupervisor },
	{ Name: "HLTA", ID: OccupationTypes.HLTA },
	{ Name: "Nurture Teacher", ID: OccupationTypes.NurtureTeacher },
	{ Name: "Nurture Mentor", ID: OccupationTypes.NurtureMentor },
	{ Name: "SENDCo", ID: OccupationTypes.SENDCo },
	{ Name: "Registered Manager", ID: OccupationTypes.RegisteredManager },
	{ Name: "Regional Manager", ID: OccupationTypes.RegionalManager },
	{ Name: "Education Director", ID: OccupationTypes.EducationDirector },
	{ Name: "Residential Director", ID: OccupationTypes.ResidentialDirector },
	{ Name: "Deputy Manager", ID: OccupationTypes.DeputyManager },
	{ Name: "Residential Support Worker", ID: OccupationTypes.ResidentialSupportWorker },
	{ Name: "Sports Coach", ID: OccupationTypes.SportsCoach },
	{ Name: "IT Technician", ID: OccupationTypes.ITTechnician },
	{ Name: "Art Technician", ID: OccupationTypes.ArtTechnician },
	{ Name: "DT Technician", ID: OccupationTypes.DTTechnician },
	{ Name: "Science Technician", ID: OccupationTypes.ScienceTechnician },
	{ Name: "Executive Headteacher", ID: OccupationTypes.ExecutiveHeadteacher },
	{ Name: "General Practitioner", ID: OccupationTypes.GeneralPractitioner },
	{ Name: "Advance Nurse Practitioner (ANP)", ID: OccupationTypes.AdvanceNursePractitioner },
	{ Name: "Emergency Nurse Practitioner (ENP)", ID: OccupationTypes.EmergencyNursePractitioner },
	{ Name: "Advanced Clinical Practitioner (ACP)", ID: OccupationTypes.AdvancedClinicalPractitioner },
	{ Name: "Emergency Clinical Practitioner (ECP)", ID: OccupationTypes.EmergencyClinicalPractitioner },
	{ Name: "Consultant Psychiatrist", ID: OccupationTypes.ConsultantPsychiatrist },
	{ Name: "Psychiatrist - SPR", ID: OccupationTypes.PsychiatristSPR },
	{ Name: "Healthcare Assistant/ Residential Support Worker", ID: OccupationTypes.HealthcareAssistantResidentialSupportWorker },
	{ Name: "Healthcare Assistant", ID: OccupationTypes.HealthcareAssistant },
	{ Name: "Senior Healthcare Assistant", ID: OccupationTypes.SeniorHealthcareAssistant },
	{ Name: "Team Leader", ID: OccupationTypes.TeamLeader },
	{ Name: "Registered General Nurse", ID: OccupationTypes.RegisteredGeneralNurse },
	{ Name: "Registered Mental Nurse", ID: OccupationTypes.RegisteredMentalNurse },
	{ Name: "Registered Nurse Learning Disabilities", ID: OccupationTypes.RegisteredNurseLearningDisabilities },
	{ Name: "Overeas Trained Teacher", ID: OccupationTypes.OverseasTrainedTeacher },
];

export const MedicalOccupationItems = [
	{ Name: "General Practitioner", ID: OccupationTypes.GeneralPractitioner },
	{ Name: "Advance Nurse Practitioner (ANP)", ID: OccupationTypes.AdvanceNursePractitioner },
	{ Name: "Emergency Nurse Practitioner (ENP)", ID: OccupationTypes.EmergencyNursePractitioner },
	{ Name: "Advanced Clinical Practitioner (ACP)", ID: OccupationTypes.AdvancedClinicalPractitioner },
	{ Name: "Emergency Clinical Practitioner (ECP)", ID: OccupationTypes.EmergencyClinicalPractitioner },
	{ Name: "Consultant Psychiatrist", ID: OccupationTypes.ConsultantPsychiatrist },
	{ Name: "Psychiatrist - SPR", ID: OccupationTypes.PsychiatristSPR },
	{ Name: "Residential Support Worker", ID: OccupationTypes.ResidentialSupportWorker },
	{ Name: "Healthcare Assistant/ Residential Support Worker", ID: OccupationTypes.HealthcareAssistantResidentialSupportWorker },
	{ Name: "Healthcare Assistant", ID: OccupationTypes.HealthcareAssistant },
	{ Name: "Senior Healthcare Assistant", ID: OccupationTypes.SeniorHealthcareAssistant },
	{ Name: "Team Leader", ID: OccupationTypes.TeamLeader },
	{ Name: "Registered General Nurse", ID: OccupationTypes.RegisteredGeneralNurse },
	{ Name: "Registered Mental Nurse", ID: OccupationTypes.RegisteredMentalNurse },
	{ Name: "Registered Nurse Learning Disabilities", ID: OccupationTypes.RegisteredNurseLearningDisabilities },
];

export const TeacherOccupationItems = [
	{ Name: "Teacher", ID: OccupationTypes.Teacher },
	{ Name: "Primary Teacher", ID: OccupationTypes.PrimaryTeacher },
	{ Name: "Secondary Teacher", ID: OccupationTypes.SecondaryTeacher },
	{ Name: "Cover Supervisor", ID: OccupationTypes.CoverSupervisor },
	{ Name: "Teaching Assistant", ID: OccupationTypes.TeachingAssistant },
	{ Name: "School Support Staff", ID: OccupationTypes.SchoolSupportStaff },
];

export const CandidateSourceItems = [
	{ Name: "Company Website", ID: CandidateSourceEnum.CompanyWebsite },
	{ Name: "iCanTeachUK Website", ID: CandidateSourceEnum.iCanTeachUKWebsite },
	{ Name: "Apply to Education", ID: CandidateSourceEnum.ApplytoEducation },
	{ Name: "CVLibrary CV Search", ID: CandidateSourceEnum.CVLibraryCVSearch },
	{ Name: "CVLibrary Advert", ID: CandidateSourceEnum.CVLibraryAdvert },
	{ Name: "CV Library - Watchdog", ID: CandidateSourceEnum.CVLibraryWatchdog },
	{ Name: "Guardian CV Search", ID: CandidateSourceEnum.GuardianCVSearch },
	{ Name: "Guardian Advert", ID: CandidateSourceEnum.GuardianAdvert },
	{ Name: "Indeed CV Search", ID: CandidateSourceEnum.IndeedCVSearch },
	{ Name: "Indeed Advert", ID: CandidateSourceEnum.IndeedAdvert },
	{ Name: "Reed CV Search", ID: CandidateSourceEnum.ReedCVSearch },
	{ Name: "Reed Advert", ID: CandidateSourceEnum.ReedAdvert },
	{ Name: "Reed – Watchdog", ID: CandidateSourceEnum.ReedWatchdog },
	{ Name: "SENploy Advert", ID: CandidateSourceEnum.SENployAdvert },
	{ Name: "Total Jobs", ID: CandidateSourceEnum.TotalJobs },
	{ Name: "Other Job Board", ID: CandidateSourceEnum.OtherJobBoard },
	{ Name: "Google", ID: CandidateSourceEnum.Google },
	{ Name: "LinkedIn", ID: CandidateSourceEnum.LinkedIn },
	{ Name: "Linkedin Recruiter", ID: CandidateSourceEnum.LinkedinRecruiter },
	{ Name: "LinkedIn Advert", ID: CandidateSourceEnum.LinkedInAdvert },
	{ Name: "Referral School", ID: CandidateSourceEnum.ReferralSchool },
	{ Name: "Referral Candidate", ID: CandidateSourceEnum.ReferralCandidate },
	{ Name: "UK University", ID: CandidateSourceEnum.UKUniversity },
	{ Name: "Irish University", ID: CandidateSourceEnum.IrishUniversity },
	{ Name: "Canadian University", ID: CandidateSourceEnum.CanadianUniversity },
	{ Name: "Marketing Campaign", ID: CandidateSourceEnum.MarketingCampaign },
	{ Name: "Woo", ID: CandidateSourceEnum.Woo },
	{ Name: "Job Show", ID: CandidateSourceEnum.JobShow },
	{ Name: "Recruitment Fair", ID: CandidateSourceEnum.RecruitmentFair },
	{ Name: "Career Fair", ID: CandidateSourceEnum.CareerFair },
	{ Name: "Other", ID: CandidateSourceEnum.Other },
];

export const AlternateCandidateSourceItems = [
	{ Name: "Company Website", ID: AlternateCandidateSourceEnum.CompanyWebsite },
	{ Name: "Direct Call", ID: AlternateCandidateSourceEnum.DirectCall },
	{ Name: "CV Library CV Search", ID: AlternateCandidateSourceEnum.CvLibraryCvSearch },
	{ Name: "CV Library Advert", ID: AlternateCandidateSourceEnum.CvLibraryAdvert },
	{ Name: "CV Library - Watchdog", ID: AlternateCandidateSourceEnum.CvLibraryWatchdog },
	{ Name: "Reed CV Search", ID: AlternateCandidateSourceEnum.ReedCvSearch },
	{ Name: "Reed Advert", ID: AlternateCandidateSourceEnum.ReedAdvert },
	{ Name: "Reed - Watchdog", ID: AlternateCandidateSourceEnum.ReedWatchdog },
	{ Name: "Indeed CV Search", ID: AlternateCandidateSourceEnum.IndeedCvSearch },
	{ Name: "Indeed Advert", ID: AlternateCandidateSourceEnum.IndeedAdvert },
	{ Name: "Indeed - Watchdog", ID: AlternateCandidateSourceEnum.IndeedWatchdog },
	{ Name: "Job Centre", ID: AlternateCandidateSourceEnum.JobCentre },
	{ Name: "Returning Candidate", ID: AlternateCandidateSourceEnum.ReturningCandidate },
	{ Name: "Referral Candidate", ID: AlternateCandidateSourceEnum.ReferralCandidate },
	{ Name: "Referral Client", ID: AlternateCandidateSourceEnum.ReferralClient },
	{ Name: "Training Provider", ID: AlternateCandidateSourceEnum.TrainingProvider },
	{ Name: "Recruitment Fair", ID: AlternateCandidateSourceEnum.RecruitmentFair },
	{ Name: "University/College", ID: AlternateCandidateSourceEnum.UniversityCollege },
];
export const subjectToNoticeItems = [
	{ Name: "Unsure", ID: NoticeTypes.Unsure },
	{ Name: "Yes", ID: NoticeTypes.Yes },
	{ Name: "No", ID: NoticeTypes.No },
];

export const employmentPreferenceItems = [
	{ Name: "Day To Day", ID: EmploymentPreferenceTypes.DayToDay },
	{ Name: "Long Term", ID: EmploymentPreferenceTypes.LongTerm },
	{ Name: "Fixed Term", ID: EmploymentPreferenceTypes.FixedTerm },
	{ Name: "Perm", ID: EmploymentPreferenceTypes.Perm },
];

export const locationItems = [
	{ Name: "All Locations", ID: LocationTypes.AllLocations },
	{ Name: "All Locations minus London", ID: LocationTypes.AllLocationsMinusLondon },
	{ Name: "Aylesbury Vale", ID: LocationTypes.AylesburyVale },
	{ Name: "Barking and Dagenham", ID: LocationTypes.BarkingAndDagenham },
	{ Name: "Barnet", ID: LocationTypes.Barnet },
	{ Name: "Based Overseas", ID: LocationTypes.BasedOverseas },
	{ Name: "Based Overseas - Canada", ID: LocationTypes.BasedOverseasCanada },
	{ Name: "Based Overseas - Ireland", ID: LocationTypes.BasedOverseasIreland },
	{ Name: "Based Overseas - Jamaica", ID: LocationTypes.BasedOverseasJamaica },
	{ Name: "Based Overseas - Northern Ireland", ID: LocationTypes.BasedOverseasNorthernIreland },
	{ Name: "Based Overseas - Other", ID: LocationTypes.BasedOverseasOther },
	{ Name: "Based Overseas - Scotland", ID: LocationTypes.BasedOverseasScotland },
	{ Name: "Based Overseas - South Africa", ID: LocationTypes.BasedOverseasSouthAfrica },
	{ Name: "Based Overseas - USA", ID: LocationTypes.BasedOverseasUSA },
	{ Name: "Based Overseas - Wales", ID: LocationTypes.BasedOverseasWales },
	{ Name: "Based Overseas - Zimbabwe", ID: LocationTypes.BasedOverseasZimbabwe },
	{ Name: "Based Overseas-Australia", ID: LocationTypes.BasedOverseasAustralia },
	{ Name: "Bath & North East Somerset", ID: LocationTypes.BathAndNorthEastSomerset },
	{ Name: "Beaconsfiled", ID: LocationTypes.Beaconsfiled },
	{ Name: "Bedfordshire", ID: LocationTypes.Bedfordshire },
	{ Name: "Berkshire", ID: LocationTypes.Berkshire },
	{ Name: "Berkshire Central", ID: LocationTypes.BerkshireCentral },
	{ Name: "Berkshire East", ID: LocationTypes.BerkshireEast },
	{ Name: "Berkshire West", ID: LocationTypes.BerkshireWest },
	{ Name: "Bexley", ID: LocationTypes.Bexley },
	{ Name: "Birmingham", ID: LocationTypes.Birmingham },
	{ Name: "Brent", ID: LocationTypes.Brent },
	{ Name: "Brighton and Hove", ID: LocationTypes.BrightonAndHove },
	{ Name: "Bristol City of", ID: LocationTypes.BristolCityOf },
	{ Name: "Bromley", ID: LocationTypes.Bromley },
	{ Name: "Buckingham", ID: LocationTypes.Buckingham },
	{ Name: "Buckinghamshire", ID: LocationTypes.Buckinghamshire },
	{ Name: "Cambridgeshire", ID: LocationTypes.Cambridgeshire },
	{ Name: "Cambridgeshire South", ID: LocationTypes.CambridgeshireSouth },
	{ Name: "Cambridge", ID: LocationTypes.Cambridge },
	{ Name: "East Cambridge", ID: LocationTypes.EastCambridge },
	{ Name: "Camden", ID: LocationTypes.Camden },
	{ Name: "Chesham", ID: LocationTypes.Chesham },
	{ Name: "Cheshire East", ID: LocationTypes.CheshireEast },
	{ Name: "Corby", ID: LocationTypes.Corby },
	{ Name: "Central Beds", ID: LocationTypes.CentralBeds },
	{ Name: "South Beds", ID: LocationTypes.SouthBeds },
	{ Name: "Cornwall", ID: LocationTypes.Cornwall },
	{ Name: "Coventry", ID: LocationTypes.Coventry },
	{ Name: "Croydon", ID: LocationTypes.Croydon },
	{ Name: "Cumbria", ID: LocationTypes.Cumbria },
	{ Name: "Daventry", ID: LocationTypes.Daventry },
	{ Name: "Derbyshire", ID: LocationTypes.Derbyshire },
	{ Name: "Devon", ID: LocationTypes.Devon },
	{ Name: "Dorset", ID: LocationTypes.Dorset },
	{ Name: "Dorset North", ID: LocationTypes.DorsetNorth },
	{ Name: "Dorset South", ID: LocationTypes.DorsetSouth },
	{ Name: "Dorset South East", ID: LocationTypes.DorsetSouthEast },
	{ Name: "Dudley", ID: LocationTypes.Dudley },
	{ Name: "Durham", ID: LocationTypes.Durham },
	{ Name: "Ealing", ID: LocationTypes.Ealing },
	{ Name: "East London", ID: LocationTypes.EastLondon },
	{ Name: "East Oxfordshire", ID: LocationTypes.EastOxfordshire },
	{ Name: "East Riding of Yorkshire", ID: LocationTypes.EastRidingOfYorkshire },
	{ Name: "East Sussex", ID: LocationTypes.EastSussex },
	{ Name: "Enfield", ID: LocationTypes.Enfield },
	{ Name: "Essex", ID: LocationTypes.Essex },
	{ Name: "Essex Central", ID: LocationTypes.EssexCentral },
	{ Name: "Essex East", ID: LocationTypes.EssexEast },
	{ Name: "Essex North", ID: LocationTypes.EssexNorth },
	{ Name: "Essex South", ID: LocationTypes.EssexSouth },
	{ Name: "Essex West", ID: LocationTypes.EssexWest },
	{ Name: "Gloucestershire", ID: LocationTypes.Gloucestershire },
	{ Name: "Greater London", ID: LocationTypes.GreaterLondon },
	{ Name: "Greater Manchester", ID: LocationTypes.GreaterManchester },
	{ Name: "Greenwich", ID: LocationTypes.Greenwich },
	{ Name: "Hackney", ID: LocationTypes.Hackney },
	{ Name: "Hammersmith and Fulham", ID: LocationTypes.HammersmithAndFulham },
	{ Name: "Hampshire", ID: LocationTypes.Hampshire },
	{ Name: "Hampshire - Isle of Wight", ID: LocationTypes.HampshireIsleOfWight },
	{ Name: "Hampshire North", ID: LocationTypes.HampshireNorth },
	{ Name: "Hampshire South East", ID: LocationTypes.HampshireSouthEast },
	{ Name: "Hampshire South West", ID: LocationTypes.HampshireSouthWest },
	{ Name: "Haringey", ID: LocationTypes.Haringey },
	{ Name: "Harrow", ID: LocationTypes.Harrow },
	{ Name: "Havering", ID: LocationTypes.Havering },
	{ Name: "Herefordshire", ID: LocationTypes.Herefordshire },
	{ Name: "Hertfordshire", ID: LocationTypes.Hertfordshire },
	{ Name: "Herts Bishop Stortford", ID: LocationTypes.HertsBishopStortford },
	{ Name: "Herts Central", ID: LocationTypes.HertsCentral },
	{ Name: "Herts North", ID: LocationTypes.HertsNorth },
	{ Name: "Herts Royston", ID: LocationTypes.HertsRoyston },
	{ Name: "Herts West", ID: LocationTypes.HertsWest },
	{ Name: "High Wycombe", ID: LocationTypes.HighWycombe },
	{ Name: "Hillingdon", ID: LocationTypes.Hillingdon },
	{ Name: "Hounslow", ID: LocationTypes.Hounslow },
	{ Name: "Inner London", ID: LocationTypes.InnerLondon },
	{ Name: "Islington", ID: LocationTypes.Islington },
	{ Name: "Kensington and Chelsea", ID: LocationTypes.KensingtonAndChelsea },
	{ Name: "Kent", ID: LocationTypes.Kent },
	{ Name: "Kent East", ID: LocationTypes.KentEast },
	{ Name: "Kent South East", ID: LocationTypes.KentSouthEast },
	{ Name: "Kent West", ID: LocationTypes.KentWest },
	{ Name: "Kent Central", ID: LocationTypes.KentCentral },
	{ Name: "Kent North", ID: LocationTypes.KentNorth },
	{ Name: "Kettering", ID: LocationTypes.Kettering },
	{ Name: "Kingston upon Thames", ID: LocationTypes.KingstonUponThames },
	{ Name: "Lambeth", ID: LocationTypes.Lambeth },
	{ Name: "Lancashire", ID: LocationTypes.Lancashire },
	{ Name: "Leeds", ID: LocationTypes.Leeds },
	{ Name: "Leicester", ID: LocationTypes.Leicester },
	{ Name: "Leicestershire", ID: LocationTypes.Leicestershire },
	{ Name: "Lewisham", ID: LocationTypes.Lewisham },
	{ Name: "Lincolnshire", ID: LocationTypes.Lincolnshire },
	{ Name: "Merseyside", ID: LocationTypes.Merseyside },
	{ Name: "Merton", ID: LocationTypes.Merton },
	{ Name: "Milton Keynes", ID: LocationTypes.MiltonKeynes },
	{ Name: "Newcastle upon Tyne", ID: LocationTypes.NewcastleUponTyne },
	{ Name: "Newham", ID: LocationTypes.Newham },
	{ Name: "Norfolk", ID: LocationTypes.Norfolk },
	{ Name: "Norfolk East", ID: LocationTypes.NorfolkEast },
	{ Name: "Norfolk North", ID: LocationTypes.NorfolkNorth },
	{ Name: "Norfolk South", ID: LocationTypes.NorfolkSouth },
	{ Name: "Norfolk West", ID: LocationTypes.NorfolkWest },
	{ Name: "North East Lincolnshire", ID: LocationTypes.NorthEastLincolnshire },
	{ Name: "North Lincolnshire", ID: LocationTypes.NorthLincolnshire },
	{ Name: "Luton", ID: LocationTypes.Luton },
	{ Name: "North London", ID: LocationTypes.NorthLondon },
	{ Name: "North Oxfordshire", ID: LocationTypes.NorthOxfordshire },
	{ Name: "North Somerset", ID: LocationTypes.NorthSomerset },
	{ Name: "North Yorkshire", ID: LocationTypes.NorthYorkshire },
	{ Name: "Northampton", ID: LocationTypes.Northampton },
	{ Name: "Northamptonshire", ID: LocationTypes.Northamptonshire },
	{ Name: "Northumberland", ID: LocationTypes.Northumberland },
	{ Name: "Nottinghamshire", ID: LocationTypes.Nottinghamshire },
	{ Name: "NW London", ID: LocationTypes.NWLondon },
	{ Name: "Outer London", ID: LocationTypes.OuterLondon },
	{ Name: "Overseas", ID: LocationTypes.Overseas },
	{ Name: "Overseas UAE", ID: LocationTypes.OverseasUAE },
	{ Name: "Oxford", ID: LocationTypes.Oxford },
	{ Name: "Oxfordshire", ID: LocationTypes.Oxfordshire },
	{ Name: "Peterborough", ID: LocationTypes.Peterborough },
	{ Name: "Princes Risborough", ID: LocationTypes.PrincesRisborough },
	{ Name: "Redbridge", ID: LocationTypes.Redbridge },
	{ Name: "Richmond upon Thames", ID: LocationTypes.RichmondUponThames },
	{ Name: "SE Herts", ID: LocationTypes.SEHerts },
	{ Name: "SE London", ID: LocationTypes.SELondon },
	{ Name: "Shropshire", ID: LocationTypes.Shropshire },
	{ Name: "Slough", ID: LocationTypes.Slough },
	{ Name: "Solihull", ID: LocationTypes.Solihull },
	{ Name: "Somerset", ID: LocationTypes.Somerset },
	{ Name: "South Gloucestershire", ID: LocationTypes.SouthGloucestershire },
	{ Name: "South Oxfordshire", ID: LocationTypes.SouthOxfordshire },
	{ Name: "Bristol", ID: LocationTypes.Bristol },
	{ Name: "South Yorkshire", ID: LocationTypes.SouthYorkshire },
	{ Name: "Southwark", ID: LocationTypes.Southwark },
	{ Name: "Staffordshire", ID: LocationTypes.Staffordshire },
	{ Name: "Suffolk", ID: LocationTypes.Suffolk },
	{ Name: "Surrey", ID: LocationTypes.Surrey },
	{ Name: "Surrey Central", ID: LocationTypes.SurreyCentral },
	{ Name: "Surrey North", ID: LocationTypes.SurreyNorth },
	{ Name: "Surrey South East", ID: LocationTypes.SurreySouthEast },
	{ Name: "Surrey South West", ID: LocationTypes.SurreySouthWest },
	{ Name: "Sutton", ID: LocationTypes.Sutton },
	{ Name: "SW London", ID: LocationTypes.SWLondon },
	{ Name: "Thurrock", ID: LocationTypes.Thurrock },
	{ Name: "Tower Hamlets", ID: LocationTypes.TowerHamlets },
	{ Name: "Tyne and Wear", ID: LocationTypes.TyneAndWear },
	{ Name: "Wales", ID: LocationTypes.Wales },
	{ Name: "Waltham Forest", ID: LocationTypes.WalthamForest },
	{ Name: "Wandsworth", ID: LocationTypes.Wandsworth },
	{ Name: "Warwickshire", ID: LocationTypes.Warwickshire },
	{ Name: "Wellingborough", ID: LocationTypes.Wellingborough },
	{ Name: "West", ID: LocationTypes.West },
	{ Name: "West London", ID: LocationTypes.WestLondon },
	{ Name: "West Midlands", ID: LocationTypes.WestMidlands },
	{ Name: "West Oxfordshire", ID: LocationTypes.WestOxfordshire },
	{ Name: "West Sussex", ID: LocationTypes.WestSussex },
	{ Name: "West Yorkshire", ID: LocationTypes.WestYorkshire },
	{ Name: "Westminster", ID: LocationTypes.Westminster },
	{ Name: "Wiltshire", ID: LocationTypes.Wiltshire },
	{ Name: "Wiltshire North", ID: LocationTypes.WiltshireNorth },
	{ Name: "Wiltshire South", ID: LocationTypes.WiltshireSouth },
	{ Name: "Wiltshire West", ID: LocationTypes.WiltshireWest },
	{ Name: "Bedford Borough", ID: LocationTypes.BedfordBorough },
	{ Name: "Huntingdonshire", ID: LocationTypes.Huntingdonshire },
	{ Name: "Fenlands", ID: LocationTypes.Fenlands },
	{ Name: "Wolverhampton", ID: LocationTypes.Wolverhampton },
	{ Name: "Overseas – Central & Eastern Asia (all)", ID: LocationTypes.OverseasCentralAndEasternAsiaAll },
	{ Name: "Overseas – Central & Eastern Asia - China", ID: LocationTypes.OverseasCentralAndEasternAsiaChina },
	{ Name: "Overseas – Central & Eastern Asia - Japan", ID: LocationTypes.OverseasCentralAndEasternAsiaJapan },
	{ Name: "Overseas – Central & Northern Europe (all)", ID: LocationTypes.OverseasCentralAndNorthernEuropeAll },
	{ Name: "Overseas – Central & Northern Europe - Denmark", ID: LocationTypes.OverseasCentralAndNorthernEuropeDenmark },
	{ Name: "Overseas – Central & Northern Europe - France", ID: LocationTypes.OverseasCentralAndNorthernEuropeFrance },
	{ Name: "Overseas – Central & Northern Europe Germany", ID: LocationTypes.OverseasCentralAndNorthernEuropeGermany },
	{ Name: "Overseas – Central & Northern Europe - Netherlands", ID: LocationTypes.OverseasCentralAndNorthernEuropeNetherlands },
	{ Name: "Overseas – Eastern Europe (all)", ID: LocationTypes.OverseasEasternEuropeAll },
	{ Name: "Overseas – Eastern Europe - Czech Republic", ID: LocationTypes.OverseasEasternEuropeCzechRepublic },
	{ Name: "Overseas – Eastern Europe - Poland", ID: LocationTypes.OverseasEasternEuropePoland },
	{ Name: "Overseas – Middle East (all)", ID: LocationTypes.OverseasMiddleEastAll },
	{ Name: "Overseas – Middle East - Oman", ID: LocationTypes.OverseasMiddleEastOman },
	{ Name: "Overseas – Middle East - Qatar", ID: LocationTypes.OverseasMiddleEastQatar },
	{ Name: "Overseas – Middle East - Saudi Arabia", ID: LocationTypes.OverseasMiddleEastSaudiArabia },
	{ Name: "Overseas – Middle East - United Arab Emirates", ID: LocationTypes.OverseasMiddleEastUnitedArabEmirates },
	{ Name: "Overseas – South East Asia (all)", ID: LocationTypes.OverseasSouthEastAsiaAll },
	{ Name: "Overseas – South East Asia - Singapore", ID: LocationTypes.OverseasSouthEastAsiaSingapore },
	{ Name: "Overseas – South East Asia - Thailand", ID: LocationTypes.OverseasSouthEastAsiaThailand },
	{ Name: "Overseas – Southern Europe (all)", ID: LocationTypes.OverseasSouthernEuropeAll },
	{ Name: "Overseas – Southern Europe - Portugal", ID: LocationTypes.OverseasSouthernEuropePortugal },
	{ Name: "Overseas – Southern Europe - Italy", ID: LocationTypes.OverseasSouthernEuropeItaly },
	{ Name: "Overseas – Southern Europe - Spain", ID: LocationTypes.OverseasSouthernEuropeSpain },
	{ Name: "Overseas- All Locations", ID: LocationTypes.OverseasAllLocations },
	{ Name: "Overseas – Ghana", ID: LocationTypes.OverseasGhana },
	{ Name: "Overseas- NOT LOOKING OVERSEAS", ID: LocationTypes.OverseasNotLookingOverseas },
];

export const subsidiarySubjectItems = [
	{ Name: "Accounting", ID: SubjectTypes.Accounting },
	{ Name: "Arabic", ID: SubjectTypes.Arabic },
	{ Name: "Art & Design", ID: SubjectTypes.ArtAndDesign },
	{ Name: "Biology", ID: SubjectTypes.Biology },
	{ Name: "Business Studies", ID: SubjectTypes.BusinessStudies },
	{ Name: "Catering", ID: SubjectTypes.Catering },
	{ Name: "Certificate of Sponsorship", ID: SubjectTypes.CertificateOfSponsorship },
	{ Name: "Chemistry", ID: SubjectTypes.Chemistry },
	{ Name: "Citizenship", ID: SubjectTypes.Citizenship },
	{ Name: "Classics", ID: SubjectTypes.Classics },
	{ Name: "Computing", ID: SubjectTypes.Computing },
	{ Name: "Cover", ID: SubjectTypes.Cover },
	{ Name: "Dance", ID: SubjectTypes.Dance },
	{ Name: "Design & Technology", ID: SubjectTypes.DesignAndTechnology },
	{ Name: "Drama", ID: SubjectTypes.Drama },
	{ Name: "EAL", ID: SubjectTypes.EAL },
	{ Name: "Economics", ID: SubjectTypes.Economics },
	{ Name: "Engineering", ID: SubjectTypes.Engineering },
	{ Name: "English", ID: SubjectTypes.English },
	{ Name: "Ethics & Philosophy", ID: SubjectTypes.EthicsAndPhilosophy },
	{ Name: "Film Studies", ID: SubjectTypes.FilmStudies },
	{ Name: "Fine Art", ID: SubjectTypes.FineArt },
	{ Name: "Food Technology", ID: SubjectTypes.FoodTechnology },
	{ Name: "French", ID: SubjectTypes.French },
	{ Name: "Further Maths", ID: SubjectTypes.FurtherMaths },
	{ Name: "General Supply Primary", ID: SubjectTypes.GeneralSupplyPrimary },
	{ Name: "General Supply Secondary", ID: SubjectTypes.GeneralSupplySecondary },
	{ Name: "Geography", ID: SubjectTypes.Geography },
	{ Name: "German", ID: SubjectTypes.German },
	{ Name: "Graphics", ID: SubjectTypes.Graphics },
	{ Name: "Greek", ID: SubjectTypes.Greek },
	{ Name: "Health & Social Care", ID: SubjectTypes.HealthAndSocialCare },
	{ Name: "History", ID: SubjectTypes.History },
	{ Name: "Hospitality", ID: SubjectTypes.Hospitality },
	{ Name: "Humanities", ID: SubjectTypes.Humanities },
	{ Name: "ICT", ID: SubjectTypes.ICT },
	{ Name: "Italian", ID: SubjectTypes.Italian },
	{ Name: "Latin", ID: SubjectTypes.Latin },
	{ Name: "Law", ID: SubjectTypes.Law },
	{ Name: "Level", ID: SubjectTypes.Level },
	{ Name: "Mandarin", ID: SubjectTypes.Mandarin },
	{ Name: "Maths", ID: SubjectTypes.Maths },
	{ Name: "Media Studies", ID: SubjectTypes.MediaStudies },
	{ Name: "MFL", ID: SubjectTypes.MFL },
	{ Name: "Music", ID: SubjectTypes.Music },
	{ Name: "Music Technology", ID: SubjectTypes.MusicTechnology },
	{ Name: "Nursery - EYFS", ID: SubjectTypes.NurseryEYFS },
	{ Name: "Other", ID: SubjectTypes.Other },
	{ Name: "Overseas", ID: SubjectTypes.Overseas },
	{ Name: "PE Female", ID: SubjectTypes.PEFemale },
	{ Name: "PE Male", ID: SubjectTypes.PEMale },
	{ Name: "Performing Arts", ID: SubjectTypes.PerformingArts },
	{ Name: "Photography", ID: SubjectTypes.Photography },
	{ Name: "PHSE", ID: SubjectTypes.PHSE },
	{ Name: "Physical Education", ID: SubjectTypes.PhysicalEducation },
	{ Name: "Physics", ID: SubjectTypes.Physics },
	{ Name: "Politics", ID: SubjectTypes.Politics },
	{ Name: "Primary EYFS", ID: SubjectTypes.PrimaryEYFS },
	{ Name: "Primary KS1", ID: SubjectTypes.PrimaryKS1 },
	{ Name: "Primary KS2", ID: SubjectTypes.PrimaryKS2 },
	{ Name: "Product Design", ID: SubjectTypes.ProductDesign },
	{ Name: "PSHE", ID: SubjectTypes.PSHE },
	{ Name: "Psychology", ID: SubjectTypes.Psychology },
	{ Name: "Rating", ID: SubjectTypes.Rating },
	{ Name: "Religious Education", ID: SubjectTypes.ReligiousEducation },
	{ Name: "Resistant Materials", ID: SubjectTypes.ResistantMaterials },
	{ Name: "Science", ID: SubjectTypes.Science },
	{ Name: "SEN", ID: SubjectTypes.SEN },
	{ Name: "SEN ADHD", ID: SubjectTypes.SENADHD },
	{ Name: "SEN ASD", ID: SubjectTypes.SENASD },
	{ Name: "SEN BESD", ID: SubjectTypes.SENBESD },
	{ Name: "SEN Dyslexia", ID: SubjectTypes.SENDyslexia },
	{ Name: "SEN EBD", ID: SubjectTypes.SENEBD },
	{ Name: "SEN EYFS", ID: SubjectTypes.SENEYFS },
	{ Name: "SEN HI", ID: SubjectTypes.SENHI },
	{ Name: "SEN Makaton", ID: SubjectTypes.SENMakaton },
	{ Name: "SEN MAPA", ID: SubjectTypes.SENMAPA },
	{ Name: "SEN MLD", ID: SubjectTypes.SENMLD },
	{ Name: "SEN Nursery", ID: SubjectTypes.SENNursery },
	{ Name: "SEN PMLD", ID: SubjectTypes.SENPMLD },
	{ Name: "SEN Primary KS1", ID: SubjectTypes.SENPrimaryKS1 },
	{ Name: "SEN Primary KS2", ID: SubjectTypes.SENPrimaryKS2 },
	{ Name: "SEN SEBD", ID: SubjectTypes.SENSEBD },
	{ Name: "SEN SEMH", ID: SubjectTypes.SENSEMH },
	{ Name: "SEN SLCN", ID: SubjectTypes.SENSLCN },
	{ Name: "SEN SLD", ID: SubjectTypes.SENSLD },
	{ Name: "SEN SPLD", ID: SubjectTypes.SENSPLD },
	{ Name: "SEN Team Teach", ID: SubjectTypes.SENTeamTeach },
	{ Name: "SEN VI", ID: SubjectTypes.SENVI },
	{ Name: "SENco", ID: SubjectTypes.SENco },
	{ Name: "Sociology", ID: SubjectTypes.Sociology },
	{ Name: "Spanish", ID: SubjectTypes.Spanish },
	{ Name: "Special Needs", ID: SubjectTypes.SpecialNeeds },
	{ Name: "Statistics", ID: SubjectTypes.Statistics },
	{ Name: "Subjects", ID: SubjectTypes.Subjects },
	{ Name: "Textiles", ID: SubjectTypes.Textiles },
	{ Name: "Travel & Tourism", ID: SubjectTypes.TravelAndTourism },
	{ Name: "SEN OCD", ID: SubjectTypes.SENOCD },
	{ Name: "SEN Therapy", ID: SubjectTypes.SENTherapy },
	{ Name: "SEN Personal Care", ID: SubjectTypes.SENPersonalCare },
	{ Name: "SEN BSL", ID: SubjectTypes.SENBSL },
	{ Name: "Primary YR", ID: SubjectTypes.PrimaryYR },
	{ Name: "Primary YR1", ID: SubjectTypes.PrimaryYR1 },
	{ Name: "Primary YR2", ID: SubjectTypes.PrimaryYR2 },
	{ Name: "Primary YR3", ID: SubjectTypes.PrimaryYR3 },
	{ Name: "Primary YR4", ID: SubjectTypes.PrimaryYR4 },
	{ Name: "Primary YR5", ID: SubjectTypes.PrimaryYR5 },
	{ Name: "Primary YR6", ID: SubjectTypes.PrimaryYR6 },
	{ Name: "Primary Reception", ID: SubjectTypes.PrimaryReception },
	{ Name: "Support", ID: SubjectTypes.Support },
	{ Name: "z Internal Rec Con", ID: SubjectTypes.ZInternalRecCon },
	{ Name: "z Internal Compliance", ID: SubjectTypes.ZInternalCompliance },
	{ Name: "z Internal Manager", ID: SubjectTypes.ZInternalManager },
	{ Name: "Z Internal Director", ID: SubjectTypes.ZInternalDirector },
	{ Name: "Z Internal Recruiter", ID: SubjectTypes.ZInternalRecruiter },
	{ Name: "Z Internal Resourcer", ID: SubjectTypes.ZInternalResourcer },
	{ Name: "Computer Science", ID: SubjectTypes.ComputerScience },
	{ Name: "Criminology", ID: SubjectTypes.Criminology },
	{ Name: "Electronics", ID: SubjectTypes.Electronics },
	{ Name: "Functional Skills", ID: SubjectTypes.FunctionalSkills },
	{ Name: "Social Sciences", ID: SubjectTypes.SocialSciences },
	{ Name: "Social Studies", ID: SubjectTypes.SocialStudies },
	{ Name: "Final Year Student", ID: SubjectTypes.FinalYearStudent },
	{ Name: "DSL Support", ID: SubjectTypes.DSLSupport },
	{ Name: "Exams Officer", ID: SubjectTypes.ExamsOfficer },
	{ Name: "Art&Design Internal", ID: SubjectTypes.ArtDesignInternal },
	{ Name: "z Rec Con Temp", ID: SubjectTypes.ZRecConTemp },
	{ Name: "z Rec Con Perm", ID: SubjectTypes.ZRecConPerm },
	{ Name: "z iCan Rec Con", ID: SubjectTypes.ZICanRecCon },
	{ Name: "z iCan Aftercare", ID: SubjectTypes.ZICanAftercare },
	{ Name: "z resourcer", ID: SubjectTypes.ZResourcer },
];
export const TransportItems = [
	{ Name: TransportTypes.DrivesOwnVehicle, ID: TransportTypes.DrivesOwnVehicle },
	{ Name: TransportTypes.PublicTransport, ID: TransportTypes.PublicTransport },
	{ Name: TransportTypes.VehicleOther, ID: TransportTypes.VehicleOther },
	{ Name: TransportTypes.Bicycle, ID: TransportTypes.Bicycle },
	{ Name: TransportTypes.NoAccess, ID: TransportTypes.NoAccess },
	{ Name: TransportTypes.Other, ID: TransportTypes.Other },
];

export const OfstedRatingItems = [
	{ Name: "Outstanding", ID: RatingTypes.Outstanding },
	{ Name: "Good", ID: RatingTypes.Good },
	{ Name: "Requires Improvement", ID: RatingTypes.RequiresImprovement },
	{ Name: "Inadequate", ID: RatingTypes.Inadequate },
	{ Name: "NA - Independent", ID: RatingTypes.NAIndependent },
	{ Name: "NA - Academy", ID: RatingTypes.NAAcademy },
];

export const GovernanceItems = [
	{ Name: "Academy", ID: InstitutionTypes.Academy },
	{ Name: "Childrens Centre", ID: InstitutionTypes.ChildrensCentre },
	{ Name: "College", ID: InstitutionTypes.College },
	{ Name: "Independent", ID: InstitutionTypes.Independent },
	{ Name: "LEA", ID: InstitutionTypes.LEA },
	{ Name: "University", ID: InstitutionTypes.University },
];

export const ReferenceTypeItems = [
	{ Name: "Education", ID: ReferenceTypes.Education },
	{ Name: "Professional", ID: ReferenceTypes.Employment },
	{ Name: "Character (3+ years)", ID: ReferenceTypes.Character },
	{ Name: "Factual", ID: ReferenceTypes.Factual },
	{ Name: "Academic", ID: ReferenceTypes.Academic },
];

export const MedicalReferenceTypeItems = [
	{ Name: "Professional", ID: ReferenceTypes.Employment },
	{ Name: "Character (3+ years)", ID: ReferenceTypes.Character },
	{ Name: "Academic", ID: ReferenceTypes.Academic },
];

export const VidaReferenceTypeItems = [
	{ Name: "Education", ID: ReferenceTypes.Education },
	{ Name: "Professional", ID: ReferenceTypes.Employment },
	{ Name: "Character (3+ years)", ID: ReferenceTypes.Character },
	{ Name: "Volunteer", ID: ReferenceTypes.Volunteer },
];

export const AllReferenceTypeItems = [
	{ Name: "Education", ID: ReferenceTypes.Education },
	{ Name: "Professional", ID: ReferenceTypes.Employment },
	{ Name: "Character (3+ years)", ID: ReferenceTypes.Character },
	{ Name: "Factual", ID: ReferenceTypes.Factual },
	{ Name: "Academic", ID: ReferenceTypes.Academic },
	{ Name: "Volunteer", ID: ReferenceTypes.Volunteer },
];

export const TitleTypes = [
	{ Name: "Mr", ID: "Mr" },
	{ Name: "Mrs", ID: "Mrs" },
	{ Name: "Ms", ID: "Ms" },
	{ Name: "Miss", ID: "Miss" },
	{ Name: "Dr", ID: "Dr" },
	{ Name: "Mx", ID: "Mx" },
	{ Name: "Lady", ID: "Lady" },
	{ Name: "Lord", ID: "Lord" },
	{ Name: "Other", ID: "Other" },
];

export const RoleTypes = [
	{ Name: "Senior Leadership Positions", ID: "Senior Leadership Positions" },
	{ Name: "Teaching Roles", ID: "Teaching Roles" },
	{ Name: "School Support Positions", ID: "School Support Positions" },
];

export const PronounTypes = [
	{ Name: "She/Her/Hers", ID: "She/Her/Hers" },
	{ Name: "He/Him/His", ID: "He/Him/His" },
	{ Name: "They/Them/Theirs", ID: "They/Them/Theirs" },
	{ Name: "Ze/Hir/Hirs", ID: "Ze/Hir/Hirs" },
	{ Name: "Ze/Zir/Zirs", ID: "Ze/Zir/Zirs" },
	{ Name: "Other", ID: "Other" },
	{ Name: "Prefer not to say", ID: "Prefer not to say" },
];

export const ReferenceSubmissionTypeItems = [
	{ Name: "Education", ID: ReferenceSubmissionTypes.Education },
	{ Name: "Professional", ID: ReferenceSubmissionTypes.Professional },
	{ Name: "Character", ID: ReferenceSubmissionTypes.Character },
	{ Name: "Factual", ID: ReferenceSubmissionTypes.Factual },
	{ Name: "Academic", ID: ReferenceSubmissionTypes.Academic },
];

export const VidaReferenceSubmissionTypeItems = [
	{ Name: "Education", ID: ReferenceSubmissionTypes.Education },
	{ Name: "Professional", ID: ReferenceSubmissionTypes.Professional },
	{ Name: "Character", ID: ReferenceSubmissionTypes.Character },
	{ Name: "Volunteer", ID: ReferenceSubmissionTypes.Volunteer },
];

export const TimesheetStatusItems = [
	{ Name: "Awaiting Approval", ID: TimesheetStatus.Pending },
	{ Name: "Confirm Attendance", ID: TimesheetStatus.Approved },
	{ Name: "Query", ID: TimesheetStatus.Rejected },
];

export const TimesheetStatusListingItems = [
	{ Name: "Awaiting Approval", ID: TimesheetStatus.Pending },
	{ Name: "Confirmed Attendance", ID: TimesheetStatus.Approved },
	{ Name: "Queried", ID: TimesheetStatus.Rejected },
];

export const BookingStatusListingItems = [
	{ Name: "Active", ID: BookingStatus.Active },
	{ Name: "Cancelled", ID: BookingStatus.Cancelled },
];

export const TagOptions = [
	{ Name: "BD Call", ID: RemarksTags.BDCall },
	{ Name: "Candidate Call", ID: RemarksTags.CandidateCall },
	{ Name: "Client Call", ID: RemarksTags.ClientCall },
	{ Name: "Spec Call", ID: RemarksTags.SpecCall },
	{ Name: "Meeting", ID: RemarksTags.Meeting },
	{ Name: "Compliance", ID: RemarksTags.Compliance },
	{ Name: "Email", ID: RemarksTags.CandidateEmail },
	{ Name: "SMS", ID: RemarksTags.CandidateSms },
];

export const AllTagOptions = [
	{ Name: "BD Call", ID: RemarksTags.BDCall },
	{ Name: "Candidate Call", ID: RemarksTags.CandidateCall },
	{ Name: "Client Call", ID: RemarksTags.ClientCall },
	{ Name: "Spec Call", ID: RemarksTags.SpecCall },
	{ Name: "Meeting", ID: RemarksTags.Meeting },
	{ Name: "Compliance", ID: RemarksTags.Compliance },
	{ Name: "Email", ID: RemarksTags.CandidateEmail },
	{ Name: "SMS", ID: RemarksTags.CandidateSms },
	{ Name: "LM - EM- TXT", ID: RemarksTags.LMEMTxt },
	{ Name: "LM - EM- TXT - First Intro", ID: RemarksTags.LMEMTxtFirstIntro },
	{ Name: "LM - EM- TXT - Second Intro", ID: RemarksTags.LMEMTxtSecondIntro },
	{ Name: "LM - EM- TXT - Third Intro", ID: RemarksTags.LMEMTxtThirdIntro },
	{ Name: "LM - EM- TXT - Application", ID: RemarksTags.LMEMTxtApplication },
	{ Name: "LM - EM- TXT - Are you looking", ID: RemarksTags.LMEMTxtAreYouLooking },
	{ Name: "Voicemail", ID: RemarksTags.Voicemail },
	{ Name: "Sent Message", ID: RemarksTags.SentMessage },
	{ Name: "Recruiting Call - Success", ID: RemarksTags.RecruitingCallSuccess },
	{ Name: "LM/EM/TXT - Recruiting", ID: RemarksTags.LMEMTxtRecruiting },
	{ Name: "Recruiting Email / Message - Sent", ID: RemarksTags.RecruitingEmailMessageSent },
	{ Name: "Recruiting Email / Message - Success", ID: RemarksTags.RecruitingEmailMessageSuccess },
	{ Name: "Recruiting Mass Email", ID: RemarksTags.RecruitingMassEmail },
	{ Name: "Job Worked on Completely", ID: RemarksTags.JobWorkedOnCompletely },
	{ Name: "Pre-Vet / Perm Reg", ID: RemarksTags.PreVetPermReg },
	{ Name: "Pre-Vet / Perm Reg (with email)", ID: RemarksTags.PreVetPermRegWithEmail },
	{ Name: "Virtual Reg Booked", ID: RemarksTags.VirtualRegBooked },
	{ Name: "Virtual Reg Attended", ID: RemarksTags.VirtualRegAttended },
	{ Name: "Virtual Reg Attended (with email)", ID: RemarksTags.VirtualRegAttendedWithEmail },
	{ Name: "In-Person Registration Booked", ID: RemarksTags.InPersonRegistrationBooked },
	{ Name: "In-Person Registration Attended", ID: RemarksTags.InPersonRegistrationAttended },
	{ Name: "Availability LM - EM - TXT", ID: RemarksTags.AvailabilityLMEMTXT },
	{ Name: "Availability Email / Message - Success", ID: RemarksTags.AvailabilityEmailMessageSuccess },
	{ Name: "Availability Call - Success", ID: RemarksTags.AvailabilityCallSuccess },
	{ Name: "Candidate Call - Other", ID: RemarksTags.CandidateCallOther },
	{ Name: "Candidate Call - Aftercare - Success", ID: RemarksTags.CandidateCallAftercareSuccess },
	{ Name: "Candidate Call - Aftercare - Attempt", ID: RemarksTags.CandidateCallAftercareAttempt },
	{ Name: "Interview Feedback", ID: RemarksTags.InterviewFeedback },
	{ Name: "Interest in Teaching Overseas", ID: RemarksTags.InterestInTeachingOverseas },
	{ Name: "Solo Odro Request Sent", ID: RemarksTags.SoloOdroRequestSent },
	{ Name: "Solo Odro - Received", ID: RemarksTags.SoloOdroReceived },
	{ Name: "Solo Odro – Success - Feedback", ID: RemarksTags.SoloOdroSuccessFeedback },
	{ Name: "Solo Odro – Failed – Feedback", ID: RemarksTags.SoloOdroFailedFeedback },
	{ Name: "iCan Fully Registered - CAT A", ID: RemarksTags.iCanFullyRegisteredCATA },
	{ Name: "iCan - Fully Registered", ID: RemarksTags.iCanFullyRegistered },
	{ Name: "Other", ID: RemarksTags.Other },
	{ Name: "Compliance - Reference Chased - Call", ID: RemarksTags.ComplianceReferenceChaseCall },
	{ Name: "Compliance - Reference Chased - Email / Message", ID: RemarksTags.ComplianceReferenceChasedEmailMessage },
	{ Name: "Compliance Call - Success", ID: RemarksTags.ComplianceCallSuccess },
	{ Name: "Compliance Call - Attempted", ID: RemarksTags.ComplianceCallAttempted },
	{ Name: "Compliance Intro", ID: RemarksTags.ComplianceIntro },
	{ Name: "Candidate Sent to Compliance", ID: RemarksTags.CandidateSentToCompliance },
	{ Name: "Compliance Note", ID: RemarksTags.ComplianceNote },
	{ Name: "Compliance - In Person Interview Compliant", ID: RemarksTags.ComplianceInPersonInterviewCompliant },
	{ Name: "Compliance - Phone/Vid Interview Compliant", ID: RemarksTags.CompliancePhoneVidInterviewCompliant },
	{ Name: "Compliance - Temp Cleared - First Check", ID: RemarksTags.ComplianceTempClearedFirstCheck },
	{ Name: "Compliance - Temp Cleared - Second Check", ID: RemarksTags.ComplianceTempClearedSecondCheck },
	{ Name: "Compliance - Direct Cleared", ID: RemarksTags.ComplianceDirectCleared },
	{ Name: "Compliance - Direct Cleared - Second Check", ID: RemarksTags.ComplianceDirectClearedSecondCheck },
	{ Name: "Compliance - Final Checks Fail", ID: RemarksTags.ComplianceFinalChecksFail },
	{ Name: "Welcome to Dunbar Call", ID: RemarksTags.WelcomeToDunbarCall },
	{ Name: "BD Call – Attempt", ID: RemarksTags.BDCallAttempt },
	{ Name: "BD Call – Success", ID: RemarksTags.BDCallSuccess },
	{ Name: "BD Email / Message – Sent", ID: RemarksTags.BDEmailMessageSent },
	{ Name: "BD Email / Message – Success", ID: RemarksTags.BDEmailMessageSuccess },
	{ Name: "Client Call - Other", ID: RemarksTags.ClientCallOther },
	{ Name: "CATs Call - Attempt", ID: RemarksTags.CATsCallAttempt },
	{ Name: "CATs Call – Success", ID: RemarksTags.CATsCallSuccess },
	{ Name: "BD Mass Email", ID: RemarksTags.BDMassEmail },
	{ Name: "Client Terms Signed", ID: RemarksTags.ClientTermsSigned },
	{ Name: "Preferred Partner + Agreed Verbally", ID: RemarksTags.PreferredPartnerAgreedVerbally },
	{ Name: "Preferred Partner + Signed", ID: RemarksTags.PreferredPartnerSigned },
	{ Name: "Client Visit Booked", ID: RemarksTags.ClientVisitBooked },
	{ Name: "Client Visit Attended", ID: RemarksTags.ClientVisitAttended },
	{ Name: "Client Drop Attended", ID: RemarksTags.ClientDropAttended },
	{ Name: "Client Visit (Drop to Visit) Attended", ID: RemarksTags.ClientVisitDropToVisitAttended },
	{ Name: "Client Visit (Virtual) Booked", ID: RemarksTags.ClientVisitVirtualBooked },
	{ Name: "Client Visit (Virtual) Attended", ID: RemarksTags.ClientVisitVirtualAttended },
	{ Name: "Interview Feedback", ID: RemarksTags.InterviewFeedback },
	{ Name: "Finance - Invoice Sent", ID: RemarksTags.FinanceInvoiceSent },
];

export const CandidateTagOptions = [
	{ Name: "BD Call", ID: RemarksTags.BDCall },
	{ Name: "Candidate Call", ID: RemarksTags.CandidateCall },
	{ Name: "Spec Call", ID: RemarksTags.SpecCall },
	{ Name: "Meeting", ID: RemarksTags.Meeting },
	{ Name: "Compliance", ID: RemarksTags.Compliance },
	{ Name: "Email", ID: RemarksTags.CandidateEmail },
	{ Name: "SMS", ID: RemarksTags.CandidateSms },
];

export const CustomCandidateTagOptions = [
	{ Name: "BD Call", ID: RemarksTags.BDCall },
	{ Name: "Candidate Call", ID: RemarksTags.CandidateCall },
	{ Name: "Meeting", ID: RemarksTags.Meeting },
	{ Name: "Compliance", ID: RemarksTags.Compliance },
	{ Name: "Email", ID: RemarksTags.CandidateEmail },
	{ Name: "SMS", ID: RemarksTags.CandidateSms },
	{ Name: "LM - EM- TXT", ID: RemarksTags.LMEMTxt },
	{ Name: "LM - EM- TXT - First Intro", ID: RemarksTags.LMEMTxtFirstIntro },
	{ Name: "LM - EM- TXT - Second Intro", ID: RemarksTags.LMEMTxtSecondIntro },
	{ Name: "LM - EM- TXT - Third Intro", ID: RemarksTags.LMEMTxtThirdIntro },
	{ Name: "LM - EM- TXT - Application", ID: RemarksTags.LMEMTxtApplication },
	{ Name: "LM - EM- TXT - Are you looking", ID: RemarksTags.LMEMTxtAreYouLooking },
	{ Name: "Voicemail", ID: RemarksTags.Voicemail },
	{ Name: "Sent Message", ID: RemarksTags.SentMessage },
	{ Name: "Recruiting Call - Success", ID: RemarksTags.RecruitingCallSuccess },
	{ Name: "LM/EM/TXT - Recruiting", ID: RemarksTags.LMEMTxtRecruiting },
	{ Name: "Recruiting Email / Message - Sent", ID: RemarksTags.RecruitingEmailMessageSent },
	{ Name: "Recruiting Email / Message - Success", ID: RemarksTags.RecruitingEmailMessageSuccess },
	{ Name: "Recruiting Mass Email", ID: RemarksTags.RecruitingMassEmail },
	{ Name: "Job Worked on Completely", ID: RemarksTags.JobWorkedOnCompletely },
	{ Name: "Pre-Vet / Perm Reg", ID: RemarksTags.PreVetPermReg },
	{ Name: "Pre-Vet / Perm Reg (with email)", ID: RemarksTags.PreVetPermRegWithEmail },
	{ Name: "Virtual Reg Booked", ID: RemarksTags.VirtualRegBooked },
	{ Name: "Virtual Reg Attended", ID: RemarksTags.VirtualRegAttended },
	{ Name: "Virtual Reg Attended (with email)", ID: RemarksTags.VirtualRegAttendedWithEmail },
	{ Name: "In-Person Registration Booked", ID: RemarksTags.InPersonRegistrationBooked },
	{ Name: "In-Person Registration Attended", ID: RemarksTags.InPersonRegistrationAttended },
	{ Name: "Availability LM - EM - TXT", ID: RemarksTags.AvailabilityLMEMTXT },
	{ Name: "Availability Email / Message - Success", ID: RemarksTags.AvailabilityEmailMessageSuccess },
	{ Name: "Availability Call - Success", ID: RemarksTags.AvailabilityCallSuccess },
	{ Name: "Candidate Call - Other", ID: RemarksTags.CandidateCallOther },
	{ Name: "Candidate Call - Aftercare - Success", ID: RemarksTags.CandidateCallAftercareSuccess },
	{ Name: "Candidate Call - Aftercare - Attempt", ID: RemarksTags.CandidateCallAftercareAttempt },
	{ Name: "Interview Feedback", ID: RemarksTags.InterviewFeedback },
	{ Name: "Interest in Teaching Overseas", ID: RemarksTags.InterestInTeachingOverseas },
	{ Name: "Solo Odro Request Sent", ID: RemarksTags.SoloOdroRequestSent },
	{ Name: "Solo Odro - Received", ID: RemarksTags.SoloOdroReceived },
	{ Name: "Solo Odro – Success - Feedback", ID: RemarksTags.SoloOdroSuccessFeedback },
	{ Name: "Solo Odro – Failed – Feedback", ID: RemarksTags.SoloOdroFailedFeedback },
	{ Name: "iCan Fully Registered - CAT A", ID: RemarksTags.iCanFullyRegisteredCATA },
	{ Name: "iCan - Fully Registered", ID: RemarksTags.iCanFullyRegistered },
	{ Name: "Other", ID: RemarksTags.Other },
	{ Name: "Compliance - Reference Chased - Call", ID: RemarksTags.ComplianceReferenceChaseCall },
	{ Name: "Compliance - Reference Chased - Email / Message", ID: RemarksTags.ComplianceReferenceChasedEmailMessage },
	{ Name: "Compliance Call - Success", ID: RemarksTags.ComplianceCallSuccess },
	{ Name: "Compliance Call - Attempted", ID: RemarksTags.ComplianceCallAttempted },
	{ Name: "Compliance Intro", ID: RemarksTags.ComplianceIntro },
	{ Name: "Candidate Sent to Compliance", ID: RemarksTags.CandidateSentToCompliance },
	{ Name: "Compliance Note", ID: RemarksTags.ComplianceNote },
	{ Name: "Compliance - In Person Interview Compliant", ID: RemarksTags.ComplianceInPersonInterviewCompliant },
	{ Name: "Compliance - Phone/Vid Interview Compliant", ID: RemarksTags.CompliancePhoneVidInterviewCompliant },
	{ Name: "Compliance - Temp Cleared - First Check", ID: RemarksTags.ComplianceTempClearedFirstCheck },
	{ Name: "Compliance - Temp Cleared - Second Check", ID: RemarksTags.ComplianceTempClearedSecondCheck },
	{ Name: "Compliance - Direct Cleared", ID: RemarksTags.ComplianceDirectCleared },
	{ Name: "Compliance - Direct Cleared - Second Check", ID: RemarksTags.ComplianceDirectClearedSecondCheck },
	{ Name: "Compliance - Final Checks Fail", ID: RemarksTags.ComplianceFinalChecksFail },
	{ Name: "Welcome to Dunbar Call", ID: RemarksTags.WelcomeToDunbarCall },
];

export const ClientTagOptions = [
	{ Name: "BD Call", ID: RemarksTags.BDCall },
	{ Name: "Client Call", ID: RemarksTags.ClientCall },
	{ Name: "Spec Call", ID: RemarksTags.SpecCall },
	{ Name: "Meeting", ID: RemarksTags.Meeting },
	{ Name: "Email", ID: RemarksTags.CandidateEmail },
];

export const CustomClientTagOptions = [
	{ Name: "BD Call", ID: RemarksTags.BDCall },
	{ Name: "Client Call", ID: RemarksTags.ClientCall },
	{ Name: "Meeting", ID: RemarksTags.Meeting },
	{ Name: "Email", ID: RemarksTags.CandidateEmail },
	{ Name: "BD Call – Attempt", ID: RemarksTags.BDCallAttempt },
	{ Name: "BD Call – Success", ID: RemarksTags.BDCallSuccess },
	{ Name: "BD Email / Message – Sent", ID: RemarksTags.BDEmailMessageSent },
	{ Name: "BD Email / Message – Success", ID: RemarksTags.BDEmailMessageSuccess },
	{ Name: "Client Call - Other", ID: RemarksTags.ClientCallOther },
	{ Name: "CATs Call - Attempt", ID: RemarksTags.CATsCallAttempt },
	{ Name: "CATs Call – Success", ID: RemarksTags.CATsCallSuccess },
	{ Name: "BD Mass Email", ID: RemarksTags.BDMassEmail },
	{ Name: "Client Terms Signed", ID: RemarksTags.ClientTermsSigned },
	{ Name: "Preferred Partner + Agreed Verbally", ID: RemarksTags.PreferredPartnerAgreedVerbally },
	{ Name: "Preferred Partner + Signed", ID: RemarksTags.PreferredPartnerSigned },
	{ Name: "Client Visit Booked", ID: RemarksTags.ClientVisitBooked },
	{ Name: "Client Visit Attended", ID: RemarksTags.ClientVisitAttended },
	{ Name: "Client Drop Attended", ID: RemarksTags.ClientDropAttended },
	{ Name: "Client Visit (Drop to Visit) Attended", ID: RemarksTags.ClientVisitDropToVisitAttended },
	{ Name: "Client Visit (Virtual) Booked", ID: RemarksTags.ClientVisitVirtualBooked },
	{ Name: "Client Visit (Virtual) Attended", ID: RemarksTags.ClientVisitVirtualAttended },
	{ Name: "Interview Feedback", ID: RemarksTags.InterviewFeedback },
	{ Name: "Finance - Invoice Sent", ID: RemarksTags.FinanceInvoiceSent },
];

export const BookingTypeFilterItems = [
	{ Name: "Standard", ID: JSON.stringify([BookingType.Standard]) },
	{
		Name: "Interview / Trial",
		ID: JSON.stringify([BookingType.Interview, BookingType.Trial]),
	},
	{ Name: "Perm", ID: JSON.stringify([BookingType.Perm]) },
	{ Name: "Tuition", ID: JSON.stringify([BookingType.Tutor]) },
];

export const BookingTypeItems = [
	{ Name: "Standard", ID: BookingType.Standard },
	{ Name: "Interview", ID: BookingType.Interview },
	{ Name: "Trial", ID: BookingType.Trial },
	{ Name: "Perm", ID: BookingType.Perm },
	{ Name: "Tuition", ID: BookingType.Tutor },
];

export const BookingTypeStandrdOnlyFilterItems = [
	{ Name: "Standard", ID: JSON.stringify([BookingType.Standard]) },
	{ Name: "Tuition", ID: JSON.stringify([BookingType.Tutor]) },
];

export const BookingTypeStandrdOnlyItems = [
	{ Name: "Standard", ID: BookingType.Standard },
	{ Name: "Tuition", ID: BookingType.Tutor },
];

export const BookingSubTypeItems = [
	{ Name: "Standard", ID: BookingSubType.TutorStandard },
	{ Name: "Mentor", ID: BookingSubType.TutorMentor },
	{ Name: "Basic", ID: BookingSubType.TutorBasic },
];

export const BookingDayStatusItems = [
	{ Name: "Active", ID: BookingDayStatus.Active },
	{ Name: "Sickness", ID: BookingDayStatus.Sickness },
	{ Name: "No Show", ID: BookingDayStatus.NoShow },
	{ Name: "Client Cancelled", ID: BookingDayStatus.ClientCancelled },
	{ Name: "Act Of God", ID: BookingDayStatus.ActOfGod },
	{ Name: "Personal Appt", ID: BookingDayStatus.PersonalAppt },
];

export const SalaryPayTypeItems = [
	{ Name: "Annual", ID: SalaryPay.Annual },
	{ Name: "Daily", ID: SalaryPay.Daily },
];

export const ArchiveReasonItems = [
	{ Name: "Vacancy filled by another agency", ID: "Vacancy filled by another agency" },
	{ Name: "No suitable candidate", ID: "No suitable candidate" },
	{ Name: "Successfully filled (actioned outside of vacancy manager)", ID: "Successfully filled (actioned outside of vacancy manager)" },
	{ Name: "School withdrew vacancy", ID: "School withdrew vacancy" },
	{ Name: "School Recruited directly", ID: "School Recruited directly" },
];

export const TimesheetQueryListingItems = [
	{
		Name: "Incorrect working days",
		ID: TimesheetQueryReasonType.IncorrectDays,
	},
	{
		Name: "Incorrect working hours",
		ID: TimesheetQueryReasonType.IncorrectHours,
	},
	{ Name: "Other", ID: TimesheetQueryReasonType.Other },
];

export const Grades = [
	{ Name: "Outstanding", ID: 5 },
	{ Name: "Good", ID: 4 },
	{ Name: "Average", ID: 3 },
	{ Name: "Poor", ID: 2 },
	{ Name: "N/A", ID: 1 },
];

export const AgreeDisagree = [
	{ Name: "Agree", ID: AgreeDisagreeEnum.Agree },
	{ Name: "Disagree", ID: AgreeDisagreeEnum.Disagree },
	{ Name: "Not Applicable", ID: AgreeDisagreeEnum.NA },
];

export const Satisfied = [
	{ Name: "Satisfied", ID: AgreeDisagreeEnum.Agree },
	{ Name: "Dissatisfied", ID: AgreeDisagreeEnum.Disagree },
	{ Name: "Not Applicable", ID: AgreeDisagreeEnum.NA },
];

export const BookingTimeIntervals = [
	{ Name: "00:00", ID: "00:00" },
	{ Name: "00:15", ID: "00:15" },
	{ Name: "00:30", ID: "00:30" },
	{ Name: "00:45", ID: "00:45" },
	{ Name: "01:00", ID: "01:00" },
	{ Name: "01:15", ID: "01:15" },
	{ Name: "01:30", ID: "01:30" },
	{ Name: "01:45", ID: "01:45" },
	{ Name: "02:00", ID: "02:00" },
	{ Name: "02:15", ID: "02:15" },
	{ Name: "02:30", ID: "02:30" },
	{ Name: "02:45", ID: "02:45" },
	{ Name: "03:00", ID: "03:00" },
	{ Name: "03:15", ID: "03:15" },
	{ Name: "03:30", ID: "03:30" },
	{ Name: "03:45", ID: "03:45" },
	{ Name: "04:00", ID: "04:00" },
	{ Name: "04:15", ID: "04:15" },
	{ Name: "04:30", ID: "04:30" },
	{ Name: "04:45", ID: "04:45" },
	{ Name: "05:00", ID: "05:00" },
	{ Name: "05:15", ID: "05:15" },
	{ Name: "05:30", ID: "05:30" },
	{ Name: "05:45", ID: "05:45" },
	{ Name: "06:00", ID: "06:00" },
	{ Name: "06:15", ID: "06:15" },
	{ Name: "06:30", ID: "06:30" },
	{ Name: "06:45", ID: "06:45" },
	{ Name: "07:00", ID: "07:00" },
	{ Name: "07:15", ID: "07:15" },
	{ Name: "07:30", ID: "07:30" },
	{ Name: "07:45", ID: "07:45" },
	{ Name: "08:00", ID: "08:00" },
	{ Name: "08:15", ID: "08:15" },
	{ Name: "08:30", ID: "08:30" },
	{ Name: "08:45", ID: "08:45" },
	{ Name: "09:00", ID: "09:00" },
	{ Name: "09:15", ID: "09:15" },
	{ Name: "09:30", ID: "09:30" },
	{ Name: "09:45", ID: "09:45" },
	{ Name: "10:00", ID: "10:00" },
	{ Name: "10:15", ID: "10:15" },
	{ Name: "10:30", ID: "10:30" },
	{ Name: "10:45", ID: "10:45" },
	{ Name: "11:00", ID: "11:00" },
	{ Name: "11:15", ID: "11:15" },
	{ Name: "11:30", ID: "11:30" },
	{ Name: "11:45", ID: "11:45" },
	{ Name: "12:00", ID: "12:00" },
	{ Name: "12:15", ID: "12:15" },
	{ Name: "12:30", ID: "12:30" },
	{ Name: "12:45", ID: "12:45" },
	{ Name: "13:00", ID: "13:00" },
	{ Name: "13:15", ID: "13:15" },
	{ Name: "13:30", ID: "13:30" },
	{ Name: "13:45", ID: "13:45" },
	{ Name: "14:00", ID: "14:00" },
	{ Name: "14:15", ID: "14:15" },
	{ Name: "14:30", ID: "14:30" },
	{ Name: "14:45", ID: "14:45" },
	{ Name: "15:00", ID: "15:00" },
	{ Name: "15:15", ID: "15:15" },
	{ Name: "15:30", ID: "15:30" },
	{ Name: "15:45", ID: "15:45" },
	{ Name: "16:00", ID: "16:00" },
	{ Name: "16:15", ID: "16:15" },
	{ Name: "16:30", ID: "16:30" },
	{ Name: "16:45", ID: "16:45" },
	{ Name: "17:00", ID: "17:00" },
	{ Name: "17:15", ID: "17:15" },
	{ Name: "17:30", ID: "17:30" },
	{ Name: "17:45", ID: "17:45" },
	{ Name: "18:00", ID: "18:00" },
	{ Name: "18:15", ID: "18:15" },
	{ Name: "18:30", ID: "18:30" },
	{ Name: "18:45", ID: "18:45" },
	{ Name: "19:00", ID: "19:00" },
	{ Name: "19:15", ID: "19:15" },
	{ Name: "19:30", ID: "19:30" },
	{ Name: "19:45", ID: "19:45" },
	{ Name: "20:00", ID: "20:00" },
	{ Name: "20:15", ID: "20:15" },
	{ Name: "20:30", ID: "20:30" },
	{ Name: "20:45", ID: "20:45" },
	{ Name: "21:00", ID: "21:00" },
	{ Name: "21:15", ID: "21:15" },
	{ Name: "21:30", ID: "21:30" },
	{ Name: "21:45", ID: "21:45" },
	{ Name: "22:00", ID: "22:00" },
	{ Name: "22:15", ID: "22:15" },
	{ Name: "22:30", ID: "22:30" },
	{ Name: "22:45", ID: "22:45" },
	{ Name: "23:00", ID: "23:00" },
	{ Name: "23:15", ID: "23:15" },
	{ Name: "23:30", ID: "23:30" },
	{ Name: "23:45", ID: "23:45" },
];

export const PermissionOptionsUsers = [
	{ ID: 100, Name: "Can Manage Users" },
	{ ID: 101, Name: "Can Add Users" },
	{ ID: 102, Name: "Can Edit Users" },
	{ ID: 103, Name: "Can Delete Users" },
];

export const PermissionOptionsClients = [
	{
		ID: 200,
		Name: "Can Access Clients Module",
		Desc: " ",
	},
	{
		ID: 201,
		Name: "Can Add Clients",
		Desc: " ",
		Requires: [201],
	},
	{
		ID: 202,
		Name: "Can Edit Clients",
		Desc: " ",
		Requires: [201],
	},
	{
		ID: 203,
		Name: "Can Delete/ Archive Clients",
		Desc: " ",
		Requires: [201],
	},
	{
		ID: 205,
		Name: "Can Export Client File",
		Desc: "Can export the client file, including finance contact information.",
		Requires: [201],
	},
	{
		ID: 206,
		Name: "Can Export Clients",
		Desc: "Can export all client data.",
		Requires: [201],
	},
	{
		ID: 1010,
		Name: "Can View All Clients",
		Desc: "Can see and access all clients, this will override any allocation clients the user has. If left unselected an Edun user will only be able to access clients they have been linked to below.",
		Requires: [201],
	},
	{
		ID: 4000,
		Name: "Can Add Bookings For All Clients",
		Desc: "Can add bookings for clients that may fall outside of any linked clients for the user. This permission is not required if the user has the permission Can View All Clients.",
		Requires: [201],
	},
];

export const PermissionOptionsComplianceManager = [
	{
		ID: 307,
		Name: "Can Manage Candidate Status",
		Desc: "Allows the user to change the candidate status, regardless of assignee status or from the compliance dashboard.",
	},
	{
		ID: 312,
		Name: "Can Manage Assigned Candidate Status",
		Desc: "Allows the user to change the candidate status of their own assigned candidates. Does not apply to the compliance dashboard. This permission is not required if the user has the permission Can Manage Candidate Status.",
	},
	{
		ID: 309,
		Name: "Can Access Compliance Dashboard",
		Desc: "Provides the user read only access to the candidate compliance dashboard.",
	},
	{
		ID: 308,
		Name: "Can Manage All Candidate Compliance",
		Desc: "Allows the user to manage candidate records without being the designated assignee from the compliance dashboard and candidate record.",
	},
	{
		ID: 6000,
		Name: "Allow ID Verification",
		Desc: "Can use our Beta candidate verification tool to verify candidate likeness and identity document information.",
	},
	{
		ID: 310,
		Name: "Only View Assigned Pending",
		Desc: "Prevents the user from viewing and managing any pending candidate record that is not assigned to them.",
	},
	{ ID: 313, Name: "Cannot Move to Approved", Desc: "Prevents the user from updating candidate to approved, regardless of other permissions" },
	{ ID: 311, Name: "Cannot Move Out of Archived", Desc: "Prevents the user from updating archived candidates to approved or pending, regardless of other permissions." },
];

export const PermissionOptionsComplianceManagerWithDocumentApproval = [
	{
		ID: 314,
		Name: "Can Approve Documents",
		Desc: "Can approve/ decline candidate documents",
	},
];

export const PermissionOptionsComplianceManagerWithEditKCSIe = [
	{
		ID: 316,
		Name: "Can Edit Canddidate KCSIE",
		Desc: "Can amend onboarded candidates KCSIE information.",
	},
];

export const PermissionOptionsTimesheets = [
	{
		ID: 500,
		Name: "Can Access Timesheets Module",
		Desc: " ",
	},
	{
		ID: 501,
		Name: "Can Add Timesheets",
		Desc: "Can create and send timesheets.",
	},
	{
		ID: 502,
		Name: "Can Edit Timesheets",
		Desc: "Can edit and manage existing timesheets.",
	},
	{
		ID: 503,
		Name: "Can Delete Timesheets",
		Desc: " ",
	},
	{
		ID: 504,
		Name: "Can Edit Timesheet Post Invoice",
		Desc: "Can edit and manage timesheets that have already had an invoice raised against.",
	},
];

export const PermissionOptionsCandidates = [
	{
		ID: 300,
		Name: "Can Access Candidates Module",
		Desc: " ",
	},
	{
		ID: 301,
		Name: "Can Add Candidates",
		Desc: "Can create candidates.",
	},
	{
		ID: 302,
		Name: "Can Edit Candidates",
		Desc: "Can edit and manage existing candidates.",
	},
	{
		ID: 305,
		Name: "Can Anonymise Candidate Record",
		Desc: "Can anonymise all candidate details in line with GDPR regulations. The action is permanent and cannot be reversed.",
	},
	{
		ID: 303,
		Name: "Can Delete/ Archive Candidates",
		Desc: " ",
	},
	{
		ID: 304,
		Name: "Can Export Candidate Personnel File",
		Desc: "Can export the candidates personnel file, contains sensitive candidate information.",
	},
	{
		ID: 306,
		Name: "Can Export Candidates",
		Desc: "Can export all candidate tag.",
	},
	{
		ID: 315,
		Name: "Can View Booked Candidate List",
		Desc: "Can view the booked candidate tab, detailing candidates with a future booking.",
	},
];

export const PermissionOptionsBookings = [
	{
		ID: 400,
		Name: "Can Access Bookings Module",
		Desc: " ",
	},
	{
		ID: 401,
		Name: "Can Add Bookings",
		Desc: " ",
	},
	{
		ID: 402,
		Name: "Can Edit Bookings",
		Desc: " ",
	},
	{
		ID: 5000,
		Name: "Can Manage Tutor Bookings",
		Desc: "Can manage all aspects of Tutor bookings.",
	},
	{
		ID: 403,
		Name: "Can Delete Bookings",
		Desc: " ",
	},
	{
		ID: 404,
		Name: "Can Export Bookings",
		Desc: "Can export booking data.",
	},
	{
		ID: 20000,
		Name: "Can Export Detailed Booking Financials",
		Desc: "Can export detailed booking financial data.",
	},
	{
		ID: 405,
		Name: "Can Manage Booking Splits",
		Desc: "Allows a non-assigned user to amend booking split percentages on booking records.",
	},
	{
		ID: 406,
		Name: "Can Manage All Bookings",
		Desc: "Allows the user to manage booking records without being the designated assignee.",
	},
];

export const PermissionOptionsBookingsWithDataProviderSync = [
	{
		ID: 8000,
		Name: "Can Sync Data Back from Provider",
		Desc: "Provides the user with the ability to sync booking placement data back to bookings from a 3rd party provider, such as confirmed timesheets.",
	},
];

export const PermissionOptionsPlacements = [
	{
		ID: 1300,
		Name: "Can Access Vacancy Manager Module",
		Desc: " ",
	},
	{
		ID: 1301,
		Name: "Can Add Vacant Bookings",
		Desc: " ",
	},
	{
		ID: 1302,
		Name: "Can Edit Vacant Bookings",
		Desc: " .",
	},
	{
		ID: 1303,
		Name: "Can Delete Vacant Bookings",
		Desc: " ",
	},
	{
		ID: 1304,
		Name: "Can Manage All Vacancies",
		Desc: "Allows the user to manage vacant booking records without being the designated assignee.",
	},
];

export const PermissionOptionsAvailabilityManager = [
	{
		ID: 7000,
		Name: "Can Access and Manage Avaiilability Manager Module",
		Desc: " ",
	},
	{
		ID: 7001,
		Name: "Can Add Draft Bookings",
		Desc: " ",
	},
	{
		ID: 7002,
		Name: "Can Edit Draft Bookings",
		Desc: " ",
	},
];

export const PermissionOptionsAutomationsManager = [
	{
		ID: 9000,
		Name: "Can manage system automated tasks",
		Desc: " ",
	},
];

export const PermissionOptionsMarketing = [
	{
		ID: 600,
		Name: "Can Access Marketing Module",
		Desc: " ",
	},
	{
		ID: 601,
		Name: "Can Create Marketing Templates",
		Desc: "Can create a new business marketing template",
	},
	{
		ID: 602,
		Name: "Can Edit Marketing Templates",
		Desc: "Can edit and manage existing marketing templates.",
	},
	{
		ID: 603,
		Name: "Can Delete Marketing Templates",
		Desc: " ",
	},
	{
		ID: 604,
		Name: "Can Create Marketing Campaign",
		Desc: "Can create and send a new marketing campaign.",
	},
	{
		ID: 605,
		Name: "Can Send Marketing Campaign on Behalf of Selected User",
		Desc: "Can create and send a marketing campaign on behalf of another user.",
	},
	{
		ID: 606,
		Name: "Can View Marketing Suppressions",
		Desc: "Can view the marketing suppression lists for candidates and clients.",
	},
];

export const PermissionsAuditTrail = [
	{
		ID: 2000,
		Name: "Can View Event Logs",
		Desc: "Can view the event logs for bookings, vacancies, candidates, clients and timesheets.",
	},
];

export const PermissionOptionsCustomer = [
	{
		ID: 700,
		Name: "Can Manage Business",
		Desc: "Can access the business management.",
	},
	{
		ID: 701,
		Name: "Can Edit Business",
		Desc: "Can edit business details such as contact, financials, documents.",
	},
	{
		ID: 702,
		Name: "Can View Business Users",
		Desc: "Can access the business users.",
	},
	{
		ID: 703,
		Name: "Can Add Business Users",
		Desc: "Can add new business users.",
	},
	{
		ID: 704,
		Name: "Can Manage Business Users",
		Desc: "Can edit business users.",
	},
];

export const PermissionOptionsReporting = [
	{
		ID: 3000,
		Name: "Can Access Reporting Module",
	},
	{
		ID: 3008,
		Name: "Can View Entire Business Data",
		Desc: "Has elevated visibility of the entire business data. Without this the users will only be able to report on their own data.",
	},
	{
		ID: 3010,
		Name: "Can View Financial Reporting",
		Desc: "Will provide acccess to profit, commission, profit loss, total profit and total spend statistics.",
	},
	{
		ID: 3011,
		Name: "Can View Financial Loss Reporting",
		Desc: "Will provide access to profit loss statistics. Not required if user has Can View Financial Reporting.",
	},
	{
		ID: 3012,
		Name: "Can View Company User Target Reporting",
		Desc: "Will provide access to user target reporting.",
	},
	{
		ID: 3009,
		Name: "Can Export Reporting",
		Desc: "Can export the reporting data.",
	},
];

export const PermissionOptionsStats = [
	{
		ID: 1001,
		Name: "Can View the Stat Blocks",
		Desc: "Has visibility of potential booking, GP and commission statistics.",
	},
	{
		ID: 1002,
		Name: "Can View Days Out Stat",
		Desc: "Can view the days out booking statistic.",
	},
	{
		ID: 1003,
		Name: "Can View Bookings Stat",
		Desc: "Can view the booking total statistic.",
	},
	{
		ID: 1004,
		Name: "Can View Average Margin Stat",
		Desc: "Can view the average margin booking statistic.",
	},
	{
		ID: 1005,
		Name: "Can View Profit Margin Stat",
		Desc: "Can view the profit margin booking statistic.",
	},
];

export const PermissionOptionsStatsFiltering = [
	{
		ID: 1000,
		Name: "Can View Entire Business Stats / Exports",
		Desc: "Has elevated visibility of the entire business statistics and exports.",
	},
	{
		ID: 1050,
		Name: "Can View Their Teams Stats / Exports",
		Desc: "Has elevanted visibility of their assigned teams statistics and exports.",
	},
	{
		ID: 1007,
		Name: "Can View Statistics / Exports for other Business Users",
		Desc: "Has elevated visbility of other business users statistics, users filtered by business or branch first depending on other permissions.",
	},
	{
		ID: 1009,
		Name: "Default Stats / Exports to Individuals Data",
		Desc: "Statistics upon first viewing will default to the users assigned bookings. Exports where applicable will default to the users assigned records.",
	},
];

export const PermissionOptionsStatsCommission = [
	{
		ID: 1006,
		Name: "Can View Personal Commission Stat",
		Desc: "Can view the individuals commission booking statistic.",
	},
	{
		ID: 1008,
		Name: "Can View Company Commission Stat",
		Desc: "Has elevated visibility of commission at a business/ team level.",
	},
];

export const PermissionOptionsInvoice = [
	{
		ID: 1100,
		Name: "Can Access Integrations Module",
	},
	{
		ID: 1103,
		Name: "Can Manage Job Feeds",
		Desc: "Can manage job feed settings, api keys and more.",
	},
	{
		ID: 1101,
		Name: "Can Create New Invoices",
		Desc: "Can trigger a new invoice. (Requires Xero integration).",
	},
	{
		ID: 1102,
		Name: "Can View an Existing Invoice",
		Desc: "Can view an existing invoice after it has been created. (Requires Xero integration).",
	},
];

export const PermissionOptionsPayment = [
	{
		ID: 1200,
		Name: "Can Access Payment Module",
		Desc: " ",
	},
	{
		ID: 1201,
		Name: "Can Create New Payment Runs",
		Desc: " ",
	},
	{
		ID: 1202,
		Name: "Can View an Existing Payment Run",
		Desc: " ",
	},
	{
		ID: 1203,
		Name: "Can Delete an Existing Payment Run",
		Desc: " ",
	},
];

export const CurrencyCodes = [
	{ ID: "AED", Name: "AED" },
	{ ID: "AFN", Name: "AFN" },
	{ ID: "ALL", Name: "ALL" },
	{ ID: "AMD", Name: "AMD" },
	{ ID: "ANG", Name: "ANG" },
	{ ID: "AOA", Name: "AOA" },
	{ ID: "ARS", Name: "ARS" },
	{ ID: "AUD", Name: "AUD" },
	{ ID: "AWG", Name: "AWG" },
	{ ID: "AZN", Name: "AZN" },
	{ ID: "BAM", Name: "BAM" },
	{ ID: "BBD", Name: "BBD" },
	{ ID: "BDT", Name: "BDT" },
	{ ID: "BGN", Name: "BGN" },
	{ ID: "BHD", Name: "BHD" },
	{ ID: "BIF", Name: "BIF" },
	{ ID: "BMD", Name: "BMD" },
	{ ID: "BND", Name: "BND" },
	{ ID: "BOB", Name: "BOB" },
	{ ID: "BRL", Name: "BRL" },
	{ ID: "BSD", Name: "BSD" },
	{ ID: "BTN", Name: "BTN" },
	{ ID: "BWP", Name: "BWP" },
	{ ID: "BYN", Name: "BYN" },
	{ ID: "BYR", Name: "BYR" },
	{ ID: "BZD", Name: "BZD" },
	{ ID: "CAD", Name: "CAD" },
	{ ID: "CDF", Name: "CDF" },
	{ ID: "CHF", Name: "CHF" },
	{ ID: "CLP", Name: "CLP" },
	{ ID: "CNY", Name: "CNY" },
	{ ID: "COP", Name: "COP" },
	{ ID: "CRC", Name: "CRC" },
	{ ID: "CUC", Name: "CUC" },
	{ ID: "CUP", Name: "CUP" },
	{ ID: "CVE", Name: "CVE" },
	{ ID: "CZK", Name: "CZK" },
	{ ID: "DJF", Name: "DJF" },
	{ ID: "DKK", Name: "DKK" },
	{ ID: "DOP", Name: "DOP" },
	{ ID: "DZD", Name: "DZD" },
	{ ID: "EGP", Name: "EGP" },
	{ ID: "ERN", Name: "ERN" },
	{ ID: "ETB", Name: "ETB" },
	{ ID: "EUR", Name: "EUR" },
	{ ID: "FJD", Name: "FJD" },
	{ ID: "FKP", Name: "FKP" },
	{ ID: "GBP", Name: "GBP" },
	{ ID: "GEL", Name: "GEL" },
	{ ID: "GGP", Name: "GGP" },
	{ ID: "GHS", Name: "GHS" },
	{ ID: "GIP", Name: "GIP" },
	{ ID: "GMD", Name: "GMD" },
	{ ID: "GNF", Name: "GNF" },
	{ ID: "GTQ", Name: "GTQ" },
	{ ID: "GYD", Name: "GYD" },
	{ ID: "HKD", Name: "HKD" },
	{ ID: "HNL", Name: "HNL" },
	{ ID: "HRK", Name: "HRK" },
	{ ID: "HTG", Name: "HTG" },
	{ ID: "HUF", Name: "HUF" },
	{ ID: "IDR", Name: "IDR" },
	{ ID: "ILS", Name: "ILS" },
	{ ID: "IMP", Name: "IMP" },
	{ ID: "INR", Name: "INR" },
	{ ID: "IQD", Name: "IQD" },
	{ ID: "IRR", Name: "IRR" },
	{ ID: "ISK", Name: "ISK" },
	{ ID: "JEP", Name: "JEP" },
	{ ID: "JMD", Name: "JMD" },
	{ ID: "JOD", Name: "JOD" },
	{ ID: "JPY", Name: "JPY" },
	{ ID: "KES", Name: "KES" },
	{ ID: "KGS", Name: "KGS" },
	{ ID: "KHR", Name: "KHR" },
	{ ID: "KMF", Name: "KMF" },
	{ ID: "KPW", Name: "KPW" },
	{ ID: "KRW", Name: "KRW" },
	{ ID: "KWD", Name: "KWD" },
	{ ID: "KYD", Name: "KYD" },
	{ ID: "KZT", Name: "KZT" },
	{ ID: "LAK", Name: "LAK" },
	{ ID: "LBP", Name: "LBP" },
	{ ID: "LKR", Name: "LKR" },
	{ ID: "LRD", Name: "LRD" },
	{ ID: "LSL", Name: "LSL" },
	{ ID: "LTL", Name: "LTL" },
	{ ID: "LYD", Name: "LYD" },
	{ ID: "MAD", Name: "MAD" },
	{ ID: "MDL", Name: "MDL" },
	{ ID: "MGA", Name: "MGA" },
	{ ID: "MKD", Name: "MKD" },
	{ ID: "MMK", Name: "MMK" },
	{ ID: "MNT", Name: "MNT" },
	{ ID: "MOP", Name: "MOP" },
	{ ID: "MRU", Name: "MRU" },
	{ ID: "MUR", Name: "MUR" },
	{ ID: "MVR", Name: "MVR" },
	{ ID: "MWK", Name: "MWK" },
	{ ID: "MXN", Name: "MXN" },
	{ ID: "MYR", Name: "MYR" },
	{ ID: "MZN", Name: "MZN" },
	{ ID: "NAD", Name: "NAD" },
	{ ID: "NGN", Name: "NGN" },
	{ ID: "NIO", Name: "NIO" },
	{ ID: "NOK", Name: "NOK" },
	{ ID: "NPR", Name: "NPR" },
	{ ID: "NZD", Name: "NZD" },
	{ ID: "OMR", Name: "OMR" },
	{ ID: "PAB", Name: "PAB" },
	{ ID: "PEN", Name: "PEN" },
	{ ID: "PGK", Name: "PGK" },
	{ ID: "PHP", Name: "PHP" },
	{ ID: "PKR", Name: "PKR" },
	{ ID: "PLN", Name: "PLN" },
	{ ID: "PYG", Name: "PYG" },
	{ ID: "QAR", Name: "QAR" },
	{ ID: "RON", Name: "RON" },
	{ ID: "RSD", Name: "RSD" },
	{ ID: "RUB", Name: "RUB" },
	{ ID: "RWF", Name: "RWF" },
	{ ID: "SAR", Name: "SAR" },
	{ ID: "SBD", Name: "SBD" },
	{ ID: "SCR", Name: "SCR" },
	{ ID: "SDG", Name: "SDG" },
	{ ID: "SEK", Name: "SEK" },
	{ ID: "SGD", Name: "SGD" },
	{ ID: "SHP", Name: "SHP" },
	{ ID: "SLL", Name: "SLL" },
	{ ID: "SOS", Name: "SOS" },
	{ ID: "SPL", Name: "SPL" },
	{ ID: "SRD", Name: "SRD" },
	{ ID: "STN", Name: "STN" },
	{ ID: "SVC", Name: "SVC" },
	{ ID: "SYP", Name: "SYP" },
	{ ID: "SZL", Name: "SZL" },
	{ ID: "THB", Name: "THB" },
	{ ID: "TJS", Name: "TJS" },
	{ ID: "TMT", Name: "TMT" },
	{ ID: "TND", Name: "TND" },
	{ ID: "TOP", Name: "TOP" },
	{ ID: "TRY_LIRA", Name: "TRY_LIRA" },
	{ ID: "TTD", Name: "TTD" },
	{ ID: "TVD", Name: "TVD" },
	{ ID: "TWD", Name: "TWD" },
	{ ID: "TZS", Name: "TZS" },
	{ ID: "UAH", Name: "UAH" },
	{ ID: "UGX", Name: "UGX" },
	{ ID: "USD", Name: "USD" },
	{ ID: "UYU", Name: "UYU" },
	{ ID: "UZS", Name: "UZS" },
	{ ID: "VEF", Name: "VEF" },
	{ ID: "VND", Name: "VND" },
	{ ID: "VUV", Name: "VUV" },
	{ ID: "WST", Name: "WST" },
	{ ID: "XAF", Name: "XAF" },
	{ ID: "XCD", Name: "XCD" },
	{ ID: "XDR", Name: "XDR" },
	{ ID: "XOF", Name: "XOF" },
	{ ID: "XPF", Name: "XPF" },
	{ ID: "YER", Name: "YER" },
	{ ID: "ZAR", Name: "ZAR" },
	{ ID: "ZMW", Name: "ZMW" },
	{ ID: "ZMK", Name: "ZMK" },
	{ ID: "ZWD", Name: "ZWD" },
];

export const ReportingCategories = [
	{ Name: "Bookings", ID: AuditTypes.Bookings },
	{ Name: "Candidates", ID: AuditTypes.Candidates },
	{ Name: "Clients", ID: AuditTypes.Clients },
	{ Name: "Timesheets", ID: AuditTypes.Timesheets },
	{ Name: "Vacancies", ID: AuditTypes.Placeholders },
	{ Name: "Marketing", ID: AuditTypes.Marketing },
	{ Name: "Comment Tags", ID: AuditTypes.CommentTags },
];

export const ReportingChartTypes = [
	{ Name: "All", ID: ChartTypes.All },
	{ Name: "Moving Average", ID: ChartTypes.MovingAverage },
	{ Name: "Average Over Time", ID: ChartTypes.AverageOverTime },
];

export const ReportingGroupByTypes = [
	{ Name: "Daily", ID: GroupBy.Daily },
	{ Name: "Weekly", ID: GroupBy.Weekly },
	{ Name: "Monthly", ID: GroupBy.Monthly },
];

export const CandidateProcessingStatuses = [
	{ Name: "Not Started", ID: CandidateProcessingStatus.NotStarted },
	{ Name: "In Progress", ID: CandidateProcessingStatus.Started },
	{ Name: "Completed", ID: CandidateProcessingStatus.Complete },
];

export const BooleanItems = [
	{ Name: "Yes", ID: BooleanFilter.True },
	{ Name: "No", ID: BooleanFilter.False },
];

export const BooleanExcludeItems = [
	{ Name: "No", ID: BooleanFilter.True },
	{ Name: "Yes", ID: BooleanFilter.False },
];

export const CandidateDistanceFilterItems = [
	{ Name: "<= 5", ID: 5 },
	{ Name: "<= 10", ID: 10 },
	{ Name: "<= 15", ID: 15 },
	{ Name: "<= 20", ID: 20 },
	{ Name: "<= 25", ID: 25 },
	{ Name: "<= 30", ID: 30 },
	{ Name: "<= 35", ID: 35 },
	{ Name: "<= 40", ID: 40 },
	{ Name: "<= 45", ID: 45 },
	{ Name: "<= 50", ID: 50 },
];

export const EmailPreferencesOnboarding = [
	{
		ID: EmailPreferencesEnum.CandidateOnboardingComplete,
		Name: "Candidate Onboarding Complete",
		Desc: "Receive emails when an assigned candidate completes their onboarding.",
	},
	{
		ID: EmailPreferencesEnum.CandidateOnboardingDocumentComplete,
		Name: "Candidate Document Submission",
		Desc: "Receive emails when an assigned candidate completes their additional document submission.",
	},
	{
		ID: EmailPreferencesEnum.CandidateOnboardingBankComplete,
		Name: "Candidate Bank Details Submission",
		Desc: "Receive emails when an assigned candidate completes their additional bank details submission.",
	},
	{
		ID: EmailPreferencesEnum.CandidateOnboardingReferenceComplete,
		Name: "Candidate Reference Request Complete",
		Desc: "Receive emails when an assigned candidate's reference request is completed.",
	},
	// {
	// 	ID: EmailPreferencesEnum.CandidateInterviewConfirmationComplete,
	// 	Name: "Candidate Interview Completion Request Complete",
	// 	Desc: "Receive emails when an assigned candidate's interview responses have been confirmed.",
	// },
];

export const EmailPreferencesAssignee = [
	{
		ID: EmailPreferencesEnum.TimesheetAssignee,
		Name: "Timesheet Assignee",
		Desc: "Receive emails when you become the assignee of a timesheet.",
	},
	{
		ID: EmailPreferencesEnum.CandidateAssignee,
		Name: "Candidate Assignee",
		Desc: "Receive emails when you become the assignee of a candidate.",
	},
	{
		ID: EmailPreferencesEnum.BookingAssignee,
		Name: "Booking Assignee",
		Desc: "Receive emails when you become the assignee of a booking.",
	},
	{
		ID: EmailPreferencesEnum.BookingPlaceholderAssignee,
		Name: "Booking Vacancy Assignee",
		Desc: "Receive emails when you become the assignee of a vacant booking.",
	},
];

export const EmailPreferencesTimesheets = [
	{
		ID: EmailPreferencesEnum.TimesheetCandidateStatusUpdate,
		Name: "Candidate Update",
		Desc: "Receive emails when a candidate approves an assigned timesheet.",
	},
	{
		ID: EmailPreferencesEnum.TimesheetClientStatusUpdate,
		Name: "Client Update",
		Desc: "Receive emails when a clent approves an assigned timesheet.",
	},
	{
		ID: EmailPreferencesEnum.TimesheetCandidateQueryUpdate,
		Name: "Candidate Query",
		Desc: "Receive emails when a candidate queries an assigned timesheet.",
	},
	{
		ID: EmailPreferencesEnum.TimesheetClientQueryUpdate,
		Name: "Client Query",
		Desc: "Receive emails when a client queries an assigned timesheet.",
	},
];

export const EmailPreferencesBookings = [
	{
		ID: EmailPreferencesEnum.BookingInterviewAttendanceUpdate,
		Name: "Interview Attendance",
		Desc: "Receive emails when a candidate updates their interview attendance status.",
	},
	{
		ID: EmailPreferencesEnum.BookingTrialAttendanceUpdate,
		Name: "Trial Attendance",
		Desc: "Receive emails when a candidate updates their trial attendance status.",
	},
];

export const EmailPreferencesPlacements = [
	{
		ID: EmailPreferencesEnum.PlacementApplication,
		Name: "Candidate Applicant",
		Desc: "Receive emails when a new application applies to an external placement.",
	},
];

export const EmailPreferencesDocuments = [
	{
		ID: EmailPreferencesEnum.DocumentExpiry,
		Name: "Document Expiry",
		Desc: "Receive emails when a candidate documents expiration date triggers an expiration notification.",
	},
];

export const UserTargetTypes = [
	{
		ID: TargetTypes.DaysOut,
		Name: "Days Out",
	},
	{
		ID: TargetTypes.HoursOut,
		Name: "Hours Out",
	},
	{
		ID: TargetTypes.GrossProfit,
		Name: "Gross Profit",
	},
	{
		ID: TargetTypes.CandidateUse,
		Name: "Individual Candidates Booked",
	},
	{
		ID: TargetTypes.ClientUse,
		Name: "Individual Clients Booked",
	},
	{
		ID: TargetTypes.ClientUtilisation,
		Name: "Client Utilisation Percentage",
	},
];

export const Countries = [
	{ ID: "Afghanistan", Name: "Afghanistan" },
	{ ID: "Albania", Name: "Albania" },
	{ ID: "Algeria", Name: "Algeria" },
	{ ID: "Andorra", Name: "Andorra" },
	{ ID: "Angola", Name: "Angola" },
	{ ID: "Antigua & Deps", Name: "Antigua & Deps" },
	{ ID: "Argentina", Name: "Argentina" },
	{ ID: "Armenia", Name: "Armenia" },
	{ ID: "Australia", Name: "Australia" },
	{ ID: "Austria", Name: "Austria" },
	{ ID: "Azerbaijan", Name: "Azerbaijan" },
	{ ID: "Bahamas", Name: "Bahamas" },
	{ ID: "Bahrain", Name: "Bahrain" },
	{ ID: "Bangladesh", Name: "Bangladesh" },
	{ ID: "Barbados", Name: "Barbados" },
	{ ID: "Belarus", Name: "Belarus" },
	{ ID: "Belgium", Name: "Belgium" },
	{ ID: "Belize", Name: "Belize" },
	{ ID: "Benin", Name: "Benin" },
	{ ID: "Bhutan", Name: "Bhutan" },
	{ ID: "Bolivia", Name: "Bolivia" },
	{ ID: "Bosnia Herzegovina", Name: "Bosnia Herzegovina" },
	{ ID: "Botswana", Name: "Botswana" },
	{ ID: "Brazil", Name: "Brazil" },
	{ ID: "Brunei", Name: "Brunei" },
	{ ID: "Bulgaria", Name: "Bulgaria" },
	{ ID: "Burkina", Name: "Burkina" },
	{ ID: "Burundi", Name: "Burundi" },
	{ ID: "Cambodia", Name: "Cambodia" },
	{ ID: "Cameroon", Name: "Cameroon" },
	{ ID: "Canada", Name: "Canada" },
	{ ID: "Cape Verde", Name: "Cape Verde" },
	{ ID: "Central African Rep", Name: "Central African Rep" },
	{ ID: "Chad", Name: "Chad" },
	{ ID: "Chile", Name: "Chile" },
	{ ID: "China", Name: "China" },
	{ ID: "Colombia", Name: "Colombia" },
	{ ID: "Comoros", Name: "Comoros" },
	{ ID: "Congo", Name: "Congo" },
	{ ID: "Congo (Democratic Rep)", Name: "Congo (Democratic Rep)" },
	{ ID: "Costa Rica", Name: "Costa Rica" },
	{ ID: "Croatia", Name: "Croatia" },
	{ ID: "Cuba", Name: "Cuba" },
	{ ID: "Cyprus", Name: "Cyprus" },
	{ ID: "Czech Republic", Name: "Czech Republic" },
	{ ID: "Denmark", Name: "Denmark" },
	{ ID: "Djibouti", Name: "Djibouti" },
	{ ID: "Dominica", Name: "Dominica" },
	{ ID: "Dominican Republic", Name: "Dominican Republic" },
	{ ID: "East Timor", Name: "East Timor" },
	{ ID: "Ecuador", Name: "Ecuador" },
	{ ID: "Egypt", Name: "Egypt" },
	{ ID: "El Salvador", Name: "El Salvador" },
	{ ID: "Equatorial Guinea", Name: "Equatorial Guinea" },
	{ ID: "Eritrea", Name: "Eritrea" },
	{ ID: "Estonia", Name: "Estonia" },
	{ ID: "Ethiopia", Name: "Ethiopia" },
	{ ID: "Fiji", Name: "Fiji" },
	{ ID: "Finland", Name: "Finland" },
	{ ID: "France", Name: "France" },
	{ ID: "Gabon", Name: "Gabon" },
	{ ID: "Gambia", Name: "Gambia" },
	{ ID: "Georgia", Name: "Georgia" },
	{ ID: "Germany", Name: "Germany" },
	{ ID: "Ghana", Name: "Ghana" },
	{ ID: "Greece", Name: "Greece" },
	{ ID: "Grenada", Name: "Grenada" },
	{ ID: "Guatemala", Name: "Guatemala" },
	{ ID: "Guinea", Name: "Guinea" },
	{ ID: "Guinea-Bissau", Name: "Guinea-Bissau" },
	{ ID: "Guyana", Name: "Guyana" },
	{ ID: "Haiti", Name: "Haiti" },
	{ ID: "Honduras", Name: "Honduras" },
	{ ID: "Hungary", Name: "Hungary" },
	{ ID: "Iceland", Name: "Iceland" },
	{ ID: "India", Name: "India" },
	{ ID: "Indonesia", Name: "Indonesia" },
	{ ID: "Iran", Name: "Iran" },
	{ ID: "Iraq", Name: "Iraq" },
	{ ID: "Ireland (Republic)", Name: "Ireland (Republic)" },
	{ ID: "Israel", Name: "Israel" },
	{ ID: "Italy", Name: "Italy" },
	{ ID: "Ivory Coast", Name: "Ivory Coast" },
	{ ID: "Jamaica", Name: "Jamaica" },
	{ ID: "Japan", Name: "Japan" },
	{ ID: "Jordan", Name: "Jordan" },
	{ ID: "Kazakhstan", Name: "Kazakhstan" },
	{ ID: "Kenya", Name: "Kenya" },
	{ ID: "Kiribati", Name: "Kiribati" },
	{ ID: "Korea North", Name: "Korea North" },
	{ ID: "Korea South", Name: "Korea South" },
	{ ID: "Kosovo", Name: "Kosovo" },
	{ ID: "Kuwait", Name: "Kuwait" },
	{ ID: "Kyrgyzstan", Name: "Kyrgyzstan" },
	{ ID: "Laos", Name: "Laos" },
	{ ID: "Latvia", Name: "Latvia" },
	{ ID: "Lebanon", Name: "Lebanon" },
	{ ID: "Lesotho", Name: "Lesotho" },
	{ ID: "Liberia", Name: "Liberia" },
	{ ID: "Libya", Name: "Libya" },
	{ ID: "Liechtenstein", Name: "Liechtenstein" },
	{ ID: "Lithuania", Name: "Lithuania" },
	{ ID: "Luxembourg", Name: "Luxembourg" },
	{ ID: "Macedonia", Name: "Macedonia" },
	{ ID: "Madagascar", Name: "Madagascar" },
	{ ID: "Malawi", Name: "Malawi" },
	{ ID: "Malaysia", Name: "Malaysia" },
	{ ID: "Maldives", Name: "Maldives" },
	{ ID: "Mali", Name: "Mali" },
	{ ID: "Malta", Name: "Malta" },
	{ ID: "Marshall Islands", Name: "Marshall Islands" },
	{ ID: "Mauritania", Name: "Mauritania" },
	{ ID: "Mauritius", Name: "Mauritius" },
	{ ID: "Mexico", Name: "Mexico" },
	{ ID: "Micronesia", Name: "Micronesia" },
	{ ID: "Moldova", Name: "Moldova" },
	{ ID: "Monaco", Name: "Monaco" },
	{ ID: "Mongolia", Name: "Mongolia" },
	{ ID: "Montenegro", Name: "Montenegro" },
	{ ID: "Morocco", Name: "Morocco" },
	{ ID: "Mozambique", Name: "Mozambique" },
	{ ID: "Myanmar, (Burma)", Name: "Myanmar, (Burma)" },
	{ ID: "Namibia", Name: "Namibia" },
	{ ID: "Nauru", Name: "Nauru" },
	{ ID: "Nepal", Name: "Nepal" },
	{ ID: "Netherlands", Name: "Netherlands" },
	{ ID: "New Zealand", Name: "New Zealand" },
	{ ID: "Nicaragua", Name: "Nicaragua" },
	{ ID: "Niger", Name: "Niger" },
	{ ID: "Nigeria", Name: "Nigeria" },
	{ ID: "Norway", Name: "Norway" },
	{ ID: "Oman", Name: "Oman" },
	{ ID: "Pakistan", Name: "Pakistan" },
	{ ID: "Palau", Name: "Palau" },
	{ ID: "Panama", Name: "Panama" },
	{ ID: "Papua New Guinea", Name: "Papua New Guinea" },
	{ ID: "Paraguay", Name: "Paraguay" },
	{ ID: "Peru", Name: "Peru" },
	{ ID: "Philippines", Name: "Philippines" },
	{ ID: "Poland", Name: "Poland" },
	{ ID: "Portugal", Name: "Portugal" },
	{ ID: "Qatar", Name: "Qatar" },
	{ ID: "Romania", Name: "Romania" },
	{ ID: "Russian Federation", Name: "Russian Federation" },
	{ ID: "Rwanda", Name: "Rwanda" },
	{ ID: "St Kitts & Nevis", Name: "St Kitts & Nevis" },
	{ ID: "St Lucia", Name: "St Lucia" },
	{ ID: "Saint Vincent & the Grenadines", Name: "Saint Vincent & the Grenadines" },
	{ ID: "Samoa", Name: "Samoa" },
	{ ID: "San Marino", Name: "San Marino" },
	{ ID: "Sao Tome & Principe", Name: "Sao Tome & Principe" },
	{ ID: "Saudi Arabia", Name: "Saudi Arabia" },
	{ ID: "Senegal", Name: "Senegal" },
	{ ID: "Serbia", Name: "Serbia" },
	{ ID: "Seychelles", Name: "Seychelles" },
	{ ID: "Sierra Leone", Name: "Sierra Leone" },
	{ ID: "Singapore", Name: "Singapore" },
	{ ID: "Slovakia", Name: "Slovakia" },
	{ ID: "Slovenia", Name: "Slovenia" },
	{ ID: "Solomon Islands", Name: "Solomon Islands" },
	{ ID: "Somalia", Name: "Somalia" },
	{ ID: "South Africa", Name: "South Africa" },
	{ ID: "South Sudan", Name: "South Sudan" },
	{ ID: "Spain", Name: "Spain" },
	{ ID: "Sri Lanka", Name: "Sri Lanka" },
	{ ID: "Sudan", Name: "Sudan" },
	{ ID: "Suriname", Name: "Suriname" },
	{ ID: "Swaziland", Name: "Swaziland" },
	{ ID: "Sweden", Name: "Sweden" },
	{ ID: "Switzerland", Name: "Switzerland" },
	{ ID: "Syria", Name: "Syria" },
	{ ID: "Taiwan", Name: "Taiwan" },
	{ ID: "Tajikistan", Name: "Tajikistan" },
	{ ID: "Tanzania", Name: "Tanzania" },
	{ ID: "Thailand", Name: "Thailand" },
	{ ID: "Togo", Name: "Togo" },
	{ ID: "Tonga", Name: "Tonga" },
	{ ID: "Trinidad & Tobago", Name: "Trinidad & Tobago" },
	{ ID: "Tunisia", Name: "Tunisia" },
	{ ID: "Turkey", Name: "Turkey" },
	{ ID: "Turkmenistan", Name: "Turkmenistan" },
	{ ID: "Tuvalu", Name: "Tuvalu" },
	{ ID: "Uganda", Name: "Uganda" },
	{ ID: "Ukraine", Name: "Ukraine" },
	{ ID: "United Arab Emirates", Name: "United Arab Emirates" },
	{ ID: "United Kingdom", Name: "United Kingdom" },
	{ ID: "United States", Name: "United States" },
	{ ID: "Uruguay", Name: "Uruguay" },
	{ ID: "Uzbekistan", Name: "Uzbekistan" },
	{ ID: "Vanuatu", Name: "Vanuatu" },
	{ ID: "Vatican City", Name: "Vatican City" },
	{ ID: "Venezuela", Name: "Venezuela" },
	{ ID: "Vietnam", Name: "Vietnam" },
	{ ID: "Yemen", Name: "Yemen" },
	{ ID: "Zambia", Name: "Zambia" },
	{ ID: "Zimbabwe", Name: "Zimbabwe" },
];

export const ClientSpecialisms = [
	{ ID: "SEN", Name: "SEN" },
	{ ID: "Mainstream", Name: "Mainstream" },
	{ ID: "Virtual", Name: "Virtual" },
	{ ID: "EHCP Team", Name: "EHCP Team" },
];

export const CandidateInterviewTypes = [
	{ ID: CandidateInterviewQuestonTypes.Secondary, Name: "Secondary" },
	{ ID: CandidateInterviewQuestonTypes.Primary, Name: "Primary" },
	{ ID: CandidateInterviewQuestonTypes.GeneralTA, Name: "General TA" },
	{ ID: CandidateInterviewQuestonTypes.SENDTA, Name: "SEND TA" },
	{ ID: CandidateInterviewQuestonTypes.CoverSupervisor, Name: "Cover Supervisor" },
	{ ID: CandidateInterviewQuestonTypes.ExamInvigilator, Name: "Exam Invigilator" },
];

export const CandidateInterviewTypesBasic = [
	{ ID: CandidateInterviewQuestonTypes.Primary, Name: "Primary" },
	{ ID: CandidateInterviewQuestonTypes.Secondary, Name: "Secondary" },
	{ ID: CandidateInterviewQuestonTypes.GeneralTA, Name: "General TA" },
	{ ID: CandidateInterviewQuestonTypes.CoverSupervisor, Name: "Cover Supervisor" },
	{ ID: CandidateInterviewQuestonTypes.ExamInvigilator, Name: "Exam Invigilator" },
];

export const CandidateInterviewWorkTypeItems = [
	{ ID: CandidateInterviewWorkTypes.Full, Name: "Full Time" },
	{ ID: CandidateInterviewWorkTypes.Part, Name: "Part Time" },
	{ ID: CandidateInterviewWorkTypes.Both, Name: "Both" },
];

export const NotificationPreferencesEmail = [
	{
		ID: EmailSmsPreferenceTypes.CandidateOnboardingEmail,
		Name: "Candidate Onboarding Request",
		Desc: "Send an email inviting a candidate to complete their onboarding.",
	},
	{
		ID: EmailSmsPreferenceTypes.CandidateOnboardingReferenceEmail,
		Name: "Candidate Onboarding Reference Request",
		Desc: "Send an email inviting a candidate to complete their reference details.",
	},
	{
		ID: EmailSmsPreferenceTypes.CandidateOnboardingBankEmail,
		Name: "Candidate Onboarding Bank Details Request",
		Desc: "Send an email inviting a candidate to provide their bank details..",
	},
	{
		ID: EmailSmsPreferenceTypes.CandidateOnboardingDocumentEmail,
		Name: "Candidate Onboarding Additional Document Request",
		Desc: "Send an email inviting a candidate to provide additional documents.",
	},
	{
		ID: EmailSmsPreferenceTypes.CandidateAvailabilityEmail,
		Name: "Candidate Availability Request",
		Desc: "Send an email inviting a candidate to provide an availability update.",
	},
	{
		ID: EmailSmsPreferenceTypes.CandidateTimesheetEmail,
		Name: "Candidate Timesheet Approval",
		Desc: "Send an email inviting a candidate to complete their timesheet/s.",
	},
	{
		ID: EmailSmsPreferenceTypes.CandidateReferenceSubmissionEmail,
		Name: "Referee Invited to Provide Reference",
		Desc: "Send an email inviting a referee to provide a reference on behalf of a candidate.",
	},
	{
		ID: EmailSmsPreferenceTypes.PlacementManagerCandidateInterestEmail,
		Name: "Candidate Vacancy Manager Interest",
		Desc: "Send an email inviting a candidate to mark their interest for a potential booking.",
	},
	{
		ID: EmailSmsPreferenceTypes.CandidateBookingConfirmationEmail,
		Name: "Candidate Booking Confirmation",
		Desc: "Send an email to the candidate summarising the confirmed booking.",
	},
	{
		ID: EmailSmsPreferenceTypes.CandidateInterviewConfirmationEmail,
		Name: "Candidate Interview Questions Confirmation",
		Desc: "Send an email to the candidate requesting confirmation of completed interview responses.",
	},
];

export const NotificationPreferencesSMS = [
	{
		ID: EmailSmsPreferenceTypes.CandidateOnboardingSms,
		Name: "Candidate Onboarding Request",
		Desc: "Send an SMS inviting a candidate to complete their onboarding.",
	},
	// {
	//  ID: EmailSmsPreferenceTypes.CandidateOnboardingReferenceSms,
	//  Name: "Candidate Onboarding Reference Request",
	//  Desc: "Send an SMS inviting a candidate to complete their reference details.",
	// },
	{
		ID: EmailSmsPreferenceTypes.CandidateOnboardingBankSms,
		Name: "Candidate Onboarding Bank Details Request",
		Desc: "Send an SMS inviting a candidate to provide their bank details..",
	},
	{
		ID: EmailSmsPreferenceTypes.CandidateOnboardingDocumentSms,
		Name: "Candidate Onboarding Additional Document Request",
		Desc: "Send an SMS inviting a candidate to provide additional documents.",
	},
	{
		ID: EmailSmsPreferenceTypes.CandidateSms,
		Name: "Candidate SMS",
		Desc: "Send a custom SMS message to a candidate.",
	},
	{
		ID: EmailSmsPreferenceTypes.CandidateAvailabilitySms,
		Name: "Candidate Availability Request",
		Desc: "Send an SMS inviting a candidate to provide an availability update.",
	},
	{
		ID: EmailSmsPreferenceTypes.CandidateTimesheetSms,
		Name: "Candidate Timesheet Approval",
		Desc: "Send an SMS inviting a candidate to complete their timesheet/s.",
	},
	// {
	//  ID: EmailSmsPreferenceTypes.CandidateReferenceSubmissionSms,
	//  Name: "Referee Invited to Provide Reference",
	//  Desc: "Send an SMS inviting a referee to provide a reference on behalf of a candidate.",
	// },
	{
		ID: EmailSmsPreferenceTypes.PlacementManagerCandidateInterestSms,
		Name: "Candidate Vacancy Manager Interest",
		Desc: "Send an SMS inviting a candidate to mark their interest for a potential booking.",
	},
	{
		ID: EmailSmsPreferenceTypes.CandidateBookingConfirmationSms,
		Name: "Candidate Booking Confirmation",
		Desc: "Send an sms to the client summarising the confirmed booking.",
	},
	{
		ID: EmailSmsPreferenceTypes.CandidateInterviewConfirmationSms,
		Name: "Candidate Interview Questions Confirmation",
		Desc: "Send an sms to the candidate requesting confirmation of completed interview responses.",
	},
];

export const UserRoleTypes = [
	{
		ID: UserRole.RecruitmentConsultant,
		Name: "Recruitment Consultant",
	},
	{
		ID: UserRole.Compliance,
		Name: "Compliance",
	},
	{
		ID: UserRole.Manager,
		Name: "Manager",
	},
	{
		ID: UserRole.SuperAdministrator,
		Name: "Super Administrator",
	},
];

export const BespokeDashboardOptionsBookings = [
	{ ID: IndividualReportType.BookingsStandardBookingsCreatedLine, Name: "Standard Bookings Created" },
	{ ID: IndividualReportType.BookingsIATBookingsCreatedLine, Name: "IAT Bookings Created" },
	{ ID: IndividualReportType.BookingsDaysOutLine, Name: "Days Out" },
	{ ID: IndividualReportType.BookingsBookingDayChangesLine, Name: "Booking Day Changes" },
	{ ID: IndividualReportType.BookingsBookingCostsChangedLine, Name: "Booking Costs Changed" },
	{ ID: IndividualReportType.BookingsBookingsCancelledLine, Name: "Bookings Cancelled" },
	{ ID: IndividualReportType.BookingsProfitLossBar, Name: "Profit Loss" },
	{ ID: IndividualReportType.BookingsProfitLossByTypeBar, Name: "Profit Loss by type" },
	{ ID: IndividualReportType.BookingsTotalBookingsStat, Name: "Total Bookings" },
	{ ID: IndividualReportType.BookingsTotalIaTsStat, Name: "Total IaT's" },
	{ ID: IndividualReportType.BookingsBookingDaysAddedStat, Name: "Booking Days Added" },
	{ ID: IndividualReportType.BookingsBookingDaysRemovedStat, Name: "Booking Days Removed" },
	{ ID: IndividualReportType.BookingsBookingDaysChangeStat, Name: "Booking Days Change" },
	{ ID: IndividualReportType.BookingsProfitLossByTypeStat, Name: "Profit Loss, by type" },
	{ ID: IndividualReportType.BookingsProfitLossDaysRemovedStat, Name: "Profit Loss, days removed" },
	{ ID: IndividualReportType.BookingsProfitStat, Name: "Profit" },
	{ ID: IndividualReportType.BookingsCommissionStat, Name: "Commission" },
	{ ID: IndividualReportType.BookingsAwrReminders, Name: "AWR Reminders" },
];

export const BespokeDashboardOptionsCandidates = [
	{ ID: IndividualReportType.CandidatesCandidateEntry, Name: "Candidate Entry" },
	{ ID: IndividualReportType.CandidatesCandidatesToApproved, Name: "Candidates to Approved" },
	{ ID: IndividualReportType.CandidatesCandidatesToPending, Name: "Candidates to Pending" },
	{ ID: IndividualReportType.CandidatesCandidatesToArchived, Name: "Candidates to Archived" },
	{ ID: IndividualReportType.CandidatesCandidatesAnonymised, Name: "Candidates Anonymised" },
	{ ID: IndividualReportType.CandidatesCandidatesOnboardingRequested, Name: "Candidates Onboarding Requested" },
	{ ID: IndividualReportType.CandidatesCandidatesOnboardingCompleted, Name: "Candidates Onboarding Completed" },
	{ ID: IndividualReportType.CandidatesCandidatesBankRequested, Name: "Candidates Bank Requested" },
	{ ID: IndividualReportType.CandidatesCandidatesDocumentRequests, Name: "Candidates Document Requests" },
	{ ID: IndividualReportType.CandidatesCandidateReferenceRequests, Name: "Candidate Reference Requests" },
	{ ID: IndividualReportType.CandidatesCandidateEntrySource, Name: "Candidate Entry Source" },
	{ ID: IndividualReportType.CandidatesBDCalls, Name: "BD Calls" },
	{ ID: IndividualReportType.CandidatesCandidateCalls, Name: "Candidate Calls" },
	{ ID: IndividualReportType.CandidatesCandidateMeetings, Name: "Candidate Meetings" },
	{ ID: IndividualReportType.CandidatesDocumentsExpiring, Name: "Documents Expiring" },
	{ ID: IndividualReportType.CandidatesOthersExpiring, Name: "Other Expirations" },
	{ ID: IndividualReportType.CandidatesCandidatesInBookings, Name: "Candidates Booked" },
	{ ID: IndividualReportType.CandidatesCandidatesNotInBookings, Name: "Candidates not Booked" },
	{ ID: IndividualReportType.CandidatesCandidateUtilisation, Name: "Candidate Utilisation" },
	{ ID: IndividualReportType.CandidatesTotalCandidatePay, Name: "Total Candidate Pay" },
];

export const BespokeDashboardOptionsTimesheets = [
	{ ID: IndividualReportType.TimesheetsTimesheetsCreated, Name: "Timesheets Created" },
	{ ID: IndividualReportType.TimesheetsClientApproved, Name: "Client Approved" },
	{ ID: IndividualReportType.TimesheetsCandidateApproved, Name: "Candidate Approved" },
	{ ID: IndividualReportType.TimesheetsClientQueried, Name: "Client Queried" },
	{ ID: IndividualReportType.TimesheetsCandidateQueried, Name: "Candidate Queried" },
];

export const BespokeDashboardOptionsVacancies = [
	{ ID: IndividualReportType.VacanciesVacancyCreate, Name: "Vacancy Create" },
	{ ID: IndividualReportType.VacanciesVacancyComplete, Name: "Vacancy Complete" },
	{ ID: IndividualReportType.VacanciesVacancyCancelled, Name: "Vacancy Cancelled" },
];

export const BespokeDashboardOptionsClients = [
	{ ID: IndividualReportType.ClientsClientCreate, Name: "Client Create" },
	{ ID: IndividualReportType.ClientsClientContactsUpdated, Name: "Client Contacts Updated" },
	{ ID: IndividualReportType.ClientsBDCalls, Name: "BD Calls" },
	{ ID: IndividualReportType.ClientsClientCalls, Name: "Client Calls" },
	{ ID: IndividualReportType.ClientsClientMeetings, Name: "Client Meetings" },
	{ ID: IndividualReportType.ClientsClientSpend, Name: "Client Spend" },
	{ ID: IndividualReportType.ClientsClientProfit, Name: "Client Profit" },
	{ ID: IndividualReportType.ClientsClientUtilisation, Name: "Client Utilisation" },
	{ ID: IndividualReportType.ClientsTotalClientSpend, Name: "Total Client Spend" },
	{ ID: IndividualReportType.ClientsTotalClientProfit, Name: "Total Client Profit" },
];

export const BespokeDashboardOptionsMarketing = [
	{ ID: IndividualReportType.MarketingCampaignsSent, Name: "Campaigns Sent" },
	{ ID: IndividualReportType.MarketingTotalRecipients, Name: "Total Recipients" },
	{ ID: IndividualReportType.MarketingSuccessfullyDelivered, Name: "Successfully Delivered" },
	{ ID: IndividualReportType.MarketingOpens, Name: "Opens" },
	{ ID: IndividualReportType.MarketingClicks, Name: "Clicks" },
	{ ID: IndividualReportType.MarketingBounced, Name: "Bounced" },
	{ ID: IndividualReportType.MarketingDeferred, Name: "Deferred" },
];

export const CandidateVerificationAlerts = [
	{ Name: "Cleared", ID: "Cleared" },
	{ Name: "Alerts", ID: "Alerts" },
];

export const CandidateVerificationTRNAlerts = [
	{ Name: "Completed Induction", ID: "Completed Induction" },
	{ Name: "Not Yet Completed Induction", ID: "Not Yet Completed Induction" },
	{ Name: "Induction Exempt", ID: "Induction Exempt" },
	{ Name: "ALERTS - Failed Induction", ID: "ALERTS - Failed Induction" },
	{ Name: "Induction Not Required", ID: "Induction Not Required" },
];

export const DataSyncProviderItems = [{ Name: "Quba", ID: DataSyncProvidersEnum.Quba }];

export const BookingSortTypesItems = [
	{ Name: "Created At", ID: BookingSortTypes.createdAt },
	{ Name: "Client Name", ID: BookingSortTypes.clientName },
	{ Name: "Candidate Name", ID: BookingSortTypes.candidateName },
];
