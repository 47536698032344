import React from "react";

export const CenterIcon = () => {
	return (
		<i>
			<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M8 2V14M2 8H14" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
			</svg>
		</i>
	);
};
