import Link from "next/link";
import XeroLogo from "../components/icons/xero";

export const BookingTypesHelpText = (
	<>
		<p>
			<strong>Standard Booking:</strong> A standard candidate placement. This type of booking contains a charge rate to the client and a pay rate to the candidate.
		</p>
		<p>
			<strong>Interview:</strong> A candidate interview. This booking contains a single day record, charge and pay rate is not applicable for an interview.
		</p>
		<p>
			<strong>Trial:</strong> A candidate placement trial. A candidate trial can contain multiple days and a pay rate if applicable.
		</p>
		<p>
			<strong>Tutor:</strong> A candidate tutor placement. Most similar to a standard booking, however the student is required. Students for tutoring are their own entity within Edun.
		</p>
	</>
);

export const BookingCandidateHelpText = (
	<>
		<p>Which candidate is the booking for? Once created the candidate for a booking cannot be changed.</p>
		<p>By default the last 100 candidates interacted with are returned. Use the search filter to search for additional candidates.</p>
	</>
);

export const BookingStudentHelpText = (
	<>
		<p>Which student is the candidate tutoring? If not known this can be left blank.</p>
	</>
);

export const BookingClientHelpText = (
	<>
		<p>Which client is the booking for? Once created the client for a booking cannot be changed.</p>
		<p>By default the last 100 clients interacted with are returned. Use the search filter to search for additional clients.</p>
	</>
);

export const BookingClientContactHelpText = (
	<>
		<p>The client contact is required for sending booking confirmations and timesheet approval requests for the candidate placement.</p>
	</>
);

export const BookingClientTimesheetContactHelpText = (
	<>
		<p>The timesheet contact is optional, if selected this contact will receive timesheet correspondence regarding the placement.</p>
	</>
);

export const BookingCandidateRoleHelpText = (
	<>
		<p>What role or title is the candidate booking for? E.g. Maths Teacher.</p>
	</>
);

export const BookingCandidateAWRHelpText = (
	<>
		<p>
			<strong>Agency Workers Regulations</strong>
		</p>
		<p>These regulations were implemented in the UK in 2010 and are designed to ensure that temporary agency workers receive equal treatment to permanent employees in the workplace.</p>
		<p>
			This includes pay, working hours, overtime, breaks, rest periods, and annual leave. The AWR aims to prevent discrimination against agency workers and to ensure fairness in the workplace.
		</p>
		<p>Typically 12 weeks.</p>
		<p>Any value entered here will be visible to client recipients of booking confirmations.</p>
	</>
);

export const BookingCandidateAWRDateHelpText = (
	<>
		<p>
			<strong>Agency Workers Regulations Date</strong>
		</p>
		<p>The date at which the Agency Workers Regulations apply to the candidate within the placement.</p>
		<p>Edun will begin to send reminders leading up to this date.</p>
	</>
);

export const BookingCandidatePayTypeHelpText = (
	<>
		<p>How will the candidate be paid? The charge and pay rates for the booking will be paid according to this selection.</p>
	</>
);

export const BookingCandidatePayRateHelpText = (
	<>
		<p>How does the candidate get paid? Paye or Umbrella? Umbrella bookings have no on-cost&apos;s associated.</p>
		<p>The default value is pulled from the candidate record.</p>
		<p>
			<strong>Limited Company</strong>
		</p>
		<p>Limited Company bookings have no on-cost&apos;s associated.</p>
	</>
);

export const BookingCandidateChargeRateHelpText = (
	<>
		<p>What is the charge rate back to the client?</p>
	</>
);

export const BookingCandidateFeeAmountHelpText = (
	<>
		<p>What is the fee amount back to the client?</p>
	</>
);

export const BookingCandidateFeePercentageHelpText = (
	<>
		<p>The fee amount percentage this booking is using.</p>
	</>
);

export const BookingCandidateBookingPayRateHelpText = (
	<>
		<p>What is the pay rate back to the candidate?</p>
	</>
);

export const BookingAdditionalCandidateBookingPayRateHelpText = (
	<>
		<p>What is the pay rate back to the candidate?</p>
		<p>As this is an additional candidate added to the booking if no payrate is added they will default to the main booking pay rate.</p>
	</>
);

export const BookingAdditionalCandidateAddHelpText = (
	<>
		<p>You can add multiple candidates to the same booking during the booking configuration. Upon booking creation a booking record will be created for each candidate added.</p>
		<p>
			You are able to specify custom pay rates for each candidate added. If not custom pay rate is added for additional candidates they will default to the main pay rate value set on the
			booking.
		</p>
		<p>Each created booking will have their own booking confirmations.</p>
	</>
);

export const BookingCandidateSalaryPayTypeHelpText = (
	<>
		<p>How will the candidate get paid post successful trial / interview?</p>
	</>
);

export const BookingCandidateSalaryHelpText = (
	<>
		<p>What is the post-successful trial/ interview salary or daily pay rate?</p>
	</>
);

export const BookingCandidatePermSalaryHelpText = (
	<>
		<p>What is the starting salary for the candidate?</p>
	</>
);

export const BookingGlobalBookingTimeHelpText = (
	<>
		<p>New booking days will default to the global booking start and end time.</p>
	</>
);

export const BookingArrivalTimeHelpText = (
	<>
		<p>Does the candidate need to arrive at a particular time on their first day only?</p>
	</>
);

export const BookingAnythingElseHelpText = (
	<>
		<p>Anything else to make the candidate aware of as part of the booking?</p>
	</>
);

export const BookingAnythingElseClientHelpText = (
	<>
		<p>Anything else to make the client aware of as part of the booking?</p>
	</>
);

export const BookingBreaksHelpText = (
	<>
		<p>
			<strong>Break Time Adjustment</strong>
		</p>
		<p>
			This feature allows you to accurately calculate the total paid work hours by deducting the break time from the day&apos;s total hours. Simply enter the total duration of breaks taken each
			day. This duration will be subtracted from the total work hours, ensuring that the hours calculated reflect only the time spent working.
		</p>
	</>
);

export const TimesheetSelectClientHelpText = (
	<>
		<p>Select the client the timesheet will be assigned to.</p>
	</>
);

export const TimesheetSelectBookingHelpText = (
	<>
		<p>Select the booking for the timesheet.</p>
	</>
);

export const TimesheetSelectCandidateNameHelpText = (
	<>
		<p>The candidate name will be displayed on the timesheet and visible to the client for approval.</p>
	</>
);

export const TimesheetClientNameHelpText = (
	<>
		<p>The client name will be displayed on the timesheet and visible to the candidate for approval.</p>
	</>
);

export const TimesheetClientEmailHelpText = (
	<>
		<p>The email address the timesheet will be sent to for client approval.</p>
	</>
);

export const TimesheetCoverageHelpText = (
	<>
		<p>The days from the booking the timesheet covers.</p>
	</>
);

export const TimesheetClientStatusHelpText = (
	<>
		<p>The current client approval status for the timesheet.</p>
		<p>If the timesheet has been queried the query reason will be detailed along with any additional information within the Timesheet Correspondence below.</p>
	</>
);

export const TimesheetCandidateStatusHelpText = (
	<>
		<p>The current candidate approval status for the timesheet.</p>
		<p>If the timesheet has been queried the query reason will be detailed along with any additional information within the Timesheet Correspondence below.</p>
	</>
);

export const TimesheetDayStatusHelpText = (
	<>
		<p>The current status of the booking day.</p>
		<p>If the candidate was unable to attend the booking day, the status can be changed to reflect this.</p>
	</>
);

export const TimesheetClientClientNameHelpText = (
	<>
		<p>The client name, this will be visible by clients and candidates on any generated timesheets.</p>
	</>
);

export const TimesheetClientContactFirstNameHelpText = (
	<>
		<p>First name is required, often visible in client communications such as timesheet approval requests.</p>
	</>
);

export const TimesheetClientContactEmailHelpText = (
	<>
		<p>The contacts email address is required, often used for sending client communications such as timesheet approval requests.</p>
	</>
);

export const TimesheetClientColourHelpText = (
	<>
		<p>The selected colour is used to differentiate clients visually when looking at areas such as the booking calendar within Edun.</p>
		<p>No external communications from Edun uses the selected colour.</p>
	</>
);

export const CandidateNextofKinNumberHelpText = (
	<>
		<p>Please enter a valid telephone number in case of emergency.</p>
	</>
);

export const CandidateFirstNameHelpText = (
	<>
		<p>The candidates first name is required, often visible in candidate communications such as onboarding, further information requests and timesheet approval requests.</p>
	</>
);

export const CandidateLastNameHelpText = (
	<>
		<p>The candidates last name is required, often visible in candidate communications such as onboarding, further information requests and timesheet approval requests.</p>
	</>
);

export const CandidateEmailHelpText = (
	<>
		<p>The candidates email is required, often used for sending candidate communications such as onboarding, further information requests and timesheet approval requests.</p>
	</>
);

export const CandidateQualificationTypeHelpText = (
	<>
		<p>It is often important to check the relevant qualifications have been added for placement bookings.</p>
	</>
);

export const CandidatesPageHelpText = (
	<>
		<p>
			Effective candidate management is pivotal to the seamless operation of Edun. Candidate data is meticulously organized into various sections, facilitating a structured approach to both
			onboarding and ongoing candidate management.
		</p>
		<p>
			<strong>Candidate Workflow</strong>
		</p>
		<p>Candidates within Edun are filtered into four distinct statuses, each reflecting a different stage in the candidate&apos;s journey. Out of these, three statuses are visible within Edun:</p>
		<ul>
			<li>Approved: Candidates who have completed onboarding and passed various compliance checks to the satisfaction of the consultant.</li>
			<li>Pending: Candidates who are either in the process of onboarding or have yet to begin the process.</li>
			<li>Archived: Candidates who have been archived, possibly due to a pause in working engagements.</li>
			<li>
				Deleted / Anonymised: Candidate records that have been deleted or anonymised. While deleted records remain in the system for potential future retrieval, anonymised records lose all
				identifiable information, reflecting a higher level of data removal.
			</li>
		</ul>
	</>
);

export const CandidatesCompliancePageHelpText = (
	<>
		<p>
			Ensuring candidate compliance is paramount, and Edun endeavors to streamline this process for consultants. Our platform facilitates both candidates and consultants in managing
			compliance-related data efficiently. The digital onboarding experience allows candidates to upload crucial compliance documents, while consultants are equipped with tools to monitor,
			request, and manage compliance data effortlessly.
		</p>
		<p>
			As a consultant, you can easily view compliance data for all candidates in a single dashboard, allowing for quick and efficient compliance monitoring and management. The dashboard is
			available to consultants with the relevant compliance permissions and provides an overview of compliance documents and data in a single table, allowing compliance requests to be sent
			directly to the candidate from the same interface.
		</p>
		<p>
			<strong>Compliance Statuses</strong>
		</p>
		<p>
			The overall compliance status for a compliance item will default to the lowest status, for example if there are 2 active DBS documents on a candidates record and one is in date and one is
			expired, the overall compliance status for this item will be <strong>expired</strong>. It is important to keep on top of archving non valid documents.
		</p>
		<p>
			<strong>Understanding the Compliance Dashboard</strong>
		</p>
		<p>
			The compliance dashboard is split into a mixture of readonly data and user action columns. The data represents the compliance status of either particular in date documents, presence of
			certain documents, or data being added to a candidate record.
		</p>
		<ul>
			<li>Status: The current candidate status.</li>
			<li>Occupation: The current top level candidates occupation.</li>
			<li>Specialism: The current specialism value (truncated to 75 characters)</li>
			<li>Identity Group 1: The presence of at least 1 in date group 1 candidate document.</li>
			<li>Driving Licence: The presence of at least 1 in date driving licence document.</li>
			<li>Proof of Address: The presence of at least 2 proof of address documents (1 if multiple group 1 was provided)</li>
			<li>
				NI: Has the candidates National Insurance been added to the candidates bank details within their profile or a National Insurance document detailing proof has been uploaded to the
				candidate record.
			</li>
			<li>Qualifications: The persence of a valid qualifications document.</li>
			<li>References: The presence of at least 2 references supplied by the candidate.</li>
			<li>DBS: The presence of a valid DBS document or DBS last checked date within 12 months.</li>
			<li>
				Update Service: Has the Update Service Last Checked date been added to the candidates Criminal Record profile section or an Update Service document detailing proof has been uploaded to
				the candidate record.
			</li>
			<li>Prohibition: Has the prohibition check date been added to the candidate profile.</li>
			<li>Declaration: Has the candidate completed all declarations provided within the candidates onboarding journey.</li>
		</ul>
	</>
);

export const CandidatesBookingsHelpText = (
	<>
		<p>
			The Bookings module is a cornerstone of the CRM platform, serving as a nexus between candidates and clients. It lays the foundation for generating timesheets and tracking various types of
			bookings-whether they be standard placements, interviews, trials, or permanent placements.
		</p>
		<p>To manage draft bookings, navigate to your availability manager.</p>
	</>
);

export const CandidatesPlaceholderBookingsHelpText = (
	<>
		<p>The Vacancy Manager module is a way of creating &quot;vacant&quot; bookings, inviting potential candidates to gauge interest and then converting them to live bookings within Edun.</p>
	</>
);

export const CandidatesPlaceholderApplicantsHelpText = (
	<>
		<p>
			When an applicant applies via the vacancy feed a candidate record is automatically created for them within Edun. This record has a status of Enquired, and is not yet available for use
			anywhere else in the system.
		</p>
		<p>This view details the vacancy applied for and their application information. Their CVs are also accessible from this listing view.</p>
		<p>
			There are mainly 2 actions to take when a candidate has applied for a vacancy and their record is in an &quot;Enquired State&quot;. You can move to pending, and then continue onboarding,
			or archive the record. These can both be achieved from the candidate applicant record.
		</p>
	</>
);

export const CandidatesPlaceholderBookingsClientInterestHelpText = (
	<>
		<p>
			This optional step enables you to directly reach out to client contacts regarding candidates who have shown interest in specific placements. This streamlined process not only facilitates
			efficient communication but also enhances the chances of successful candidate placements.
		</p>
		<p>When you identify candidates interested in a particular placement, you can opt to send a request to the relevant client contact.</p>
		<p>
			Upon receiving your request, the client contact gains access to the list of available candidates. If any of these candidates have linked documents (such as CVs, or other relevant files),
			these can be selected for the client to view and download.
		</p>
	</>
);

export const CandidatesAvailabilityManagerHelpText = (
	<>
		<p>Streamline Booking and Candidate Management.</p>
		<p>
			The Availability Manager is designed to help consultants efficiently assess and manage the availability of draft bookings and candidates. This feature provides a centralised location where
			you can quickly view and organise candidate availability and short term bookings ready to be filled.
		</p>
		<p>Filter availability by week, allowing you to focus on the most relevant time periods and quickly identify availability.</p>
		<p>
			Match candidates to draft bookings by simply dragging and dropping them onto the relevant booking slot. Confirm the days the candidate should work, either fill the booking days or select a
			subset and continue managing.
		</p>
		<p>
			Streamline your availability gathering process by requesting availability from multiple candidates simultaneously. This feature eliminates the need for individual follow-ups or
			availability requests, saving you valuable time and effort.
		</p>
	</>
);

export const DraftBookingHelpText = (
	<>
		<p>
			Draft bookings are simply bookings that do not have a candidate yet, these differ from vacancy manager bookings as draft bookings are primarily used within the availability manager for
			short term placements.
		</p>
		<p>
			Once a draft booking has been created it will appear on the availability manager. Draft bookings are converted to live bookings by assigning a candidate, either via availability manager by
			dragging a candidate onto the booking, or by clicking the booking, editing and adding a candidate to the record.
		</p>
	</>
);

export const RequestBulkAvailabilityHelpText = (
	<>
		<p>Want to know when particular candidates are available for work? Simply create a bulk availability request of up to 100 candidates at once.</p>
		<p>
			After selecting the availability period and candidates to contact they will receive an email with a unique secure link for each candodate to update their availability for the period
			selected.
		</p>
	</>
);

export const CandidatesTimesheetsHelpText = (
	<>
		<p>
			The functionality of timesheets within Edun is designed to provide a seamless transition from bookings to the tracking and approval of worked hours. Originating from bookings, timesheets
			are pivotal in ensuring accurate recording and approval of work performed, thereby facilitating transparent and precise billing and payroll processes. All booking types, barring
			interviews, have the capability to transition into timesheets. This section elucidates the process and functionalities associated with timesheets in Edun.
		</p>
		<p>
			Timesheets in Edun can be crafted either in bulk or on an individual basis, providing flexibility in managing multiple bookings and their associated timesheets. Once a timesheet is
			created, it holds a record within the system, enabling a historical view and management of all timesheets.
		</p>
		<p>
			<strong>Bulk Creation</strong>: Ideal for managing multiple bookings simultaneously, thereby saving time and ensuring consistency across timesheets.
		</p>
		<p>
			<strong>Individual Creation</strong>: Provides a focused approach for creating a timesheet for a singular booking, allowing for precise entry and review.
		</p>
		<p>
			<strong>Approval Process</strong>
		</p>
		<p>
			The integrity of the data within a timesheet is upheld through a dual-approval process, necessitating both the client and candidate to review and approve the timesheet. This collaborative
			approval mechanism ensures that both parties agree on the hours worked before the timesheet attains an &quot;Approved&quot; status.
		</p>
	</>
);

export const StudentsPageHelpText = (
	<>
		<p>Students are lightweight records associated to Tutor type bookings.</p>
		<p>Basic student records can be stored within Edun, timesheets can be created from Tutor bookings linked to students, trail of booking history per student can be viewed and exported.</p>
	</>
);

export const CandidatesClientsHelpText = (
	<>
		<p>
			In Edun, the term &quot;Clients&quot; refers to places of education, which are a crucial entity within the system. The &quot;Clients&quot; section is designed to house all pertinent
			information regarding the places of education, facilitating seamless interactions and transactions between your organisation and the client. This section is a repository for contact
			details of various client contacts, the dispatch of terms of business, and other relevant communication.
		</p>
		<p>Client contacts are required for any communications in the system such as Timesheet approval requests &amp; Marketing.</p>
	</>
);

export const ClientContactsHelpText = (
	<>
		<p>
			<strong>Headteacher</strong>
		</p>
		<p>The Headteacher of the client.</p>
		<p>
			<strong>Assistant Headteacher</strong>
		</p>
		<p>Supportive Deputy in School Leadership.</p>
		<p>
			<strong>Headteachers PA</strong>
		</p>
		<p>Headteacher&apos;s Personal Assistant and Administrative Support.</p>
		<p>
			<strong>Cover Manager</strong>
		</p>
		<p>Organises staff cover arrangements for absent teachers. Often used as the recipient for booking confirmations and timesheet approvals within Edun.</p>
		<p>
			<strong>HR Manager</strong>
		</p>
		<p>Manages human resources and personnel matters in the school.</p>
		<p>
			<strong>Finance Manager</strong>
		</p>
		<p>
			Handles financial management and budgeting for the school. If entered this contact will be used in payment run&apos;s, falling back to the client contact on the timesheet if not present.
		</p>
		<p>
			<strong>Invoice Contact</strong>
		</p>
		<p>Manages invoicing and financial communications with vendors. Often used as the recipient for booking confirmations and timesheet approvals within Edun.</p>
		<p>
			<strong>Business Manager</strong>
		</p>
		<p>Oversees school operations and budget management.</p>
		<p>
			<strong>Business Administrator</strong>
		</p>
		<p>Provides administrative support to the school&apos;s business functions.</p>
		<p>
			<strong>Head of Maths</strong>
		</p>
		<p>Leads the Mathematics department and curriculum.</p>
		<p>
			<strong>Head of Science</strong>
		</p>
		<p>Leads the Science department and curriculum.</p>
		<p>
			<strong>Head of English</strong>
		</p>
		<p>Leads the English department and curriculum.</p>
		<p>
			<strong>Deputy Headteacher</strong>
		</p>
		<p>Assists the headteacher in school leadership and management.</p>
		<p>
			<strong>SENCO</strong>
		</p>
		<p>Supports students with special educational needs and ensures appropriate provisions.</p>
	</>
);

export const MarketingCampaignsHelpText = (
	<>
		<p>Reach your clients or candidates faster via Edun.</p>
		<p>
			Create marketing campaigns using pre-defined templates created from within Edun. Search for your client or candidate contacts and build up your recipient list. Dynamically include useful
			information to personalise your campaigns for each recipient.
		</p>
	</>
);

export const AutomationHelpText = (
	<>
		<p>Create automation rules.</p>
		<p>Create automation rules. Examples to follow.</p>
	</>
);

export const MarketingCampaignTemplatesHelpText = (
	<>
		<p>Build your marketing campaign template from within Edun.</p>
		<p>Use our drag and drop editor to build up your email template, add your content and content replacement variables using the options defined below.</p>
		<p>Once created you can use your templates for your Edun marketing campaigns.</p>
		<p>Campaign specific templates are not included in the templates table, they are only available to use directly within a campaign.</p>
		<p>
			Campaign specific templates are not editable, and are single use templates for specific campaigns. If you need to edit a campaign specific template, navigate to the campaign creation page,
			select the template you wish to use, click &apos;View / Edit Template&apos; then amend the template speicfically for your campaign and click &quot;Save To Campaign Only&quot;.
		</p>
	</>
);

export const MarketingSettingsHelpText = (
	<>
		<p>Configure your marketing settings within Edun.</p>
		<p>Check your verified domain status and setup your companies marketing signature. Each template used will inherit the below signature.</p>
	</>
);

export const BookingPageHelpText = (
	<>
		<p>Create or update your candidate bookings.</p>
		<p>Set the client and candidate for the booking, specify the type of booking, placement, trial or interview, and setup the remaining booking information.</p>
		<p>
			<strong>Financial Information</strong>
		</p>
		<p>How will the candidate be paid? Daily or hourly.</p>
		<p>How does the candidate get paid? Paye or Umbrella? Umbrella bookings have no on-cost&apos;s associated. Limited Company bookings have no on-cost&apos;s associated.</p>
		<p>What is the charge rate back to the client?</p>
		<p>What is the pay rate back to the candidate?</p>
		<p>Configure the working days for the booking.</p>
		<p>
			The working days can be entered manually, one by one, or by selecting a start and end date for the booking. You can also create a weekly rota, each working day will inherit the start and
			end times from the relevant day within the weekly rota if applicable.
		</p>
		<p>
			Existing working days can have their status changed to reflect the actual working status of the candidate during the booking if required. Days marked as non-active, e.g. sickness will not
			be counted towards any profit / commission calculations.
		</p>
	</>
);

export const CandidatePageHelpText = (
	<>
		<p>Candidate information is categorised into several sections, each capturing a different facet of candidate data:</p>
		<ul>
			<li>Contact Information</li>
			<li>Emtergency Contact</li>
			<li>Qualifications</li>
			<li>Employment History</li>
			<li>Documents</li>
			<li>References</li>
			<li>Criminal Record</li>
			<li>Keeping Chidren Safe</li>
			<li>Health Declaration</li>
			<li>Documents</li>
			<li>Bank Details</li>
			<li>Paye/ Umbrella</li>
		</ul>

		<p>
			These sections can either be filled out by the candidate during the onboarding process or manually by a consultant. However, a consultant can only edit candidate records for which they are
			the current assignee. The assignment of candidates can be toggled, with each action being logged in an audit trail, ensuring accountability and traceability of actions.
		</p>
	</>
);

export const TimesheetCorrespondanceInternalVisibilityHelpText = (
	<>
		<p>Configure the visibility of your comment.</p>
		<p>Internal only will be visiible by your Edun users only.</p>
		<p>External will give you the option to make the comment available for clients, candidates or both.</p>
	</>
);

export const TimesheetCorrespondanceVisibilityHelpText = (
	<>
		<p>Who will be able to see your comment?</p>
		<p>Clients, candidates or both.</p>
	</>
);

export const TimesheetCorrespondanceNotifyCandidateHelpText = (
	<>
		<p>Selecting yes will send an email to the candidate notifying them of your comment.</p>
		<p>Make sure to select the appropriate comment visibility.</p>
	</>
);

export const TimesheetCorrespondanceNotifyClientHelpText = (
	<>
		<p>Selecting yes will send an email to the client notifying them of your comment.</p>
		<p>Make sure to select the appropriate comment visibility.</p>
	</>
);

export const XeroIntegrationHelpText = (
	<>
		<p>If your Xero integration status is &quot;Not Connected&quot;, you will need to connect your Xero account in order to sync your data and manage invoices.</p>
		<p>If your Xero integration status is &quot;Connected&quot;, no further action is required.</p>
	</>
);

export const InvoicesPaymentsPageHelpText = (
	<>
		<p>
			<XeroLogo />
		</p>
		<p>Edun offers Xero integration to help manage your invoices.</p>
		<p>You can view and create Invoices from Edun using your linked Xero account.</p>
		<p>
			Connect your account from the company{" "}
			<Link href="/account/me/integrations/xero" target="_blank">
				<a>integrations settings</a>
			</Link>{" "}
			page.
		</p>
		<p>
			In order to create invoices we require you to have a valid default{" "}
			<a href="https://go.xero.com/InvoiceSettings/InvoiceSettings.aspx" target="_blank" rel="noreferrer">
				invoice template
			</a>{" "}
			and payment service setup and linked to your invoice template from within your Xero account. Invoices created from Edun use your default invoice template.
		</p>
		<p>
			If you wish to create an invoice with a different template you can choose to not auto-approve and send the invoice from Edun, giving you the opportunity to change the template from within
			Xero.
		</p>
	</>
);

export const InvoicesPaymentsRunPageHelpText = (
	<>
		<p>
			Payment runs can be a crucial component in the recruitment industry. They refer to the aggregation of timesheets for a specific period. This aggregation aids in determining the accurate
			pay for candidates and the charge amounts for clients.
		</p>
		<p>
			Payment runs ensure that candidates receive the correct amount for their services and that clients are charged appropriately based on the agreed rates. This systematic approach helps in
			streamlining financial transactions, avoiding discrepancies, and ensuring timely payments.
		</p>
	</>
);

export const InvoicesAccountHelpText = (
	<>
		<p>In Xero, an Account represents a financial account that is used to track financial transactions, such as sales, purchases, expenses, and payments.</p>
		<p>
			Every transaction in Xero must be assigned to an account. Not all account types can be used for invoicing, be sure to select an appropriate account. Most of the time the default Sales
			account is correct.
		</p>
		<p>You can manage your Xero accounts from within Xero. </p>
	</>
);

export const InvoicesDueDateHelpText = (
	<>
		<p>This is the date the invoice/s is due to be paid by the client. It will default to 30 days from the invoice date if not changed.</p>
		<p>Managing late / missed payments is not the responsibility of Edun. This can be managed from your Xero account.</p>
	</>
);

export const InvoicesDateHelpText = (
	<>
		<p>This is invoice date. It will default to today&apos;s date if not changed.</p>
	</>
);

export const InvoicesTrackingCategoriesHelpText = (
	<>
		<p>Choose tracking category to append additional Xero tracking data to invoice line items upon creation.</p>
		<p>If you have tracking categories setup in your Xero business settings you can select a category and append the relevant tracking value to each line item, this is optional per line item.</p>
		<p>
			By default if tracking categories are being used these are typically for linking consultants within Edun to consultants specified within Xero, if the assignee of the selected
			booking/timesheet matches a tracking category from the selected category Edun will automatically add the relevant consultants tracking category to the relevant line items. This is only
			possible if the consultants account first and last names match those entered within your Xero tracking categories.
		</p>
	</>
);

export const InvoicesSelectClientContactHelpText = (
	<>
		<p>In Xero, an invoice requires a contact. A contact is the client / email that will receive the invoice if you choose to automatically approve and send the invoice.</p>
		<p>Clients/contacts can be created from within Xero or from Edun when creating your invoice.</p>
		<p>Duplicate clients/contacts cannot exist within your Xero account.</p>
		<p>
			Xero matches clients/contacts by name. To ensure accurate matching, we attempt to align your client names within Edun with those stored in your Xero account. If the client/contact names in
			Xero differ from those in Edun, automatic selection of the most relevant client/contact for the invoice may not be possible. In such cases, you have the option to manually select the
			appropriate client/contact or create a new Xero client/contact directly from Edun, utilising the information from an existing Edun client contact.
		</p>
	</>
);

export const InvoiceCreateContactHelpText = (
	<>
		<p>Select the client and contact the invoice is for.</p>
	</>
);

export const InvoiceGroupingHelpText = (
	<>
		<p>When selecting bookings to include in any generated invoice from Edun you can choose how you would like to group and send invoices.</p>
		<p>
			When grouped by client an invoice will contain all bookings selected from the above dropdown for the same client. If grouped by booking then each booking will create it&apos;s own invoice.
		</p>
	</>
);

export const PaymentCreateRunHelpText = (
	<>
		<p>Once all timesheets for a set period are gathered, they&apos;re processed in a payment run. This involves:</p>
		<ul>
			<li>Select the date period for candidate chargeable days coverage</li>
			<li>Setting your estimated payment date (if applicable).</li>
			<li>Remove any unwanted timesheets from the returned data set.</li>
			<li>Create the payment run</li>
			<li>Export the created payment run</li>
		</ul>
	</>
);

export const PaymentEstimatedDueDateHelpText = (
	<>
		<p>Select the estimated date the payment is to be processed.</p>
		<p>This date is for your internal recording.</p>
	</>
);

export const CommissionThresholdHelpText = (
	<>
		<p>The profit value that must be exceeded before commission is paid out.</p>
		<p>If you require variable commission thresholds at different profit levels, you can add multiple records below.</p>
		<p>A users commission defaults to the ranges set at the company level if none are present.</p>
		<p>Commission thresholds work differently depending on your companies commission payout policy.</p>
		<span>
			<strong>Fixed Commission</strong>
		</span>
		<p>e.g. 10% commission on profit over £5000 per consultant. Min = 5000 & Commission = 10. 10% commission is calculated on booking profits over £5000.</p>
		<p>
			e.g. 10% commission on profit over £5000 per consultant up to £7500, 5% commission on profit over £7500. Min = 5000 & Commission = 10, Min = £7500 & commission = 5. 10% commission is
			calculated on booking profits over £5000. If booking profits exceed £7500 then 5% commission will be paid on all booking profits.
		</p>
		<p>e.g. 10% commission on all bookings. Min = 0 & commission = 10. 10% commission is calculated on all bookings profits.</p>
		<span>
			<strong>Variable Commission</strong>
		</span>
		<p>e.g. 10% commission on profit over £5000 per consultant. Min = 5000 & Commission = 10. 10% commission is calculated on booking profits over £5000.</p>
		<p>
			e.g. 10% commission on profit over £5000 per consultant up to £7500, 5% commission on profit over £7500. Min = 5000 & Commission = 10, Min = £7500 & commission = 5. 10% commission is
			calculated on booking profits over £5000. 5% commission is calculated on booking profits over £7500.
		</p>
		<p>e.g. 10% commission on all bookings. Min = 0 & commission = 10. 10% commission is calculated on all bookings profits.</p>
	</>
);

export const CommissionThresholdCustomerHelpText = (
	<>
		<p>The profit value that must be exceeded before commission is paid out.</p>
		<p>If you require variable commission thresholds at different profit levels, you can add multiple records below.</p>
		<p>If no records are present the companies default commission percentage will be 0.</p>
		<p>Commission thresholds work differently depending on your companies commission payout policy.</p>
		<span>
			<strong>Fixed Commission</strong>
		</span>
		<p>e.g. 10% commission on profit over £5000 per consultant. Min = 5000 & Commission = 10. 10% commission is calculated on booking profits over £5000.</p>
		<p>
			e.g. 10% commission on profit over £5000 per consultant up to £7500, 5% commission on profit over £7500. Min = 5000 & Commission = 10, Min = £7500 & commission = 5. 10% commission is
			calculated on booking profits over £5000. If booking profits exceed £7500 then 5% commission will be paid on all booking profits.
		</p>
		<p>e.g. 10% commission on all bookings. Min = 0 & commission = 10. 10% commission is calculated on all bookings profits.</p>
		<span>
			<strong>Variable Commission</strong>
		</span>
		<p>e.g. 10% commission on profit over £5000 per consultant. Min = 5000 & Commission = 10. 10% commission is calculated on booking profits over £5000.</p>
		<p>
			e.g. 10% commission on profit over £5000 per consultant up to £7500, 5% commission on profit over £7500. Min = 5000 & Commission = 10, Min = £7500 & commission = 5. 10% commission is
			calculated on booking profits over £5000. 5% commission is calculated on booking profits over £7500.
		</p>
		<p>e.g. 10% commission on all bookings. Min = 0 & commission = 10. 10% commission is calculated on all bookings profits.</p>
	</>
);

export const CommissionThresholdStartsAtHelpText = (
	<>
		<p>The commission % for this range will start to be paid when the profit reaches this minimum value.</p>
	</>
);

export const CommissionThresholdCommissionHelpText = (
	<>
		<p>The percentage commission to pay out on bookings at this range.</p>
	</>
);

export const CommissionThresholdCommissionWeeklyMonthlyText = (
	<>
		<p>
			<strong>How is commisioin paid out?</strong>
		</p>
		<p>By default commission values assume your commission thresholds are relative to a month. If your commission thresholds are relative to a week you can toggle the below setting to weekly.</p>
		<p>By selecting weekly, commission values will be relative to the number of 7 day periods within the filtered booking date range.</p>
	</>
);

export const CommissionThresholdCommissionStatWeeklyMonthlyText = (
	<>
		<p>
			<strong>How is commission calculated?</strong>
		</p>
		<p>Representative commission values are calculated based on the total booking profit and the commission thresholds setup against the company / user who created the booking.</p>
		<p>If your commission settings are weekly, viewing any date range larger than a week may display an incorrect representative commission value.</p>
		<p>If your commission settings are monthly, viewing any date range less then a month may display an incorrect representative commission value.</p>
	</>
);

export const CommissionThresholdCommissionFixedVariableText = (
	<>
		<p>
			A fixed commission will payout a fixed percentage based on the profit from the consultant. e.g. A consultant books £7500 profit, your commission thresholds are 2.5k - 5k 5%, 5K+ 10%, then
			the consultant would earn 10% commission on all profit.
		</p>
		<p>
			A variable commission will payout a variable percentage based on the profit from the consultant. e.g. A consultant books £7500 profit, your commission thresholds are 2.5k - 5k 5%, 5k+ 10%,
			then the consultant would earn 5% commission on all profit between 2.5k and 5k and 10% on any profit over 5k.
		</p>
		<p>
			<strong>
				Be careful when changing this setting, it is an organisation wide value and will affect all bookings going forward. If you end up with a mixture of fixed and variable commission
				bookings Edun will be unable to provide a representative commission value from the bookings calendar.
			</strong>
		</p>
	</>
);

export const CommissionThresholdMixedTypesHelpText = (
	<>
		<p>
			The dataset below contains bookings of fixed and variable commission types. It is only possible to calculate a representative commission value using all bookings of the same commission
			type.
		</p>
	</>
);

export const CandidateDocumentUploadHelpText = (
	<>
		<p>To upload a document select the document type first, once selected you can select the file and process the upload.</p>
	</>
);

export const MarketingCampaignCreateHelpText = (
	<>
		<p>Distributing marketing campaigns directly through Edun presents a highly efficient and impactful approach to engaging with your candidates and client contacts.</p>
		<p>
			<strong>Templates</strong>
		</p>
		<p>Select the appropriate pre-existing template to use for your campaign.</p>
		<p>
			<strong>Campaign Specific Templates</strong>
		</p>
		<p>
			Campaign specific templates are not editable, and are single use templates for specific campaigns. If you need to create a campaign specific template, select the template you wish to use,
			click &apos;View / Edit / Create Campaign Template&apos; then amend the template speicfically for your campaign and click &quot;Save To Campaign Only&quot;.
		</p>
		<p>
			<strong>Subject</strong>
		</p>
		<p>Choose a suitable and compelling email subject that resonates effectively with your recipients, maximizing the potential for achieving desired outcomes</p>
		<p>
			<strong>Attachments</strong>
		</p>
		<p>Include any optional attachments as needed, enhancing your message&apos;s content and potential impact</p>
		<p>
			<strong>Recipients</strong>
		</p>
		<p>
			Effortlessly add recipients by searching through client and candidate contacts. Make use of various properties associated with both clients and candidates to facilitate filtering. This
			streamlined approach allows you to target your desired audience effectively.
		</p>
		<p>
			Additionally, any email addresses that have previously blocked communication from your account will be automatically added to a 60-day blacklist. During this period, no further marketing
			emails will be sent to these users, ensuring their preferences are respected and Edun&apos;s email sending reputation remains high.
		</p>
		<p>
			Moreover, candidates who have chosen to opt out of receiving marketing communications will also be excluded from receiving any marketing communications. This ensures compliance with their
			preferences regarding marketing content.
		</p>
	</>
);

export const DocumentExpiryHelpText = (
	<>
		<p>
			<strong>Driving Licence, Passport, Visa:</strong> Expiration within 3 months will be flagged with an amber warning.
		</p>
		<p>
			<strong>DBS:</strong> Expiration within 6 months will be flagged with an amber warning. Expiration for DBS documents is by default 12 months from the document upload date.
		</p>
		<p>
			<strong>Expired:</strong> Expiration in the past will be flagged with a red warning.
		</p>
	</>
);

export const ShowPermDaysHelpText = (
	<>
		<p>
			By default, only the first day of a permanent booking appears on the booking calendar. To display all the working days for that booking, simply turn on the &apos;Show All Perm Days&apos;
			option.
		</p>
	</>
);

export const PlaceholderNoVacanciesHelpText = (
	<>
		<p>
			Vacant bookings can be completed to multiple candidates at once. Specify the total number of available vacancies for this booking. This value is only visible within Edun, not visible
			externally.
		</p>
		<p>If left empty, 1 available vacancy for this placement will be presumed.</p>
	</>
);

export const PlaceholderJobFeedsHelpText = (
	<>
		<p>Vacancies can be included in external job feeds or CV Library. This field indicates whether or not particular vacancies have been published or not.</p>
	</>
);

export const ProfilePhotoGuidelines = (
	<>
		<p>
			<strong>Photo Size and Quality</strong>
		</p>
		<p>Your photo must be at least 300px by 300px in size. This ensures adequate quality and clarity.</p>
		<p>The photo should not exceed 5MB. Larger files will be resized, you will be able to crop the photo once selected. However we encourage the use of images with a much smaller file size.</p>
		<p>
			<strong>Photo Style</strong>
		</p>
		<p>It should be a head-and-shoulders shot, facing the camera directly. Preferably use a plain, light-colored background. Avoid busy or distracting backgrounds.</p>
		<p>
			<strong>Use of Photo</strong>
		</p>
		<p>Your photo may be used in booking confirmations to facilitate easy recognition and professionalism.</p>
		<p>For compliance purposes, such as candidate verification, it&apos;s essential that your photo is a current and accurate representation of you.</p>
		<p>
			<strong>Photo Tips</strong>
		</p>
		<p>Ensure your face is well-lit without harsh shadows.</p>
		<p>Maintain a neutral expression. Avoid wearing hats or sunglasses.</p>
		<p>Use a high-quality image to ensure details are clear.</p>
		<p>
			<strong>Examples</strong>
		</p>
		<p>
			<img src="https://edun-cdn.s3.eu-north-1.amazonaws.com/assets/profile-photo-examples.png" />
		</p>
	</>
);

export const ReferenceRequestHelp = (
	<>
		<p>
			<strong>Requesting References</strong>
		</p>
		<p>
			When you request a reference, an email will be sent to the referee, containing a unique link that remains active for 21 days. Once the reference is submitted, it will be marked as
			&quot;received&quot; in our system. You can then view the referee&apos;s response directly through the reference record.
		</p>
		<p>
			<strong>Mark as Received</strong>
		</p>
		<p>
			In some cases, you might receive a reference through a different format or method, such as a document. For these instances, you have the option to manually mark the reference as
			&quot;received&quot; in our system.
		</p>
	</>
);

export const CandidateProfileSummaryHelpText = (
	<>
		<p>Provide up to 3000 characters for the candidates profile.</p>
		<p>This summary will be pulled into any automatically generated Edun Candidate CV&apos;s</p>
	</>
);

export const CandidateProfileExperienceSummaryHelpText = (
	<>
		<p>Provide up to 3000 characters for the candidates profile and experience summary.</p>
		<p>This experience summary will be pulled into any automatically generated Edun Candidate CV&apos;s alongside their employment and work history.</p>
	</>
);

export const CandidatePaymentIntervalsHelpText = (
	<>
		<p>
			Candidate payment intervals, the frequency at which the candidate should receive their payment. Note: This is a static field for your records and has no impact on any potential payment
			process.
		</p>
	</>
);

export const ReportingTimeGroupingHelpText = (
	<>
		<p>Allows data to be grouped and viewed in daily, weekly, or monthly chunks. For extended data ranges, weekly and monthly groupings offer a more condensed view.</p>
	</>
);

export const ReportingTeamHelpText = (
	<>
		<p>Filter the data by one of the teams setup within your business.</p>
	</>
);

export const ReportingUserHelpText = (
	<>
		<p>Filter the data by one of your business users.</p>
	</>
);

export const ReportingCategoryHelpText = (
	<>
		<p>Select from Bookings, Candidates, Clients, Timesheets, Vacancies or Marketing categories to narrow down the kind of data you wish to see.</p>
	</>
);

export const ReportingChartTypeHelpText = (
	<>
		<p>
			Choose between various chart presentations. The &quot;All&quot; option displays raw data, showing zeros for dates without any data. &quot;Moving Average&quot; offers a more smoothed out
			visualisation, connecting data points and ignoring dates with no data. Lastly, &quot;Average&quot; provides an overarching average for the selected time period.
		</p>
	</>
);

export const BookingJobBoardsHelpText = (
	<>
		<p>At present we offer the abilty to post placement requests as vacancies on external websites.</p>
		<p>Your job board settings can be found in your company settings. Including installation instructions.</p>
	</>
);

export const TRNLastCheckedDateHelpText = (
	<>
		<p>The date the TRN Number was last checked. You will automatically be sent a reminder as part of the weekly expiration reminders when 12 months from this date is approaching.</p>
	</>
);

export const CandidateDBSTypeHelpText = (
	<>
		<p>
			DBS Type, although an optional field does feed into the candidate vetting data. If the DBS type is &quot;Enhanced with Barred List&quot; then the Children Barred Check List date will use
			the DBS Certificate Issue Date, instead of the DBS Update Service Check Date.
		</p>
		<p>DBS Type, otherwise is purely static information stored against the candidates Criminal Reecord &amp; Security Checks.</p>
	</>
);

export const CandidateDBSLastCheckedHelpText = (
	<>
		<p>The DBS last checked date will be converted to a date picker in the coming weeks. Any values not currently in a valid date format may not function accurately after this change.</p>
	</>
);

export const CandidateDBSUpdateServiceTypeHelpText = (
	<>
		<p>Enter the date when the DBS was last checked against the Update Service. Reminder dates will be automatically calculated based on your preferences (12, 6, or 3 months).</p>
	</>
);

export const DocumentApprovalStatusHelpText = (
	<>
		<p>Toggle the internal document approval status for individual documents.</p>
		<p>Approved / Declined documents will affect the compliance status of the document.</p>
		<p>Documents that have no approval action against them (neither approved or declined) will be treated as approved unless specifically declined by a user.</p>
	</>
);

export const CandidateQuickMailers = (
	<>
		<p>Create candidate mailers directly from your candidate listings.</p>
		<p>
			<strong>Marketing Campaigns:</strong>
		</p>
		<p>Choose to initiate a marketing campaign by selecting your recipients alongside an existing marketing template.</p>
		<p>
			<strong>Email Mailer:</strong>
		</p>
		<p>Quickly send an email to up to 50 candidates, entering custom email content.</p>
		<p>
			<strong>SMS Mailer:</strong>
		</p>
		<p>Quickly send an SMS message to up to 50 candidates, entering custom SMS content.</p>
		<p>
			<strong>Audit Logging:</strong>
		</p>
		<p>When sending a custom email or SMS mailer the email or SMS content is added to each recipients record as a note, tagged appropriately, along side an event log record to track delivery.</p>
	</>
);

export const CandidatesTeamFilterHelpText = (
	<>
		<p>Filter the candidate list by the assignee teams they are part of.</p>
		<p>As candidates are not directly placed into teams, instead the team being associated with the assignee there is no value to display in this column.</p>
		<p>An active team filter will override any active assignee filter.</p>
	</>
);

export const JobFeedExternalHelpText = (
	<>
		<p>You can set the iFrame background colour for your external job feeds here.</p>
	</>
);

export const AutomationFocusHelpText = (
	<>
		<p>Select the target for your automations.</p>
		<p>The focus target can either be candidate, client, or consultant.</p>
		<p>The action (email, notify, tag, comment) will action the focus you have selected.</p>
	</>
);

export const AutomationActionHelpText = (
	<>
		<p>Select the action to perform on valid targets.</p>
		<p>If the focus is candidates and the action is email, the candidates which the criteria is applied to will be emailed.</p>
	</>
);

export const AutomationCriteriaHelpText = (
	<>
		<p>Select the criteria for your automations.</p>
		<p>The criteria provides the filtering logic and will filter targets based on the described conditions.</p>
	</>
);

export const UserTargetReportPeriodHelpText = (
	<>
		<p>What is the timeframe this report value applies to?</p>
		<ul>
			<li>
				<strong>Full:</strong> The default report period, if set the value applies to the entire period of the target.
			</li>
			<li>
				<strong>Weekly:</strong> This value will pro-rated for each week within the target date period.
			</li>
			<li>
				<strong>Monthly:</strong> This value will be pro-rated for each month within the target date period.
			</li>
		</ul>
		<p>Due to pro-rating of targets for weekly / monthly targets some values may change slightly. e.g. monthly targets are worked out as days based on 365 / 12.</p>
	</>
);

export const ReportingPageHelpText = (
	<>
		<p>Reporting within Edun is divided into two main categories:</p>

		<p>
			<strong>1. Operational Reporting</strong>
		</p>
		<p>Operational reporting provides comprehensive statistics and key metrics across your organisation.</p>
		<p>Access to operational reports is controlled through user permissions, ensuring data security and relevant access.</p>

		<p>
			<strong>2. User Targets</strong>
		</p>
		<p>User targets provide visual representations of individual and team progress against set metrics. These targets include:</p>
		<p>Target metrics are automatically pro-rated based on their configuration:</p>
		<ul>
			<li>
				<strong>Full Period:</strong> Targets apply to the entire selected date range
			</li>
			<li>
				<strong>Weekly:</strong> Targets are divided across weeks within the range
			</li>
			<li>
				<strong>Monthly:</strong> Targets are distributed across months in the range
			</li>
		</ul>
		<p>Both reporting areas support various visualisation options, including charts, tables, and exportable data formats for further analysis.</p>
	</>
);

export const RecordWatchHelpText = (
	<>
		<p>Watching a record allows you to recieve updates when items are submitted / confirmed.</p>
		<p>
			For example, as a compliance user you may choose to watch a candidate record to recieve updates when onboarding is complete, or a new document is uploaded without having to be the primary
			assignee.
		</p>
	</>
);
