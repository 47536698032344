export enum RemarkTypes {
	candidate = 1,
	timesheet = 2,
	client = 3,
	booking = 4,
	student = 5,
	branch = 6,
}

export enum RemarksTags {
	BDCall = 1,
	CandidateCall = 2,
	ClientCall = 3,
	Meeting = 4,
	Compliance = 5,
	CandidateEmail = 6,
	CandidateSms = 7,
	//CustomTags
	LMEMTxt = 8,
	LMEMTxtFirstIntro = 9,
	LMEMTxtSecondIntro = 10,
	LMEMTxtThirdIntro = 11,
	LMEMTxtApplication = 12,
	LMEMTxtAreYouLooking = 13,
	Voicemail = 14,
	SentMessage = 15,
	BDCallAttempt = 16,
	BDCallSuccess = 17,
	BDEmailMessageSent = 18,
	BDEmailMessageSuccess = 19,
	ClientCallOther = 20,
	CATsCallAttempt = 21,
	CATsCallSuccess = 22,
	BDMassEmail = 23,
	ClientTermsSigned = 24,
	PreferredPartnerAgreedVerbally = 25,
	PreferredPartnerSigned = 26,
	RecruitingCallSuccess = 27,
	LMEMTxtRecruiting = 28,
	RecruitingEmailMessageSent = 29,
	RecruitingEmailMessageSuccess = 30,
	RecruitingMassEmail = 31,
	JobWorkedOnCompletely = 32,
	PreVetPermReg = 33,
	PreVetPermRegWithEmail = 34,
	VirtualRegBooked = 35,
	VirtualRegAttended = 36,
	VirtualRegAttendedWithEmail = 37,
	InPersonRegistrationBooked = 38,
	InPersonRegistrationAttended = 39,
	AvailabilityLMEMTXT = 40,
	AvailabilityEmailMessageSuccess = 41,
	AvailabilityCallSuccess = 42,
	CandidateCallOther = 43,
	CandidateCallAftercareSuccess = 44,
	CandidateCallAftercareAttempt = 45,
	SingleEshotSentUKBased = 46,
	SingleEshotSentOverseasBased = 47,
	WeeklyEshotSentUKBased = 48,
	WeeklyEshotSentOverseasBased = 49,
	AdvertPosted = 50,
	ClientVisitBooked = 51,
	ClientVisitAttended = 52,
	ClientDropAttended = 53,
	ClientVisitDropToVisitAttended = 54,
	ClientVisitVirtualBooked = 55,
	ClientVisitVirtualAttended = 56,
	InterviewFeedback = 57,
	InterestInTeachingOverseas = 58,
	SoloOdroRequestSent = 59,
	SoloOdroReceived = 60,
	SoloOdroSuccessFeedback = 61,
	SoloOdroFailedFeedback = 62,
	iCanFullyRegisteredCATA = 63,
	iCanFullyRegistered = 64,
	Other = 65,
	Email = 66,
	ComplianceReferenceChaseCall = 67,
	ComplianceReferenceChasedEmailMessage = 68,
	ComplianceCallSuccess = 69,
	ComplianceCallAttempted = 70,
	ComplianceIntro = 71,
	CandidateSentToCompliance = 72,
	ComplianceNote = 73,
	ComplianceInPersonInterviewCompliant = 74,
	CompliancePhoneVidInterviewCompliant = 75,
	ComplianceTempClearedFirstCheck = 76,
	ComplianceTempClearedSecondCheck = 77,
	ComplianceDirectCleared = 78,
	ComplianceDirectClearedSecondCheck = 79,
	ComplianceFinalChecksFail = 80,
	WelcomeToDunbarCall = 81,
	FinanceInvoiceSent = 82,
	SpecCall = 83,
}
