import { BLOB, HTTP, IHttpResponse } from "./http";
import {
	CREATE_CLIENT,
	UPDATE_CLIENT,
	UPDATE_CLIENT_CONTACTS,
	UPDATE_CLIENT_ID,
	UPDATE_CLIENT_INTERNAL_ID,
	CREATE_CLIENTS,
	GET_CLIENTS,
	EXPORT_CLIENTS,
	EXPORT_CONTACTS,
	EXPORT_CLIENT,
	SEND_TERMS_OF_BUSINESS,
	GET_CLIENTS_STUB,
	GET_CLIENTS_BOOKING_STUB,
	GET_CLIENTS_IDS,
	GET_CLIENT,
	GET_CLIENT_SUMMARY,
	MARKETING_RECIPIENT_SEARCH,
	MARKETING_RECIPIENT_CANDIDATE_SEARCH,
	MARKETING_RECIPIENT_CANDIDATE_SEARCH_MOVE,
	GET_CLIENTS_BOOKING_STUB_LATEST,
	GET_CLIENTS_BOOKING_STUB_SEARCH,
	GET_CLIENT_CONTACT_SUMMARY,
	SEND_CLIENT_EMAIL,
	SEND_CLIENT_DOCUMENTS,
	MANAGE_DO_NOT_WORK,
	DELETE_CANDIDATE_DO_NOT_WORK,
	UPDATE_CLIENT_ASSIGNEE,
	MARKETING_RECIPIENT_SEARCH_ALL,
} from "../../config/endpoints";
import { HeadersAuth } from "./http";
import { ContactInterface } from "../../_models/data.contacts.model";
import { ContactRoles } from "../../_enums/contactRoles.enum";
import { IDoNotWork } from "../../_models/data.doNotWork.model";

export const CreateClient = (Token: string, DATA) => {
	return HTTP({
		Method: "POST",
		Url: CREATE_CLIENT,
		Data: DATA ?? null,
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const UpdateClient = (Token: string, DATA, CLIENT_ID) => {
	return HTTP({
		Method: "PUT",
		Url: UPDATE_CLIENT(CLIENT_ID),
		Data: DATA ?? null,
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const UpdateClientContacts = (Token: string, DATA: { contacts: ContactInterface[] }, CLIENT_ID) => {
	return HTTP({
		Method: "PUT",
		Url: UPDATE_CLIENT_CONTACTS(CLIENT_ID),
		Data: DATA ?? null,
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const CreateClients = (Token: string, DATA) => {
	return HTTP({
		Method: "POST",
		Url: CREATE_CLIENTS,
		Data: DATA ?? null,
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const GetClients = (Token: string) => {
	return HTTP({
		Method: "POST",
		Url: GET_CLIENTS,
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const GetClientsFilter = (Token: string, DATA?) => {
	return HTTP({
		Method: "POST",
		Url: GET_CLIENTS,
		Data: DATA || {},
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const ExportClients = (Token: string, DATA?, CONTACT_TYPE?: ContactRoles) => {
	return BLOB({
		Method: "POST",
		Url: EXPORT_CLIENTS(CONTACT_TYPE),
		Data: DATA || {},
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const ExportContacts = (Token: string, DATA?) => {
	return BLOB({
		Method: "POST",
		Url: EXPORT_CONTACTS,
		Data: DATA || {},
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const ExportClient = (Token: string, CLIENT_ID: string, CONTACT_TYPE: ContactRoles) => {
	return BLOB({
		Method: "GET",
		Url: EXPORT_CLIENT(CLIENT_ID, CONTACT_TYPE),
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const SendTermsOfBusiness = (Token: string, CLIENT_ID: string, DATA: { contact: string }) => {
	return HTTP({
		Method: "POST",
		Url: SEND_TERMS_OF_BUSINESS(CLIENT_ID),
		Headers: HeadersAuth(Token),
		Data: DATA,
	}) as Promise<IHttpResponse>;
};

export const GetClientsIds = (Token: string) => {
	return HTTP({
		Method: "GET",
		Url: GET_CLIENTS_IDS,
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const GetClientsStub = (Token: string) => {
	return HTTP({
		Method: "GET",
		Url: GET_CLIENTS_STUB,
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const GetClientsBookingStub = (Token: string) => {
	return HTTP({
		Method: "GET",
		Url: GET_CLIENTS_BOOKING_STUB,
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const GetClientsBookingStubLatest = (Token: string) => {
	return HTTP({
		Method: "GET",
		Url: GET_CLIENTS_BOOKING_STUB_LATEST,
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const GetClientsBookingStubSearch = (Token: string, DATA: { value: string }) => {
	return HTTP({
		Method: "POST",
		Url: GET_CLIENTS_BOOKING_STUB_SEARCH,
		Headers: HeadersAuth(Token),
		Data: DATA,
	}) as Promise<IHttpResponse>;
};

export const GetClient = (Token: string, CLIENT_ID) => {
	return HTTP({
		Method: "GET",
		Url: GET_CLIENT(CLIENT_ID),
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const GetClientSummary = (Token: string, CLIENT_ID) => {
	return HTTP({
		Method: "GET",
		Url: GET_CLIENT_SUMMARY(CLIENT_ID),
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const GetClientClient = (Token: string, CLIENT_ID) => {
	return HTTP({
		Method: "GET",
		Url: GET_CLIENT(CLIENT_ID),
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const UpdateClientAssignee = (Token: string, DATA, CLIENT_ID) => {
	return HTTP({
		Method: "PUT",
		Url: UPDATE_CLIENT_ASSIGNEE(CLIENT_ID),
		Data: DATA ?? null,
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const MarketingRecipientSearch = (Token: string, DATA, CLIENT_SEARCH) => {
	return HTTP({
		Method: "POST",
		Url: CLIENT_SEARCH ? MARKETING_RECIPIENT_SEARCH : MARKETING_RECIPIENT_CANDIDATE_SEARCH,
		Data: DATA ?? null,
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const MarketingRecipientSearchAll = (Token: string, DATA) => {
	return HTTP({
		Method: "POST",
		Url: MARKETING_RECIPIENT_SEARCH_ALL,
		Data: DATA ?? null,
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const MarketingRecipientSearchMove = (Token: string, DATA) => {
	return HTTP({
		Method: "POST",
		Url: MARKETING_RECIPIENT_CANDIDATE_SEARCH_MOVE,
		Data: DATA ?? null,
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const UpdateClientId = (Token: string, DATA, CLIENT_ID) => {
	return HTTP({
		Method: "PUT",
		Url: UPDATE_CLIENT_ID(CLIENT_ID),
		Data: DATA,
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const UpdateClientInternalId = (Token: string, DATA, CLIENT_ID) => {
	return HTTP({
		Method: "PUT",
		Url: UPDATE_CLIENT_INTERNAL_ID(CLIENT_ID),
		Data: DATA,
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const ArchiveClient = (Token: string, CLIENT_ID) => {
	return HTTP({
		Method: "DELETE",
		Url: GET_CLIENT(CLIENT_ID),
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const GetClientContactSummary = (Token: string, CLIENT_ID: string) => {
	return HTTP({
		Method: "GET",
		Url: GET_CLIENT_CONTACT_SUMMARY(CLIENT_ID),
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const SendClientEmail = (Token: string, CLIENT_ID: string, DATA: { content: string; contact: string; subject: string; text: string; selectedDocuments?: string[] }) => {
	return HTTP({
		Method: "POST",
		Url: SEND_CLIENT_EMAIL(CLIENT_ID),
		Headers: HeadersAuth(Token),
		Data: DATA,
	}) as Promise<IHttpResponse>;
};

export const SendClientDocument = (Token: string, CANDIDATE_ID: string, DATA: { documents: string[]; content: string; contact: string }) => {
	return HTTP({
		Method: "POST",
		Url: SEND_CLIENT_DOCUMENTS(CANDIDATE_ID),
		Headers: HeadersAuth(Token),
		Data: DATA,
	}) as Promise<IHttpResponse>;
};

export const AddDoNotWork = (Token: string, DATA: IDoNotWork, CLIENT_ID) => {
	return HTTP({
		Method: "POST",
		Url: MANAGE_DO_NOT_WORK(CLIENT_ID),
		Headers: HeadersAuth(Token),
		Data: DATA ?? {},
	}) as Promise<IHttpResponse>;
};

export const GetDoNotWorkList = (Token: string, CLIENT_ID: string) => {
	return HTTP({
		Method: "GET",
		Url: MANAGE_DO_NOT_WORK(CLIENT_ID),
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const DeleteCandidateDoNotWork = (Token: string, CLIENT_ID: string, CANDIDATE_ID: string) => {
	return HTTP({
		Method: "DELETE",
		Url: DELETE_CANDIDATE_DO_NOT_WORK(CLIENT_ID, CANDIDATE_ID),
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};
