export enum CandidateStatus {
	Pending = 1,
	Approved = 2,
	Rejected = 3,
	Anonymised = 4,
	Archived = 5,
	Enquired = 6,
	Hold = 7,
	Future = 8,
	DNC = 9,
	Pre = 10,
}
