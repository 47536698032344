import { ICommission } from "../components/forms/inputs/commission";

export class UserModel {
	email?: string;
	emailError?: string;
	firstName?: string;
	firstNameError?: string;
	lastName?: string;
	lastNameError?: string;
	username?: string;
	usernameError?: string;
	lea?: string;
	permissions?: number[];
	accountType?: number;
	activationCode?: string;
	disabled?: boolean;
	clients?: string[];
	position?: string;
	positionError?: string;
	// commissionThreshold?: number;
	commissionThreshold?: ICommission[];
	commissionThresholdError?: string;
	_customId?: string;
	phone?: string;
	defaultCandidateWatcher?: boolean;

	constructor(data: any = {}) {
		this.email = data.email || "";
		this.firstName = data.firstName || "";
		this.lastName = data.lastName || "";
		this.username = data.username || "";
		this.lea = data.lea || "";
		this.permissions = data.permissions ? data.permissions.map((item) => parseInt(item)) : [];
		this.accountType = data.accountType || 2;
		this.activationCode = data.activationCode || "";
		this.disabled = data.disabled || false;
		this.clients = data.clients || [];
		this.position = data.position || "";
		this.commissionThreshold = data.commissionThreshold || [];
		this._customId = data._customId || null;
		this.phone = data.phone || "";
		this.defaultCandidateWatcher = data.defaultCandidateWatcher || false;
	}
}

export class UserLinkedClients {
	clients?: string[];

	constructor(data: any = {}) {
		this.clients = data.clients || [];
	}
}

export class UserAccountStateModel {
	disabled?: boolean;

	constructor(data: any = {}) {
		this.disabled = data.disabled || false;
	}
}

export class UserStatModel {
	_id?: string;
	commissionThreshold?: number;

	constructor(data: any = {}) {
		this._id = data._id;
		this.commissionThreshold = data.commissionThreshold;
	}
}

export class UserEmailModel {
	email: string;

	constructor(data: { email: string }) {
		this.email = data.email;
	}
}

export interface IUserClients {
	user: string;
	clients: string[];
}
